
import React from 'react';
import styles from './Buttons.module.css';

export function TransparentTimePicker(props) {

    const handleTimeChange = (event) => {

        let minutes = props.value
        if (event.target.name === "hours") {
            if (event.target.value >= 0 && event.target.value < 24) {
                minutes = parseInt(event.target.value) * 60 + parseInt(props.value % 60)
            }
        } else if (event.target.name === "minutes") {
            if (event.target.value >= 0 && event.target.value < 60) {
                minutes = parseInt(props.value / 60) * 60 + parseInt(event.target.value)
            }

        }
        if (props.min && minutes < props.min) return
        if (props.max && minutes > props.max) return
        if (!isNaN(minutes)) props.onChange(minutes)
    }

    return <div>
        <input id="timer"
            disabled={props.disabled}
            min={0}
            max={23}
            type="number"
            className={styles.inputTime}
            name="hours"
            placeholder=""
            onChange={handleTimeChange}
            value={parseInt(props.value / 60)} />
        <span> : </span>
        <input id="timer"
            disabled={props.disabled}
            min={0}
            max={59}
            type="number"
            className={styles.inputTime}
            name="minutes"
            placeholder=""
            onChange={handleTimeChange}
            value={parseInt(props.value % 60)} />
    </div>
}