import chroma from 'chroma-js'

const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight:"10px",
        height: 10,
        width: 10,
    },
})

export const selectInputStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: 'white',
        border: "1px solid var(--grey)",
        borderRadius: "20px",
        minHeight: "40px",
    }),
    singleValue: (styles, { data }) => ({ ...styles, color: data.color ,...dot(data.color) }),
    multiValue: (styles, { data }) => {
        let stylesObj = {
            ...styles
        }
        if(data.color){
            const color = chroma(data.color)
            stylesObj.backgroundColor = color.alpha(0.1).css()
        }
        return stylesObj
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? "#fff9f1"
                    : isFocused
                        ? "#fff9f1"
                        : undefined,
            color: isDisabled
                ? '#ccc'

                : data.color,
            // color: isDisabled
            // ? '#ccc'

            //     : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : "#ef912b"
                    : undefined,
            },
        }
    }
}

export const selectInputErrorStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: 'white',
        border: "1px solid var(--red)",
        borderRadius: "20px",
        minHeight: "40px"
    }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? "#fff9f1"
                    : isFocused
                        ? "#fff9f1"
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? "#ef912b"
                    : data.color,
            // color: isDisabled
            // ? '#ccc'

            //     : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : "#ef912b"
                    : undefined,
            },
        }
    }
}