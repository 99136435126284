import React, { useEffect, useState } from 'react'
import styles from './Buttons.module.css'
import Langs from "../../app/lang/langs"

export function RadioButton(props) {

    const [selected, setSelected] = useState(props.selected);

    const onChangeHandler = (event) => {
        props.setSelected(props.value)
    }

    useEffect(() => {
        setSelected(props.selected)
    }, [props])

    return <div className={styles.radioButton}
        onClick={onChangeHandler}>
        <input type="radio"
            name={props.title}
            onChange={onChangeHandler}
            checked={selected} />
        <label>
            <Langs str={props.title} />
        </label>
    </div>
}