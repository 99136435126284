import React, { useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from "react-redux";
import {
    setCollabMcqModalVisibility,
    setCollabMcqModalIdProject,
    getProjectQuestions,
    setCollabMcqQuestions,
    getProjects,
    recordMcqResponsePerProject,
    setCollabMcqResp
} from "./vcompSlice";
import './mcqModalCollab.css'
import { Col, Row } from "react-bootstrap";
import { DynamicButton } from "../buttons/DynamicButton";
import { showErrorToast, showSuccessToast } from "../../app/utils/helpers";
import Langs from "../../app/lang/langs";

export default function CollabMcqView() {

    const dispatch = useDispatch();

    const visible = useSelector((state) => state.vcomp.collabMcqModalVisibility);
    const idProject = useSelector((state) => state.vcomp.collabMcqModalIdProject);
    const questions = useSelector((state) => state.vcomp.collabMcqQuestions);
    const projectsList = useSelector((state) => state.vcomp.projectsList);
    const respProjectMcq = useSelector((state) => state.vcomp.collabMcqResp)


    const [projectName, setProjectName] = useState('')
    const [selections, setSelections] = useState({});
    const [timeRemaining, setTimeRemaining] = useState(0);
    const [timerActive, setTimerActive] = useState(false);


    useEffect(() => {
        if (visible && idProject) {
            const project = projectsList.find(p => p.id == idProject);
            if (project) {
                setProjectName(project.nom)
            }
            dispatch(getProjectQuestions({ id_project: idProject }))
        } else {
            setTimerActive(false);
        }
    }, [visible])

    useEffect(() => {
        let timer;
        if (questions.length > 0) {
            setTimeRemaining(120 * questions.length);//120 seconds for each question adjust in the future based on requirments
            timer = setInterval(() => {
                setTimeRemaining(time => {
                    if (time <= 1) {
                        clearInterval(timer);
                        return 0;
                    }
                    return time - 1;
                });
            }, 1000);
        } else {
            if (timer) {
                clearInterval(timer);
            }
        }
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [questions]);

    useEffect(() => {
        if (questions.length > 0 && timeRemaining < 1) {
            handleSubmitMcq();
        }
    }, [timeRemaining])


    useEffect(() => {
        if (respProjectMcq != null) {
            if (respProjectMcq.trim().toLowerCase().includes("successfully")) {
                handleClose();
                showSuccessToast(respProjectMcq);
            } else {
                handleClose();
            };
            dispatch(setCollabMcqResp(null));
        };
    }, [respProjectMcq]);

    const handleClose = () => {
        dispatch(getProjects());
        dispatch(setCollabMcqModalVisibility(false));
        dispatch(setCollabMcqModalIdProject(null));
        dispatch(setCollabMcqQuestions([]));
        setSelections({});
        setProjectName('');
        setTimeRemaining(0)
    };

    const allQuestionsAnswered = questions.every(question => {
        const selection = selections[question.id];
        return Array.isArray(selection) ? selection.length > 0 : selection !== undefined && selection !== null;
    });

    // useEffect(() => { console.log(selections) }, [selections])
    const handleSubmitMcq = () => {
        const response = Object.keys(selections).map(key => ({
            id_question: parseInt(key),
            answers: Array.isArray(selections[key]) ? selections[key] : [selections[key]]
        }));
        dispatch(recordMcqResponsePerProject(
            {
                id_project: idProject,
                response: response
            }
        ))
    }
    const mcqDiv = () => {
        const handleSelectionChange = (questionId, optionId, isMulti) => {
            if (isMulti) {
                setSelections(prev => {
                    const currentSelections = prev[questionId] || [];
                    if (currentSelections.includes(optionId)) {
                        if (currentSelections.length === 1) {
                            return prev;
                        }
                        return {
                            ...prev,
                            [questionId]: currentSelections.filter(id => id !== optionId)
                        };
                    } else {
                        return {
                            ...prev,
                            [questionId]: [...currentSelections, optionId]
                        };
                    }
                });
            } else {
                setSelections(prev => ({
                    ...prev,
                    [questionId]: prev[questionId] === optionId ? undefined : optionId
                }));
            }
        };
        return (
            <div className="d-flex justify-content-center">
                <div className="mcq-container" >
                    {questions.map(question => (
                        <div key={question.id} className="card m-3 p-3 scard">
                            <h3>{question.question}</h3>
                            <div className="options-container">
                                {question.options.map(option => (
                                    <label key={option.id} className="option-label">
                                        <input
                                            type={question.type === 'single' ? 'radio' : 'checkbox'}
                                            name={question.id}
                                            checked={question.type === 'single' ?
                                                selections[question.id] === option.id :
                                                selections[question.id] ? selections[question.id].includes(option.id) : false
                                            }
                                            onChange={() => handleSelectionChange(question.id, option.id, question.type === 'multi')}
                                        />
                                        {option.value}
                                    </label>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const header = () => {
        function formatTime(seconds) {
            const mins = Math.floor(seconds / 60);
            const secs = seconds % 60;
            return `${mins}m ${secs}s`;
        }
        return (
            <Row className="d-flex justify-content-center">
                <Col lg={12} className="d-flex justify-content-center">
                    <h4>{projectName}</h4>
                </Col>
                {
                    questions.length > 0 &&
                    <Col lg={12} className="d-flex justify-content-center" style={{ color: timeRemaining > 59 ? '' : 'red' }}>
                        Time Remaining: {formatTime(timeRemaining)}
                    </Col>
                }
            </Row>
        )
    }

    const body = () => {
        return (
            <Dialog
                header={header}
                headerClassName="d-flex justify-content-center"
                visible={visible}
                closeOnEscape={false}
                onHide={() => { if (!visible) return; }}
                style={{ width: '100vw', height: '100vh', maxHeight: "100%", backgroundColor: "grey" }}
                draggable={false}
                resizable={false}
                className="collabMcqModal">
                {questions.length > 0 ?
                    <div>
                        {mcqDiv()}
                        {footer()}
                    </div> :
                    <div className="row d-flex justify-content-center">
                        <Col lg={12} className="d-flex justify-content-center m-3">
                            <h2>No questions</h2>
                        </Col>
                        <Col lg={2}>
                            <DynamicButton
                                color='red'
                                onClick={() => { handleClose() }}
                                title={<Langs str='CLOSE' />}
                                active={allQuestionsAnswered}
                            />
                        </Col>
                    </div>
                }
            </Dialog>
        )
    }

    const footer = () => {
        return (
            <Row className="d-flex justify-content-center">
                <Col lg={2}>
                    <DynamicButton
                        color='green'
                        onClick={() => { handleSubmitMcq() }}
                        title="Submit"
                        active={allQuestionsAnswered}
                    />
                </Col>
            </Row>
        )
    }
    return (
        <div>
            {body()}
        </div>
    )
}