import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkManager from "../../app/managers/networkManager";
import { config } from "../../app/utils/config";
import { act } from "react";

const initialState = {
  updateBody: null,
  addBody: null,
  deleteResponse: null,
  editResponse: null,
  addResponse: null,
  evaluationAddModal: false,
  evaluationDetailModal: false,
  fields: [],
  onEvaluateTeamId: null,
  onEvaluateCollab: null,
  onEvaluateDate: null,
  report: {},
  monthlyEvaluations: [],
  deleteFieldResponse: null,
  addFieldResponse: null,
  types: [],
  typesGeneric: [],
  selectedType: null,
  selectedTypeGeneric: null,
  typeModal: false,
  onEditType: null,
  fieldModal: false,
  onEditField: null,
  fieldsIgnoreActive: [],
  typesIgnoreActive: [],
  modifyTypeResponse: null,
  allowedToEdit: false
}
export const evaluationsSlice = createSlice({
  name: "evaluations",
  initialState,
  reducers: {
    setUpdateBody: (state, action) => {
      state.updateBody = action.payload
    },
    setAddBody: (state, action) => {
      state.addBody = action.payload
    },
    setDeleteResponse: (state, action) => {
      state.deleteResponse = action.payload
    },
    setEditResponse: (state, action) => {
      state.editResponse = action.payload
    },
    setAddResponse: (state, action) => {
      state.addResponse = action.payload
    },
    setEvaluationAddModal: (state, action) => {
      state.evaluationAddModal = action.payload
    },
    setEvaluationDetailModal: (state, action) => {
      state.evaluationDetailModal = action.payload
    },
    setOnEvaluateTeamId: (state, action) => {
      state.onEvaluateTeamId = action.payload
    },
    setOnEvaluateCollab: (state, action) => {
      state.onEvaluateCollab = action.payload
    },
    setOnEvaluateDate: (state, action) => {
      state.onEvaluateDate = action.payload
    },
    setMonthlyEvaluations: (state, action) => {
      state.monthlyEvaluations = action.payload
    },
    setDeleteFieldResponse: (state, action) => {
      state.deleteFieldResponse = action.payload
    },
    setAddFieldResponse: (state, action) => {
      state.addFieldResponse = action.payload
    },
    setSelectedType: (state, action) => {
      state.selectedType = action.payload
    },
    setSelectedTypeGeneric: (state, action) => {
      state.selectedTypeGeneric = action.payload
    },
    setReorderTypes: (state, action) => {
      state.types = action.payload
    },
    setTypeModalVisibilty: (state, action) => {
      state.typeModal = action.payload
    },
    setFieldModalVisibilty: (state, action) => {
      state.fieldModal = action.payload
    },
    setOnEditField: (state, action) => {
      state.onEditField = action.payload
    },
    setOnEditType: (state, action) => {
      state.onEditType = action.payload
    },
    setModifyTypeResponse: (state, action) =>{
      state.modifyTypeResponse = action.payload
    },
    setAllowedToEdit: (state, action) =>{
      state.allowedToEdit = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEvaluationFields.fulfilled, (state, action) => {
      if (
        action.payload.responseData &&
        action.payload.responseData.fields
      ) {

        state.fields = action.payload.responseData.fields.map(field => ({
          id: field.id,
          rank: field.rank,
          title: field.title
        }))
        state.fields = state.fields.sort((a, b) => a.rank - b.rank)
      }
      if (
        action.payload.responseData &&
        action.payload.responseData.allFields
      ) {
          state.fieldsIgnoreActive = action.payload.responseData.allFields.map(field => ({
          id: field.id,
          rank: field.rank,
          title: field.title,
          active: field.active
        }))
        state.fieldsIgnoreActive = state.fieldsIgnoreActive.sort((a, b) => a.rank - b.rank)
      }
    });
    builder.addCase(addEvaluation.fulfilled, (state, action) => {
      if (action.payload.responseData) {
        if (action.payload.responseData.status) {
          state.addResponse = action.payload.responseData.message;
          if (!state.addBody) return
        
          const { id_collab, month, values } = state.addBody;

          if (state.report[id_collab]) {
            const notAnsweredCount = 0;
            const yesCount = values.filter(value => value === 1).length;
            const noCount = values.filter(value => value === 0).length;
            state.report[id_collab].data[month] = {
              not_answered: notAnsweredCount,
              yes: yesCount,
              no: noCount
            };
          }
          state.addBody = null;
        }
      }
      if (action.payload.responseData) {
        if (action.payload.responseData.status == 0) {
          state.addResponse = action.payload.responseData.message;

        }
      }
    });
    builder.addCase(getEvaluationsReport.fulfilled, (state, action) => {
      if (action.payload.responseData) {
        state.report = JSON.parse(JSON.stringify(action.payload.responseData["report"]))
      }
    });
    builder.addCase(getMonthlyEvaluations.fulfilled, (state, action) => {
      if (action.payload.responseData &&
        action.payload.responseData["evaluations"]
      ) {
        state.monthlyEvaluations = action.payload.responseData["evaluations"].map(evaluation => {
          return {
            ...evaluation,
            value: evaluation.value == 'no' ? 0 : 1
          }
        })
      }else{
        state.monthlyEvaluations = []
      }
    })
    builder.addCase(editEvaluation.fulfilled, (state, action) => {

      if (action.payload.responseData) {
        state.editResponse = action.payload.responseData.message
        if (action.payload.responseData.status) {

          if(!state.updateBody) return

          const { id_collab, id_team, month, values } = state.updateBody

          const notAnsweredCount = 0
          const yesCount = values.filter(value => value == 1).length
          const noCount = values.filter(value => value == 0).length

          let collabMonthReport = {
            not_answered: notAnsweredCount,
            yes: yesCount,
            no: noCount
          }
          id_team.forEach(idTeam => {
            if (state.report[id_collab+"_"+idTeam]) {
              state.report[id_collab+"_"+idTeam].data[month] = collabMonthReport
            }
          })
          state.updateBody = null
        }
      }
    })
    builder.addCase(deleteEvaluationField.fulfilled, (state, action) => {
      if (action.payload.responseData) {
        if (action.payload.responseData.status) {
          state.deleteFieldResponse = action.payload.responseData.message;
        } else {
          state.deleteFieldResponse = "Error !"
        }
      }
    })
    builder.addCase(addEvaluationField.fulfilled, (state, action) => {
      if (action.payload.responseData) {
        if (action.payload.responseData.status) {
          state.addFieldResponse = action.payload.responseData.message;
        } else {
          state.addFieldResponse = "Error !"
        }
      }
    })
    builder.addCase(getEvaluationsTypes.fulfilled, (state, action) => {
      if (action.payload.responseData &&
        action.payload.responseData.singletonTypes) {
        state.types = action.payload.responseData.singletonTypes.sort((a, b) => a.rank - b.rank)
        if (!state.selectedType && state.types[0]) state.selectedType = state.types[0]
      }
      if(action.payload.responseData &&
        action.payload.responseData.genericTypes){
          state.genericTypes = action.payload.responseData.genericTypes.sort((a, b) => a.rank - b.rank)
          if (!state.selectedTypeGeneric && state.genericTypes[0]) state.selectedTypeGeneric = state.genericTypes[0]
        }
      if(action.payload.responseData &&
        action.payload.responseData.settingsTypes){
          state.typesIgnoreActive = action.payload.responseData.settingsTypes.sort((a, b) => a.rank - b.rank);
        }
    })
    builder.addCase(editEvaluationField.fulfilled, (state, action) => {
      if (action.payload.responseData) {
        if (action.payload.responseData.status) {
          state.addFieldResponse = action.payload.responseData.message;
        } else {
          state.addFieldResponse = "Error !"
        }
      }
    })
    builder.addCase(addEvaluationsType.fulfilled, (state, action)=>{
      if(action.payload.responseData &&
        action.payload.responseData.message
      ){
        state.modifyTypeResponse = action.payload.responseData.message
      }
    })
    builder.addCase(editEvaluationsType.fulfilled, (state, action)=>{
      if(action.payload.responseData &&
        action.payload.responseData.message
      ){
        state.modifyTypeResponse = action.payload.responseData.message
      }
    })
  }
})

//thunks here

export const addEvaluation = createAsyncThunk(
  "planning/addEvaluation",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.addEvaluation,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const editEvaluation = createAsyncThunk(
  "planning/editEvaluation",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.editEvaluation,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getEvaluationFields = createAsyncThunk(
  "planning/getEvaluationFields",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getEvaluationFields,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getEvaluationsReport = createAsyncThunk(
  "planning/getEvaluationsReport",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getEvaluationsReport,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getMonthlyEvaluations = createAsyncThunk(
  "planning/getMonthlyEvaluations",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getMonthlyEvaluations,
        body
      );
      return response
    } catch (error) {
      return error;
    }
  }
)
export const deleteEvaluationField = createAsyncThunk(
  "planning/deleteEvaluationField",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.deleteEvaluationField,
        body
      );
      return response
    } catch (error) {
      return error;
    }
  }
)
export const addEvaluationField = createAsyncThunk(
  "planning/addEvaluationField",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.addEvaluationField,
        body
      );
      return response
    } catch (error) {
      return error;
    }
  }
)
export const getEvaluationsTypes = createAsyncThunk(
  "planning/getEvaluationsTypes",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getEvaluationsTypes,
        body
      );
      return response
    } catch (error) {
      return error;
    }
  }
)

export const reorderEvaluationsFields = createAsyncThunk(
  "planning/reorderEvaluationsFields",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.reorderEvaluationsFields,
        body
      );
      return response
    } catch (error) {
      return error;
    }
  }
)
export const reorderEvaluationTypes = createAsyncThunk(
  "planning/reorderEvaluationTypes",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.reorderEvaluationTypes,
        body
      );
      return response
    } catch (error) {
      return error;
    }
  }
)
export const editEvaluationField = createAsyncThunk(
  "planning/editEvaluationField",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.editEvaluationField,
        body
      );
      return response
    } catch (error) {
      return error;
    }
  }
)
export const addEvaluationsType = createAsyncThunk(
  "planning/addEvaluationsType",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.addEvaluationsType,
        body
      );
      return response
    } catch (error) {
      return error;
    }
  }
)
export const editEvaluationsType = createAsyncThunk(
  "planning/editEvaluationsType",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.editEvaluationsType,
        body
      );
      return response
    } catch (error) {
      return error;
    }
  }
)



export const {
  setUpdateBody,
  setAddBody,
  setDeleteResponse,
  setEditResponse,
  setAddResponse,
  setEvaluationAddModal,
  setEvaluationDetailModal,
  setOnEvaluateCollab,
  setOnEvaluateDate,
  setMonthlyEvaluations,
  setDeleteFieldResponse,
  setAddFieldResponse,
  setSelectedType,
  setReorderTypes,
  setTypeModalVisibilty,
  setFieldModalVisibilty,
  setOnEditField,
  setOnEditType,
  setSelectedTypeGeneric,
  setModifyTypeResponse,
  setAllowedToEdit,
  setOnEvaluateTeamId
} = evaluationsSlice.actions

export default evaluationsSlice.reducer;