import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { ThemeButton } from "../../components/buttons/ThemeButton";
import Langs from "../../app/lang/langs";
import { SelectInput } from "../../components/inputs/Select";
import { TransparentDatePicker } from "../../components/buttons/TransparentDatePicker";
import { getLeaves, requestDeleteLeave, requestLeave } from "./leavesSlice";
import { checkPermission, generateUniqueId, getLastUpdatedFromDate, getStatusTagClass, isOwner, showSuccessToast } from "../../app/utils/helpers";
import { DangerButton } from "../../components/buttons/DangerButton";
import { isAdmin } from "../../app/utils/helpers";
import { submitLeave } from "./leavesSlice";
import { showErrorToast } from "../../app/utils/helpers";
import { getPlanning } from "../planning/planningSlice";
import { formatDate } from "../../app/utils/helpers";
import { Tag } from "primereact/tag";
import { Message } from 'primereact/message';
import moment from "moment";
import { getCollabs } from "../../components/collabs/collabsSlice";
import { config } from "../../app/utils/config";

export function LeaveFormModal(props) {

    const dispatch = useDispatch()

    const userId = useSelector((state) => state.login.userId)
    const userRoleId = useSelector((state) => state.login.userRoleId)
    const userCollabId = useSelector((state) => state.login.userId)
    const teams = useSelector((state) => state.teams.list)
    const currentCollabs = useSelector((state) => state.collabs.currentList)
    const myTeam = useSelector((state) => state.teams.myTeam)
    const sampleLeaves = useSelector((state) => state.sampleLeaves.list)
    const leaveModalType = useSelector((state) => state.leaves.leaveModalType)
    const selectedLeaveFormData = useSelector((state) => state.leaves.selectedLeaveFormData)
    const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
    const collabs = useSelector((state) => state.collabs.list)

    const [formError, setFormError] = useState(null)
    const [selectedCollab, setSelectedCollab] = useState()
    const [formData, setFormData] = useState({})
    const [showToDate, setShowToDate] = useState(false)
    const [leavesOptions, setLeavesOptions] = useState([])

    const mounted = useRef(false)

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
            setNewFormData()
        }
    })

    useEffect(() => {
        loadLeaves()
    }, [dispatch, collabs])

    function setNewFormData() {
        let from = new Date()
        from.setHours(0)
        from.setMinutes(0)
        from.setSeconds(0)
        let to = new Date()
        to.setHours(23)
        to.setMinutes(59)
        to.setSeconds(59)
        let leaveData = {
            id: generateUniqueId(),
            id_team: null,
            id_collab: null,
            id_sample_leave: null,
            type: null,
            from: from.getTime(),
            to: to.getTime(),
            description: null
        }
        setFormData(leaveData)
        setSelectedCollab(null)
    }

    function getFromDayPeriodOptions() {
        let options = [
            { value: 0, label: <Langs str="Matin" /> },
            { value: 12, label: <Langs str="Midi" /> },
        ]
        return options
    }

    function getCollabsOptions() {
        let uniqueIds = []
        let collabsOptions = currentCollabs.filter((collab) => {
            return ["Présent", "Actif"].includes(collab.statut)
                && (!myTeam || checkPermission(config.scopes.leaves, config.permissionTypes.edit, collab.id_team) || collab.id_team != myTeam.id || collab.id == userCollabId)
                && !collab.id_team_delegate
        })
        return collabsOptions.filter(c => {
            if (uniqueIds.includes(c.id)) {
                return false
            }
            uniqueIds.push(c.id)
            return true
        })
    }

    function isManager() {
        let teamId = formData.id_team
        if (selectedLeaveFormData && leaveModalType == 'UPDATE_LEAVE')
            teamId = selectedLeaveFormData.id_team
        return checkPermission(config.scopes.leaves, config.permissionTypes.edit, teamId)
        return isAdmin(userRoleId) && (!myTeam || myTeam.userRoleId == 2 || teamId != myTeam.id)
    }

    function getFromDayPeriod(value) {
        switch (value) {
            case 0:
                return "Matin"
            case 12:
                return "Midi"
            default:
                break
        }
    }

    function getToDayPeriodOptions() {
        let options = [
            { value: 12, label: <Langs str="Midi" /> },
            { value: 23, label: <Langs str="Soir" /> },
        ]
        return options
    }

    function getToDayPeriod(value) {
        switch (value) {
            case 12:
                return "Midi"
            case 23:
                return "Soir"
            default:
                break
        }
    }

    function getSelectedDayPeriod(dateTime) {
        let date = new Date(dateTime)
        let hour = date.getHours()
        if (hour < 12) return 0
        else if (hour > 12) return 23
        return 12
    }

    const onFromDayPeriodChange = (option) => {
        let fromDate = new Date(formData.from)
        fromDate.setHours(option.value)
        fromDate.setMinutes(0)
        fromDate.setSeconds(59)
        setFormData({ ...formData, from: fromDate.getTime() })
    };

    const onToDayPeriodChange = (option) => {
        let toDate = new Date(formData.to)
        toDate.setHours(option.value)
        if (option.value > 12) {
            toDate.setMinutes(59)
            toDate.setSeconds(59)
        } else {
            toDate.setMinutes(0)
            toDate.setSeconds(0)
        }
        setFormData({ ...formData, to: toDate.getTime() })
    }

    function hide() {
        setNewFormData()
        props.onHide()
    }

    function updateLeaveWithStatus(status) {
        dispatch(submitLeave({
            id: selectedLeaveFormData.id,
            status: status
        })).then((response) => {
            if (response['payload']['responseData']) {
                loadPlanning()
            }
            if (response['payload']['responseError']) {
                if (response['payload']['responseError']) {
                    if (response['payload']['responseError']['data'] && response['payload']['responseError']['data']['message']) {
                        showErrorToast(response['payload']['responseError']['data']['message'])
                    } else showErrorToast()
                }
            }
        })
        props.onHide()
    }

    function loadPlanning(){
        if (selectedTeamsIds && selectedTeamsIds.length && collabs.length) {
          let selectedCollabsIds = collabs.reduce((array, current) =>{
            array.push(current.id)
            return array
          }, [])
          dispatch(getPlanning({selectedTeamsIds, selectedCollabsIds}))
        }
    }

    function handleRequestDelete() {
        dispatch(requestDeleteLeave({ id: selectedLeaveFormData.id })).then((response) => {
            if (response['payload']['responseData']) {
                loadPlanning()
                if (selectedLeaveFormData["status"] != "PENDING")
                    showSuccessToast(<Langs str="REQUEST_SENT" />)
            }
            if (response['payload']['responseError']) {
                showErrorToast()
            }
        })
        props.onHide()
    }

    function handleSend() {
        // Vérification de la validité de la date de fin
        if (formData.from && formData.to && formData.to < formData.from) {
            setFormError("La date de fin ne peut pas être antérieure à la date de début");
            return;
        }
        if (!formData.id_collab) {
            setFormError("id_collab")
            return
        }
        if (!formData.id_sample_leave) {
            setFormError("id_sample_leave")
            return
        }
        setFormError(null)
        dispatch(requestLeave(formData)).then(() => {
            dispatch(getCollabs({ selectedTeamsIds, isCurrent: true }))
            setNewFormData()
            props.onHide()
        })

    }

    function loadLeaves(){
        if (selectedTeamsIds && selectedTeamsIds.length) {
          let selectedCollabsIds = collabs.reduce((array, current) =>{
            array.push(current.id)
            return array
          }, [])
          dispatch(getLeaves({selectedTeamsIds, selectedCollabsIds}))
        }
    }

    function getTeamLocalizationFromId(teamId) {
        let localization = ""
        let teamsFiltered = teams && teams.filter(t => t.id == teamId)
        if (teamsFiltered && teamsFiltered[0]) {
            localization = teamsFiltered[0].localization
        }
        return localization
    }

    function handleCollabChange(collab) {
        let availableLeaves = sampleLeaves.filter(s => !s.visibility)
        if (!isAdmin(userRoleId)) {
            availableLeaves = availableLeaves.filter((sampleLeave) => sampleLeave.roleId >= 2)
        }
        if (collab.id != userId && !isOwner(userRoleId, getTeamLocalizationFromId(collab.id_team))) {
            availableLeaves = availableLeaves.filter((sampleLeave) => sampleLeave.roleId < 3)
        }
        setSelectedCollab(collab)
        setLeavesOptions(availableLeaves)
        setFormData({
            ...formData,
            id_collab: collab.id,
            id_team: collab.id_team,
            id_sample_leave: availableLeaves[0].id,
            type: availableLeaves[0].type
        })
    }

    function handleTypeChange(selectedSample) {
        setFormData({ ...formData, id_sample_leave: selectedSample.id, type: selectedSample.type })
    }

    const handleStartDateChange = (time) => {
        let selectedDate = new Date(time)
        let formDate = new Date(formData.from)
        formDate.setFullYear(selectedDate.getFullYear())
        formDate.setMonth(selectedDate.getMonth())
        formDate.setDate(selectedDate.getDate())
        setFormData({ ...formData, from: formDate.getTime() })
        setShowToDate(true)
    }


    const handleEndDateChange = (time) => {
        let selectedDate = new Date(time)
        let toDate = new Date(formData.to)
        toDate.setFullYear(selectedDate.getFullYear())
        toDate.setMonth(selectedDate.getMonth())
        toDate.setDate(selectedDate.getDate())
        setFormData({ ...formData, to: toDate.getTime() })
        setShowToDate(false)
    }

    const handleDescriptionChange = (event) => {
        setFormData({ ...formData, description: event.target.value })
    }

    function getLeaveLabel(leaveData) {
        if (leaveData.type == 'LEAVE') return <Langs str={leaveData.type} />
        let sampleLeave = sampleLeaves.filter(s => s.id == leaveData.id_sample_leave)
        if (sampleLeave[0]) return sampleLeave[0].description
        return ""
    }

    function renderBodyView() {

        if (selectedLeaveFormData && leaveModalType == 'UPDATE_LEAVE')
            return <Modal.Body>
                <Row className="d-flex justify-content-center align-items-center p-2">
                    <Col md={3}>
                        <label>
                            <Langs str="COLLAB" />
                        </label>
                    </Col>
                    <Col md={9}>
                        <div>{selectedCollab && selectedCollab.label}</div>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center p-2">
                    <Col md={3}>
                        <span>
                            <Langs str="START" />
                        </span>
                    </Col>

                    <Col md={4}>
                        <div>{formatDate(new Date(selectedLeaveFormData.from), "DD MMMM YY")}</div>
                    </Col>

                    <Col md={5}>
                        <div><Langs str={getFromDayPeriod(getSelectedDayPeriod(selectedLeaveFormData.from))} /></div>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center p-2">
                    <Col md={3}>
                        <span>
                            <Langs str="END" />
                        </span>
                    </Col>
                    <Col md={4}>
                        <div>{formatDate(new Date(selectedLeaveFormData.to), "DD MMMM YY")}</div>
                    </Col>
                    <Col md={5}>
                        <div><Langs str={getToDayPeriod(getSelectedDayPeriod(selectedLeaveFormData.to))} /></div>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center p-2">
                    <Col md={3}>
                        <label>
                            <Langs str="TYPE" />
                        </label>
                    </Col>

                    <Col md={9}>
                        <div><Langs str={selectedLeaveFormData.type} /></div>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center p-2">
                    <Col md={3}>
                        <label>
                            <Langs str="DESCRIPTION" />
                        </label>
                    </Col>
                    <Col md={9}>
                        <div>{(selectedLeaveFormData.description) ? selectedLeaveFormData.description : '-'}</div>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center p-2" style={{ color: "grey" }}>
                    <Col md={3}>
                        <label>
                            <Langs str="CREATED_AT" />
                        </label>
                    </Col>
                    <Col md={9}>
                        <div>{getLastUpdatedFromDate(selectedLeaveFormData.createdAt) || "-"}</div>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center p-2" style={{ color: "grey" }}>
                    <Col md={3}>
                        <label>
                            <Langs str="CREATED_BY" />
                        </label>
                    </Col>
                    <Col md={9}>
                        <div>{selectedLeaveFormData.createdBy}</div>
                    </Col>
                </Row>
            </Modal.Body>
        else if (leaveModalType == 'ADD_LEAVE')
            return <Modal.Body>
                <Row className="d-flex justify-content-center align-items-center p-2">
                    <Col md={3}>
                        <label>
                            <Langs str="COLLAB" />
                        </label>
                    </Col>
                    <Col md={9}>
                        <SelectInput
                            value={formData.id_collab}
                            onChange={(e) => handleCollabChange(e)}
                            error={formError === "id_collab"}
                            options={getCollabsOptions()}
                        />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center p-2">
                    <Col md={3}>
                        <span>
                            <Langs str="START" />
                        </span>
                    </Col>
                    <Col md={4}>
                        <TransparentDatePicker
                            minDate={null}
                            onChange={handleStartDateChange}
                            value={formData.from}
                        />
                    </Col>
                    <Col md={5}>
                        <SelectInput
                            options={getFromDayPeriodOptions()}
                            value={getSelectedDayPeriod(formData.from)}
                            onChange={onFromDayPeriodChange}
                        />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center p-2">
                    <Col md={3}>
                        <span>
                            <Langs str="END" />
                        </span>
                    </Col>
                    <Col md={4}>
                        <TransparentDatePicker
                            minDate={null}
                            onChange={handleEndDateChange}
                            value={formData.from}
                            range={formData.to}
                            forceShow={showToDate}
                        />
                    </Col>
                    <Col md={5}>
                        <SelectInput
                            options={getToDayPeriodOptions()}
                            value={getSelectedDayPeriod(formData.to)}
                            onChange={onToDayPeriodChange}
                        />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center p-2">
                    <Col md={3}>
                        <label>
                            <Langs str="TYPE" />
                        </label>
                    </Col>
                    <Col md={9}>
                        <SelectInput
                            value={formData.id_sample_leave}
                            onChange={(e) => handleTypeChange(e)}
                            error={formError === "id_sample_leave"}
                            options={leavesOptions}
                        />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-items-center p-2">
                    <Col md={3}>
                        <label>
                            <Langs str="DESCRIPTION" />
                        </label>
                    </Col>
                    <Col md={9}>
                        <textarea
                            name="description"
                            maxLength={200}
                            value={formData.description || ""}
                            onChange={handleDescriptionChange}
                        />
                    </Col>
                </Row>
                {selectedCollab && <Row className="d-flex justify-content-center align-items-center p-2" style={{ color: "grey" }}>
                    <Col md={3}>
                        <label>
                            <Langs str="CPN-1" />
                        </label>
                    </Col>
                    <Col md={9}>
                        {selectedCollab.solde_cp_old} <Langs str="days available till" /> {moment(selectedCollab.solde_cp_old_expiry).format("DD/MM/YYYY")}
                    </Col>
                </Row>}
                {selectedCollab && <Row className="d-flex justify-content-center align-items-center p-2" style={{ color: "grey" }}>
                    <Col md={3}>
                        <label>
                            <Langs str="CPN" />
                        </label>
                    </Col>
                    <Col md={9}>
                        {selectedCollab.solde_cp_current} <Langs str="days available" />
                    </Col>
                </Row>}
                <Row className='d-flex justify-content-center align-items-center p-1 pb-2'>
                    {formData.from && formData.to && formData.to < formData.from && (
                        <Message severity="error" text={<Langs str="ADD_LEAVE_ERROR_DATE" />} />
                    )}
                </Row>
            </Modal.Body>
    }

    function renderHeaderView() {
        return (
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">

                    {selectedLeaveFormData && leaveModalType == 'UPDATE_LEAVE' ?
                        <div className="d-flex align-items-center">
                            <label className="me-2">
                                {getLeaveLabel(selectedLeaveFormData)}
                            </label>
                            <Tag severity={getStatusTagClass(selectedLeaveFormData.status)}>
                                <Langs str={selectedLeaveFormData.status} />
                            </Tag>
                        </div>
                        :
                        (leaveModalType == 'ADD_LEAVE' ?
                            <Langs str={isManager() ? "Add New Leave" : "Request New Leave"} />
                            :
                            <div></div>)
                    }
                </Modal.Title>
            </Modal.Header>
        )
    }

    function renderCollabFooterView() {
        switch (leaveModalType) {
            case "UPDATE_LEAVE":
                if (selectedLeaveFormData["id_collab"] == userId && !['DELETED', 'DELETE_REQUESTED'].includes(selectedLeaveFormData.status))
                    return (
                        <Modal.Footer className="d-flex justify-content-between">
                            <Col md={3}>
                                <PrimaryButton title="CANCEL" active={true} onClick={() => hide()} />
                            </Col>
                            <Col md={5}>
                                <DangerButton
                                    title={selectedLeaveFormData["status"] == "PENDING" ? "DELETE" : "REQUEST_DELETE"}
                                    active={true}
                                    onClick={() => handleRequestDelete()}
                                />
                            </Col>
                        </Modal.Footer>
                    )
                return
            case "ADD_LEAVE":
                return <Modal.Footer className="d-flex justify-content-between">
                    <Col md={3}>
                        <PrimaryButton title="CANCEL" active={true} onClick={() => hide()} />
                    </Col>
                    <Col md={3}>
                        <ThemeButton
                            title="SEND"
                            active={true}
                            onClick={() => handleSend()}
                        />
                    </Col>
                </Modal.Footer>
        }

    }

    function renderAdminFooterView() {
        switch (leaveModalType) {
            case "UPDATE_LEAVE":
                switch (selectedLeaveFormData.status) {
                    case "PENDING":
                        return (<Modal.Footer className="d-flex justify-content-between">
                            <Col md={3}>
                                <DangerButton
                                    title="REJECT"
                                    active={true}
                                    onClick={() => updateLeaveWithStatus('REJECTED')}
                                />
                            </Col>
                            <Col md={3}>
                                <PrimaryButton
                                    title="VALIDATE"
                                    active={true}
                                    onClick={() => updateLeaveWithStatus('VALIDATED')}
                                />
                            </Col>
                        </Modal.Footer>
                        )
                    default:
                        break;
                }
            case "UPDATE_LEAVE":
                if (selectedLeaveFormData.status !== 'DELETED')
                    return (
                        <Modal.Footer className="d-flex justify-content-between">
                            <Col md={3}>
                                <PrimaryButton title="CANCEL" active={true} onClick={() => hide()} />
                            </Col>
                            <Col md={3}>
                                <DangerButton
                                    title="DELETE"
                                    active={true}
                                    onClick={() => updateLeaveWithStatus('DELETED')}
                                />
                            </Col>
                        </Modal.Footer>
                    );
                return
            case "ADD_LEAVE":
                return <Modal.Footer className="d-flex justify-content-between">
                    <Col md={3}>
                        <PrimaryButton title="CANCEL" active={true} onClick={() => hide()} />
                    </Col>
                    <Col md={3}>
                        <ThemeButton
                            title={isManager() ? "ADD" : "SEND"}
                            active={true}
                            onClick={() => handleSend()}
                        />
                    </Col>
                </Modal.Footer>
            default:
                break;
        }
    }

    return (
        <Modal
            {...props}
            onHide={() => hide()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {renderHeaderView()}
            {renderBodyView()}
            {isOwner(userRoleId) || isManager(selectedLeaveFormData.id_team) ? renderAdminFooterView() : renderCollabFooterView()}
        </Modal>
    )
}
