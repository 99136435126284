import React from 'react';
import {Button} from 'react-bootstrap';
import styles from './Buttons.module.css';
import Langs from "../../app/lang/langs";

export function StateButton(props) {

    return <button className={styles.stateButton + " " + (props.selected && styles.selected)}
                   onClick={props.onClick}>
        <Langs str={props.title}/>
    </button>;
}