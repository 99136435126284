import React, { act, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from 'primereact/tabview';
import { Col, Row } from "react-bootstrap";
import { Checkbox } from 'primereact/checkbox';
import { PrimaryButton } from "../buttons/PrimaryButton";
import Langs from "../../app/lang/langs";
import ProgressViewCollab from "./ProgressViewCollab";
import { getProjectAptitudesWithValues, answerAptitudeDailyBase, setCollabProjectFormVisibility, setOnAnswerProject, setProjectAptitudes } from "./vcompSlice";
import { showErrorToast } from "../../app/utils/helpers";
import './VcompCompnentsStyle.css'
import styles from './VcompCompnents.module.css';

const PROGRESS_STEPS_PRACTICAL = [
    { label: "Ne sait pas encore ce que c'est", value: "0" },
    { label: "Expliqué", value: "1" },
    { label: "Vu (Double passif)", value: "2" },
    { label: "Réalisé avec accompagnement (Double Actif)", value: "3" },
    { label: "Réalisé sans aide (Double Actif)", value: "4" },
    { label: "Maîtrisé (Autonomie)", value: "5" }
];

const PROGRESS_STEPS_THEORETICAL = [
    { label: "Rien fait", value: "0" },
    { label: "Lu/Expliqué une fois (en partie)", value: "1" },
    { label: "Lu/Expliqué une fois (totalité)", value: "2" },
    { label: "Compris/Connu en partie", value: "3" },
    { label: "Compris/Connu en totalité", value: "4" },
    { label: "Maîtrisé (Autonome)", value: "5" }
];

const ProgressSteps = ({ steps, selectedOption, setSelectedOption, currentProgress }) => (
    <Col lg={12} className="d-flex flex-column fs-6">
        {steps.map(step => (
            <div className="ps-3 pt-2" key={step.value}>
                <label>
                    <Checkbox
                        onChange={() => setSelectedOption(step.value)}
                        checked={selectedOption === step.value}
                        disabled={currentProgress > parseInt(step.value)}
                    />
                    &nbsp;&nbsp;{step.label}
                </label>
            </div>
        ))}
    </Col>
);

const Breadcrumb = ({ category, subcategory }) => (
    <div className="d-flex align-items-center ms-4 mt-5 fs-6 text-muted">
        {category && <span>{category}</span>}
        {category && subcategory && <span> &nbsp;/&nbsp; </span>}
        {subcategory && <span>{subcategory}</span>}
    </div>
);

const Header = ({ activeTabIndex, onTabHeadChange }) => (
    <div className="header-container">
        <TabView scrollable activeIndex={activeTabIndex} onTabChange={onTabHeadChange} className="customTabView">
            <TabPanel header={<Langs str="general" />} rightIcon="pi pi-bolt ps-2 " />
            <TabPanel header={<Langs str="entry" />} rightIcon="pi pi-pencil ps-2 " />
        </TabView>
    </div>
);

const HeaderTemplate = (apt) => {
    const getColor = (num) => {
        if (num < 0 || num > 100) return 'gray';
        if (num < 25) return 'red';
        if (num < 50) return 'orange';
        if (num < 75) return '#FFBF00';
        return 'green';
    };

    return (
        <div>{apt.nom}&nbsp;{apt.progress && <i className="pi pi-circle-fill" style={{ color: getColor(apt.progress * 20) }}></i>}</div>
    );
};

const CollabProjectForm = () => {
    const dispatch = useDispatch();
    const visible = useSelector((state) => state.vcomp.collabProjectFormVisibility);
    const projectAptitudes = useSelector((state) => state.vcomp.projectAptitudesWithValues);
    const onAnswerProject = useSelector((state) => state.vcomp.onAnswerProject);
    const isMobile = useSelector((state) => state.settings.isMobile);

    const [activeIndex, setActiveIndex] = useState(0);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [localApt, setLocalApt] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    useEffect(() => {
        if (onAnswerProject) {
            dispatch(getProjectAptitudesWithValues({ id_project: onAnswerProject.id }));
        }
    }, [onAnswerProject, dispatch]);

    useEffect(() => {
        if (projectAptitudes && projectAptitudes.categories) {
            setLocalApt(flattenData(projectAptitudes.categories));
        } else {
            setLocalApt([]);
        }
    }, [projectAptitudes]);

    useEffect(() => {
        if (localApt[activeIndex]) {
            setSelectedOption(localApt[activeIndex].progress !== null ? localApt[activeIndex].progress.toString() : '');
        }
    }, [activeIndex, localApt]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.key === 'Enter' || event.key === 'ArrowRight') && !!selectedOption) {
                handleNext();
            }
    
            if (event.key === 'ArrowLeft') {
                setActiveIndex((prevIndex) => {

                    if (prevIndex > 0) {
                        return prevIndex - 1;
                    }
                    return prevIndex;
                });
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedOption]); 

    const flattenData = (data) => {
        let aptitudes = [];
        const processAptitude = (aptitude, categoryId = null, categoryName = null, subcategoryId = null, subcategoryName = null) => {
            aptitudes.push({
                id: aptitude.id,
                nom: aptitude.nom,
                coef: aptitude.coef,
                visibility: aptitude.visibility,
                description: aptitude.description,
                progress: aptitude.progress,
                type: aptitude.type,
                pos: aptitude.pos,
                category_id: categoryId,
                category_name: categoryName,
                subcategory_id: subcategoryId,
                subcategory_name: subcategoryName,
                aspect: aptitude.aspect
            });
        };
        data.forEach(category => {
            if (category.scope === "category") {
                const categoryName = category.nom;
                const categoryId = category.id;
                if (category.children && category.children.length) {
                    category.children.forEach(item => {
                        if (item.scope === "subcategory") {
                            const subcategoryName = item.nom;
                            const subcategoryId = item.id;

                            item.children.forEach(aptitude => {
                                if (aptitude.scope === "aptitude") {
                                    processAptitude(aptitude, categoryId, categoryName, subcategoryId, subcategoryName);
                                }
                            });
                        } else if (item.scope === "aptitude") {
                            processAptitude(item, categoryId, categoryName);
                        }
                    });
                }
            } else if (category.scope === "aptitude") {
                processAptitude(category);
            }
        });
        return aptitudes;
    };

    const handleClose = () => {
        dispatch(setCollabProjectFormVisibility(false));
        dispatch(setOnAnswerProject(null));
        dispatch(setProjectAptitudes([]));
        setActiveIndex(0);
        setActiveTabIndex(0);
    };

    const handleNext = () => {
        if (localApt[activeIndex]) {
            let nextIndex = activeIndex;
    
            do {
                nextIndex++;
            } while (nextIndex < localApt.length && localApt[nextIndex].progress === "5");

            if (nextIndex < localApt.length && localApt[nextIndex]) {
                const currentApt = localApt[activeIndex];
    
                if (currentApt.progress !== selectedOption) {
                    try {
                        updateLocalData(activeIndex, selectedOption);
                        dispatch(answerAptitudeDailyBase({ id_aptitude: currentApt.id, progress: selectedOption }));
                        setSelectedOption('');
                    } catch (error) {
                        showErrorToast();
                        return;
                    }
                }
                setActiveIndex(nextIndex);
            } else {
                const currentApt = localApt[activeIndex];
    
                if (currentApt.progress !== selectedOption) {
                    try {
                        updateLocalData(activeIndex, selectedOption);
                        dispatch(answerAptitudeDailyBase({ id_aptitude: currentApt.id, progress: selectedOption }));
                        setSelectedOption('');
                    } catch (error) {
                        showErrorToast();
                        return;
                    }
                }
                handleClose(); 
            }
        }
    };

    const updateLocalData = (index, progress) => {
        localApt[index] = {
            ...localApt[index],
            progress: progress
        };
    };

    const body = () => {
        if (activeTabIndex == 0) {
            return <ProgressViewCollab nodes={projectAptitudes?.categories || []} />;
        } else {
            const currentApt = localApt[activeIndex] || {};
            const currentProgress = parseInt(currentApt.progress || '0', 10);

            return (
                <div>
                    <div className="card" style={{ height: 'calc(100vh - 100px)', overflowY: 'auto' }}>
                        {localApt.length === 0 ? (
                            <Row>
                                <Col lg={12} className="d-flex justify-content-center">
                                    <Langs str="NO_APTITUDES_MESSAGE" />
                                </Col>
                            </Row>
                        ) : (
                            <div>
                                <TabView scrollable activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="customTabView">
                                    {localApt.map(tab => (
                                        <TabPanel key={tab.id} header={HeaderTemplate(tab)} />
                                    ))}
                                </TabView>
                                <div key={currentApt.id} style={{ marginBottom: '20px', overflowY: 'auto' }}>
                                    <Row style={{ height: isMobile === 2 ? "" : "auto" }}>
                                        <Col lg={12} className="d-flex flex-column">
                                            {activeTabIndex === 1 && (
                                                <Breadcrumb
                                                    category={currentApt.category_name}
                                                    subcategory={currentApt.subcategory_name}
                                                />
                                            )}
                                            <Col className="d-flex justify-content-start fs-3 fst-italic">
                                                <p className="ms-3 pt-2 mr-2">{currentApt.description}</p>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row style={{ height: isMobile === 2 ? "" : "calc(100vh - 440px)" }}>
                                        {currentApt.aspect == "theoretical" ? (
                                            <ProgressSteps
                                                steps={PROGRESS_STEPS_THEORETICAL}
                                                selectedOption={selectedOption}
                                                setSelectedOption={setSelectedOption}
                                                currentProgress={currentProgress}
                                            />
                                        ) : (
                                            <ProgressSteps
                                                steps={PROGRESS_STEPS_PRACTICAL}
                                                selectedOption={selectedOption}
                                                setSelectedOption={setSelectedOption}
                                                currentProgress={currentProgress}
                                            />
                                        )}
                                    </Row>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-between align-items-end" style={{ position: 'absolute', bottom: '20px', left: '20px', right: '20px' }}>
                        <div className="d-flex align-items-end">
                            <PrimaryButton
                                title="Previous"
                                active={activeIndex > 0}
                                onClick={() => setActiveIndex(prevIndex => prevIndex - 1)}
                                style={{ padding: '5px 10px', fontSize: '12px' }}
                            />
                        </div>
                        <div className="d-flex align-items-center">
                            <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                {localApt.length > 0 ? `${activeIndex + 1}/${localApt.length}` : '0/0'}
                            </span>
                        </div>
                        {localApt.length > 0 && (
                            <div className="d-flex align-items-end">
                                <PrimaryButton
                                    title="Next"
                                    active={!!selectedOption}
                                    onClick={handleNext}
                                    style={{ padding: '5px 10px', fontSize: '12px' }}
                                />
                            </div>
                        )}
                    </div>
                    {isMobile === 1 && (
                         <div className="d-flex justify-content-center align-items-end text-center" style={{ position: 'absolute', bottom: '5px', height: '30px', left: '0', width: '100%' }}>
                         <small style={{ fontSize: '11px', color: 'grey' }}>
                             <Langs str="Vous pouvez utiliser les flèches de votre clavier pour naviguer plus rapidement" />
                         </small>
                     </div>
                    )}             
                </div>
            );
        }
    };

    return (
        <div className="card flex justify-content-center">
            <Dialog
                visible={visible}
                style={{ width: '90vw', height: '90vh', maxHeight: "100%", backgroundColor: "grey" }}
                onHide={handleClose}
                draggable={false}
                resizable={false}
                header={<Header activeTabIndex={activeTabIndex} onTabHeadChange={(e) => setActiveTabIndex(e.index)} />}
                className="customDialog"
            >
                {body()}
            </Dialog>
        </div>
    );
};

export default CollabProjectForm;
