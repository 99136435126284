import React, { useRef, useEffect } from 'react';
import ProgressBar from 'progressbar.js';
import styles from '../Loader.module.css';

const ProgressBarComponent = ({ progress }) => {
    const progressBarRef = useRef(null);  
    const barRef = useRef(null); 

    useEffect(() => {
        if (barRef.current) {
            barRef.current.animate(progress, {
                duration: 1400, 
                step: function (state, circle) {
                    circle.path.setAttribute('stroke', state.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    const value = Math.round(circle.value() * 100);
                    if (value === 0) {
                        circle.setText('');
                    } else {
                        circle.setText(value + '%');
                    }
                }
            });
        } else {
            const bar = new ProgressBar.Circle(progressBarRef.current, {
                color: '#FFA500', // Orange
                strokeWidth: 4,
                trailWidth: 2,
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    autoStyleContainer: false
                },
                from: { color: '#FFA500', width: 1 },
                to: { color: '#FFA500', width: 4 },
                step: function (state, circle) {
                    circle.path.setAttribute('stroke', state.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    const value = Math.round(circle.value() * 100);
                    if (value === 0) {
                        circle.setText('0%');
                    } else {
                        circle.setText(value + '%');
                    }
                }
            });

            barRef.current = bar;

            if (bar.text) {
                bar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
                bar.text.style.fontSize = '1.5rem';
            }
        }
    }, [progress]);  

    const loaderStyles = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        width: '200px', // Réduire la taille du conteneur
        height: '200px', 
    };

    const overlayStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9998,
    };

    return (
        <div>
            <div style={overlayStyles}></div>
            <div style={loaderStyles}>
                <div ref={progressBarRef} />
            </div>
        </div>
    );
};

export default ProgressBarComponent;
