import { configureStore } from '@reduxjs/toolkit';

import loginReducer from '../../containers/login/loginSlice';
import commonReducer from '../../containers/common/commonSlice';
import settingsReducer from "../../containers/settings/settingsSlice";
import planningSlice from '../../containers/planning/planningSlice';
import shiftsSlice from '../../components/scheduler/shifts/shiftsSlice';
import sampleShiftsSlice from '../../components/scheduler/shifts/sampleShiftsSlice';
import collabsSlice from '../../components/collabs/collabsSlice';
import clientsSlice from '../../components/clients/clientsSlice';
import sampleLeavesSlice from '../../components/scheduler/leave/leaveSampleSlice';
import teamsSlice from '../../components/teams/teamsSlice';
import groupesSlice from '../../containers/groupes/groupesSlice';
import postesSlice from '../../components/postes/postesSlice';
import leavesSlice from '../../containers/leaves/leavesSlice';
import timesheetSlice from '../../containers/timesheet/timesheetSlice';
import reportsSlice from '../../containers/reports/reportsSlice';
import evaluationsSlice from '../../components/evaluations/evaluationsSlice';
import vcompSlice from '../../components/vcomp/vcompSlice';
import VentrSlice from '../../containers/ventr/VentrSlice';

import LogRocket from 'logrocket';
import { config } from "../utils/config";

let extraMiddlwares = []
if(config.LogRocket){
    extraMiddlwares.push(LogRocket.reduxMiddleware())
}

export const store = configureStore({
    reducer: {
        login: loginReducer,
        common: commonReducer,
        planning: planningSlice,
        settings: settingsReducer,
        shifts: shiftsSlice,
        sampleShifts: sampleShiftsSlice,
        sampleLeaves: sampleLeavesSlice,
        collabs: collabsSlice,
        clients: clientsSlice,
        teams: teamsSlice,
        groupes: groupesSlice,
        postes: postesSlice,
        leaves: leavesSlice,
        timesheet: timesheetSlice,
        reports: reportsSlice,
        evaluations: evaluationsSlice,
        vcomp: vcompSlice,
        ventr: VentrSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ['your/action/type'],
                // Ignore these field paths in all actions
                ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                // Ignore these paths in the state
                ignoredPaths: ['items.dates'],
            },
        }).concat(extraMiddlwares),
})
