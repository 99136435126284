import React from 'react';
import { Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from "react-redux";
import Langs from "../../app/lang/langs";
import { hidePopup, onPopupConfirm } from "../../containers/common/commonSlice";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { ThemeButton } from '../buttons/ThemeButton';

export function PopupModalCentered(props) {

    const dispatch = useDispatch();

    function hide() {
        dispatch(hidePopup())
    }

    function handleConfirm() {
        dispatch(onPopupConfirm(props.title))
    }

    function renderFooter() {
        if (props.title) {
            if (props.title.includes("CONFIRM_")) {
                return <Modal.Footer className="d-flex justify-content-between">
                    <Col md={3}><PrimaryButton
                        title="NO"
                        active={true}
                        onClick={() => hide()} /></Col>
                    <Col md={3}>
                        <ThemeButton
                            title="YES"
                            active={true}
                            onClick={() => handleConfirm()} />
                    </Col>
                </Modal.Footer>
            } else return <Modal.Footer className="d-flex justify-content-center">
                <Col md={3}>
                    <PrimaryButton
                        title="OK"
                        active={true}
                        onClick={() => hide()} />
                </Col>
            </Modal.Footer>
        }
    }

    return <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                <Langs str={props.title} />
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Langs str={props.message} />
        </Modal.Body>
        {renderFooter()}
    </Modal>
}