import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkManager from "../../app/managers/networkManager";
import { config } from "../../app/utils/config";
import moment from "moment";
import { setIsLoading } from "../common/commonSlice";
import { store } from "../../app/store/store";
import { dynamicSort, generateUniqueId } from "../../app/utils/helpers";

const initialState = {
  list: [],
  selectedPlanning: [],
  modifiedPlanning: [],
  selectedTimesheet: null,
  hasModifications: false,
  selectedSamplesViewTimesheet: false
}

export const timesheetSlice = createSlice({
  name: "timesheet",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelectedPlanning: (state, action) => {
      state.selectedPlanning = action.payload;
    },
    setModifiedPlanning: (state, action) => {
      state.modifiedPlanning = action.payload;
    },
    setSelectedTimesheet: (state, action) => {
      if (action.payload) {
        let timesheet = state.list.filter(t => t.month == action.payload)
        if (timesheet && timesheet[0]) {
          state.selectedTimesheet = timesheet[0]
        }
      } else {
        state.selectedTimesheet = null
      }
    },
    setHasModifications: (state, action) => {
      state.hasModifications = action.payload;
    },
    setSelectedSamplesViewTimesheet: (state, action) => {
      state.selectedSamplesViewTimesheet = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTimesheets.fulfilled, (state, action) => {
      if (
        action.payload.responseData &&
        action.payload.responseData["timesheets"]
      ) {
        let responseData = action.payload.responseData["timesheets"]
        let timesheetList = []
        for (const key in responseData) {
          let timesheet = responseData[key]
          timesheetList.push({
            id: timesheet.id,
            month: moment(timesheet["from"]).format('MMMM YYYY'),
            id_collab: timesheet["id_collab"],
            from: timesheet["from"],
            to: timesheet["to"],
            start: moment(timesheet["from"]).format("DD/MM/YYYY"),
            end: moment(timesheet["to"]).format("DD/MM/YYYY"),
            status: timesheet["status"],
            updated_at: moment(timesheet["updated_at"]).format("DD/MM/YYYY HH:mm")
          })
        }
        state.list = timesheetList
      }
    });
    builder.addCase(getCollabPlanning.fulfilled, (state, action) => {
      if (
        action.payload.responseData &&
        action.payload.responseData["planning"]
      ) {
        let planningList = [];
        for (const key in action.payload.responseData["planning"]) {
          let planning = action.payload.responseData["planning"][key];
          planningList.push({
            value: key,
            label: planning["task"],
            id: planning["id"],
            id_sample_shift: planning["id_sample_shift"],
            id_sample_leave: planning["id_sample_leave"],
            id_collab: planning["id_collab"],
            id_groupe: planning['id_groupe'],
            id_client: planning["id_client"],
            id_team: planning["id_team"],
            id_team_delegate: planning["id_team_delegate"],
            type: planning["type"],
            validatedBy: planning["validated_by"],
            task: planning["task"],
            description: planning["description"],
            from: planning["from"],
            to: planning["to"],
            day: moment(planning["from"], 'x').format('dddd'),
            date: moment(planning["from"], 'x').format('DD/MM/YYYY'),
            dateTime: planning["from"],
            status: planning["status"],
            createdAt: new Date(planning["created_at"]).getTime(),
            updatedAt: new Date(planning["updated_at"]).getTime(),
            createdBy: planning["createdBy"],
            updatedBy: planning["updatedBy"],
          })
        }
        // Prefill missing days
        let { from, to, id_collab, id_team } = action.payload.body
        let fromDate = new Date(from)
        let toDate = new Date(to)
        while (fromDate < toDate) {
          let newShift = {
            id: generateUniqueId(),
            id_collab: id_collab,
            id_team: id_team,
            dateTime: fromDate.getTime(),
            day: moment(fromDate).format('dddd'),
            date: moment(fromDate).format('DD/MM/YYYY'),
            type: 's',
            status: 'PENDING'
          }
          if (fromDate.getDay() != 0 && fromDate.getDay() != 6) {
            //newShift.id_sample_shift = 0
          }
          if (!planningList.filter(s => s.date == newShift.date).length) {
            planningList.push(newShift)
          }
          fromDate.setDate(fromDate.getDate() + 1)
        }

        planningList.sort(dynamicSort("dateTime"))
        state.selectedPlanning = planningList
        state.modifiedPlanning = planningList
      }
    });
  },
});

export const getTimesheets = createAsyncThunk(
  "planning/getTimesheets",
  async () => {
    try {
      let response = await NetworkManager.getInstance().getRequest(
        config.apiPaths.planning.getTimesheets,
      )
      return response;
    } catch (error) {
      return error
    }
  }
)

export const getCollabPlanning = createAsyncThunk(
  "planning/getCollabPlanning",
  async (body) => {
    try {
      store.dispatch(setIsLoading(true))
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getCollabPlanning,
        body
      )
      store.dispatch(setIsLoading(false))
      return response;
    } catch (error) {
      return error;
    }
  }
);


export const {
  setSelectedPlanning,
  setModifiedPlanning,
  setSelectedTimesheet,
  setHasModifications,
  setSelectedSamplesViewTimesheet
} = timesheetSlice.actions;

export default timesheetSlice.reducer;
