import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import addIcon from "../../assets/images/add.png";
import addIconFocus from "../../assets/images/addFocus.png";
import exportIcon from "../../assets/images/export.png";
import exportIconFocus from "../../assets/images/exportFocus.png";
import { Col, Row, Toast } from "react-bootstrap";
import Langs from "../../app/lang/langs";
import styles from "./Reports.module.css";
import { TeamSelector } from "../../components/teams/TeamSelector";
import ImageButton from "../../components/buttons/ImageButton";
import { ReportFormModal } from "./ReportFormModal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from 'primereact/api';
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { getClients, getReports, setSelectedView } from "./reportsSlice";
import { Tag } from "primereact/tag";
import { getStatusTagClass } from "../../app/utils/helpers";
import NetworkManager from "../../app/managers/networkManager";
import { showErrorToast } from "../../app/utils/helpers";
import { ViewSelectButton } from "../../components/buttons/ViewSelectButton";

export function PublicationsView() {

    const dispatch = useDispatch()

    const publications = useSelector((state) => state.reports.publications)
    const isMobile = useSelector((state) => state.settings.isMobile)

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const toast = useRef(null)
    const dt = useRef(null)
    const mounted = useRef(null)

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
        }
    })

    const onGlobalFilterChange = (e) => {
        const value = e.target.value
        let _filters = { ...filters }

        _filters['global'].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const actionBodyTemplate = (rowData) => {

    }

    const statusBodyTemplate = (rowData) => {
        return <Tag
            value={<Langs str={rowData.status} />}
            severity={getStatusTagClass(rowData.status)}>
        </Tag>
    }

    const header = isMobile == 3 ? null : (
        <Row>
            <Col md={3}>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Search..."
                    />
                </span>
            </Col>
            <Col md={9}></Col>
        </Row>
    )

    return (<div>
        <Toast ref={toast} />
        <div className={styles.card}>
            <DataTable ref={dt} value={publications}
                paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50, 100]} scrollable
                scrollHeight={isMobile == 3 ? "calc(100vh - 210px)" : "calc(100vh - 315px)"}
                paginatorTemplate={isMobile == 3 ? "PrevPageLink NextPageLink" : "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"}
                currentPageReportTemplate={"Showing {first} to {last} of {totalRecords}"}
                header={header}
                filters={isMobile == 3 ? null : filters}>
                {/* <Column selectionMode="multiple" exportable={false}></Column> */}
                <Column field="name" header={<Langs str="Client" />} sortable filter={isMobile == 3} filterPlaceholder={isMobile == 3 ? "Search by name" : undefined} style={{ minWidth: '11rem' }}></Column>
                <Column field="createdAt" header={<Langs str="CREATED_AT" />} sortable style={{ minWidth: '12rem' }}></Column>
                <Column field="createdBy" header={<Langs str="CREATED_BY" />} sortable style={{ minWidth: '12rem' }}></Column>
                <Column field="status" header={<Langs str="status" />} body={statusBodyTemplate} sortable style={{ minWidth: '8rem' }}></Column>
                {isMobile == 1 && (
                    <Column
                        body={actionBodyTemplate}
                        exportable={false}
                        style={{ minWidth: '6rem', textAlign: "right" }}
                        frozen
                        alignFrozen="right"
                    />
                )}
            </DataTable>
        </div>
    </div>
    )
}
