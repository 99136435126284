import React, { useRef, useState } from "react";
import { Dialog } from 'primereact/dialog';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import ImageButton from "../../components/buttons/ImageButton";
import deleteIcon from "../../assets/images/delete.png";
import deleteIconFocus from "../../assets/images/delete-orange.png";
import editIcon from "../../assets/images/edit.png";
import editIconFocus from "../../assets/images/editFocus.png";
import addIcon from "../../assets/images/addIconOrange.png";
import addIconFocus from "../../assets/images/addFocus.png";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';

const EntretienModal = ({ visible, onHide }) => {
    const dispatch = useDispatch();
    const stepperRef = useRef(null);
    const [activeStep, setActiveStep] = useState(0);
    const selectedEntretien = useSelector((state) => state.ventr.selectedEntretien);

    const niveauOptions = [
        { label: "BAC +2", value: "BAC +2" },
        { label: "BAC +3", value: "BAC +3" },
        { label: "BAC +4", value: "BAC +4" },
        { label: "BAC +5", value: "BAC +5" },
        { label: "BAC +8", value: "BAC +8" }
    ];

    const periodeOptions = [
        { label: "Des que possible", value: "Des que possible" },
        { label: "Cette année", value: "Cette année" },
        { label: "Dans 6 mois", value: "Dans 6 mois" }
    ];

    const certificationOptions = [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" }
    ];

    const financementOptions = [
        { label: "CPF", value: "CPF" },
        { label: "Employeur", value: "Employeur" },
        { label: "Autofinancé", value: "Autofinancé" }
    ];

    const souhaitOptions = [
        { label: "Oui", value: true },
        { label: "Non", value: false }
    ];

    const originOptions = [
        { label: "Salarié", value: "collab" },
        { label: "Employeur", value: "employeur" }
    ];

    const diplomeOptions = [
        { label: "Brevet des collèges", value: "Brevet des collèges" },
        { label: "CAP", value: "CAP" },
        { label: "BEP", value: "BEP" },
        { label: "Baccalauréat", value: "Baccalauréat" },
        { label: "BTS", value: "BTS" },
        { label: "DUT", value: "DUT" },
        { label: "Licence", value: "Licence" },
        { label: "Licence professionnelle", value: "Licence professionnelle" },
        { label: "Master", value: "Master" },
        { label: "Master professionnel", value: "Master professionnel" },
        { label: "MBA", value: "MBA" },
        { label: "Diplôme d'ingénieur", value: "Diplôme d'ingénieur" },
        { label: "Doctorat", value: "Doctorat" }
    ];

    const posteOptions = [
        "ADMINISTRATEUR SYSTEME",
        "ANALYSTE D'EXPLOITATION",
        "ASSISTANTE MANAGERIALE",
        "ASSISTANTE DE GESTION",
        "CHARGE CRM",
        "CHARGE DE COMMUNICATION",
        "CHARGE DE RECHERCHE",
        "CHARGE DE RECRUTEMENT",
        "CHEF DE PROJET AMOA - MOA",
        "CHEF DE PROJET INFRA",
        "CHEF DE PROJET MOE",
        "COORDINATEUR TECHNIQUE",
        "DBA PROD",
        "DEVELOPPEUR WEB - NET",
        "DIRECTEUR DE PROJET",
        "GESTIONNAIRE DE PARC",
        "HOTESSE D'ACCUEIL",
        "INCIDENT PROLEM MANAGER",
        "INGENIEUR BIG DATA",
        "INGENIEUR DE DEVELOPPEMENT",
        "INGENIEUR DE PRODUCTION",
        "INGENIEUR DEVOPS",
        "INGENIEUR POSTE DE TRAVAIL",
        "INGENIEUR RESEAUX SECURITE",
        "INGENIEUR STOCKAGE SAN - NAS",
        "INGENIEUR SYSTEME",
        "PILOTE D'EXPLOITATION",
        "RESPONSABLE DE RECRUTEMENT",
        "RESPONSABLE OPERATIONNEL PRODUCTION",
        "RESPONSABLE QUALITÉ",
        "SERVICE DELIVERY MANAGER",
        "SUPERVISEUR",
        "TECHNICIEN HD-PROXIMITE",
        "TECHNICIEN HELPDESK N1",
        "TECHNICIEN HOTLINE APPLICATIVE",
        "TECHNICIEN SUPPORT APPLICATIF N2",
        "TECHNICIEN SUPPORT N2",
        "ASSISTANTE RH",
        "DIRECTRICE ADM & FIN",
        "RESPONSABLE COMMERCIAL",
        "GESTIONNAIRE RH",
        "GEST COMPT PAIE ADMIN",
        "CHEF DE PROJET JUNIOR",
        "AUTRE"
    ];


    const initialEntretienData = {
        collab: "",  // Nom complet du collaborateur (ex: "HAFFOUD Yassine")
        status: "Non renseigné",  // Statut par défaut
        date: "N/A",  // Date théorique de l'entretien
        matricule: "N/A",  // Matricule du collaborateur
        entreeDate: "N/A",  // Date d'entrée du collaborateur
        sortieDate: "Présent",  // Date de sortie du collaborateur ou "Présent"
        typeContrat: "N/A",  // Type de contrat
        typeCollab: "N/A",  // Type de collaboration (interne/externe)
        email: "N/A",  // Email du collaborateur
        diplomas: [],  // Liste des diplômes
        proPre: [],  // Expériences professionnelles pré-Vital
        proPost: [],  // Expériences professionnelles post-Vital
        mobility: { lieu: "", commentaire: "", value: false },  // Mobilité géographique
        trainingRequested: [],  // Formations demandées
        trainingLast2Years: [],  // Formations suivies dans les 2 dernières années
        trainingLast6Years: [],  // Formations suivies dans les 6 dernières années
        desiredEvolution: { periode: "", description: "", souhaitResponsabilite: false, souhaitReorientation: false, souhaitChangement: false },  // Évolution désirée
        commentsCollab: "N/A",  // Commentaires du collaborateur
        commentsManager: "N/A",  // Commentaires du manager
        interviewType: "N/A",  // Type d'entretien
        parcoursPro: { periode: "", description: "", souhaitResponsabilite: false, souhaitReorientation: false, souhaitChangement: false },  // Parcours professionnel
        bilanPlus: "N/A",  // Bilan supplémentaire
        lastValidatedInterviewDate: "N/A",  // Date du dernier entretien validé
        clientName: "N/A",  // Nom du client
        teamStartDate: "N/A"  // Date de début dans l'équipe
    };


    const [entretienData, setEntretienData] = useState(initialEntretienData);

    useEffect(() => {
        if (selectedEntretien) {
            setEntretienData({
                ...initialEntretienData,
                ...selectedEntretien,
            });
        } else {
            setEntretienData(initialEntretienData);
        }
    }, [selectedEntretien]);

    const handleHide = () => {
        setActiveStep(0); 
        onHide(); 
    };

    const handleNext = () => {
        if (activeStep < 8) {
            stepperRef.current.nextCallback();
            setActiveStep(activeStep + 1);
        }
    };

    const handlePrev = () => {
        if (activeStep > 0) {
            stepperRef.current.prevCallback();
            setActiveStep(activeStep - 1);
        }
    };

    const customStepStyle = (index) => ({
        backgroundColor: activeStep === index ? '#FF9015' : '#D3D3D3',
        borderRadius: '50%',
        padding: '8px',
        color: 'white',
        fontWeight: 'bold',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '10px'
    });

    const stepHeader = (index, title) => (
        <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => onStepClick(index)}
        >
            <div style={customStepStyle(index)}>{index + 1}</div>
            <div>{title}</div>
        </div>
    );

    const onStepClick = (index) => {
        setActiveStep(index);
    };

    const handleDropdownChange = (value, field, index, section) => {
        const updatedData = { ...entretienData };
        if (section in updatedData) {
            updatedData[section][index][field] = value;
        } else {
            updatedData[field] = value;  // Au cas où la section est un champ direct de l'objet
        }
        setEntretienData(updatedData);
    };

    const handleInputChange = (value, field, section) => {
        const updatedData = { ...entretienData };
        if (section in updatedData) {
            updatedData[section][field] = value;
        } else {
            updatedData[field] = value;  // Au cas où la section est un champ direct de l'objet
        }
        setEntretienData(updatedData);
    };

    const addRow = (section) => {
        // Créer une copie profonde de entretienData
        const updatedData = JSON.parse(JSON.stringify(entretienData));

        if (section === 'diplomas') {
            updatedData.diplomas.push({ annee: "", diplome: "", niveau: "" });
        } else if (section === 'proPre') {
            updatedData.proPre.push({ dateDebut: "", dateFin: "", entreprise: "", poste: "" });
        } else if (section === 'proPost') {
            updatedData.proPost.push({ client: "", poste: "", dateDebut: "", dateFin: "" });
        } else if (section === 'trainingLast2Years') {
            updatedData.trainingLast2Years.push({ date: "", title: "", certification: "", evaluation: "", origin: "", financing: "" });
        } else if (section === 'trainingRequested') {
            updatedData.trainingRequested.push({ periode: "", formation: "", financement: "", commentaire: "" });
        } else if (section === 'trainingLast6Years') {
            updatedData.trainingLast6Years.push({ date: "", title: "", certification: "", evaluation: "", origin: "", financing: "" });
        }

        // Mettre à jour l'état
        setEntretienData(updatedData);
    };


    const deleteRow = (section, rowIndex) => {
        const updatedData = { ...entretienData };
        if (section in updatedData) {
            updatedData[section].splice(rowIndex, 1);
        }
        setEntretienData(updatedData);
    };


    return (
        <Dialog visible={visible} style={{ width: '70vw' }} onHide={handleHide} header="Détails de l'entretien">
            <style>{`.p-stepper-number { display: none; }`}</style>
            <div className="card flex justify-content-center">
                <Stepper ref={stepperRef} activeIndex={activeStep} style={{ height: 'auto' }}>
                    {/* Diplômes */}
                    <StepperPanel header={stepHeader(0, "Diplômes")}>
                        <DataTable
                            value={entretienData.diplomas}
                            tableStyle={{ minWidth: '60rem' }}
                            header={
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <ImageButton
                                        tooltip="Ajouter"
                                        image={addIcon}
                                        imageFocus={addIconFocus}
                                        active={true}
                                        onClick={() => addRow('diplomas')}
                                        width={22}
                                        height={22}
                                    />
                                </div>
                            }
                        >
                            <Column
                                field="annee"
                                header="Année"
                                body={(rowData, options) => (
                                    <Calendar
                                        value={new Date(rowData.annee, 0)}
                                        view="year"
                                        dateFormat="yy"
                                        onChange={(e) => handleDropdownChange(e.value.getFullYear(), 'annee', options.rowIndex, 'diplomas')}
                                    />
                                )}
                            />
                            <Column
                                field="diplome"
                                header="Diplôme"
                                body={(rowData, options) => (
                                    <Dropdown
                                        value={rowData.diplome}
                                        options={diplomeOptions}
                                        onChange={(e) => handleDropdownChange(e.value, 'diplome', options.rowIndex, 'diplomas')}
                                        placeholder="Sélectionner un diplôme"
                                    />
                                )}
                            />
                            <Column
                                field="niveau"
                                header="Niveau"
                                body={(rowData, options) => (
                                    <Dropdown
                                        value={rowData.niveau}
                                        options={niveauOptions}
                                        onChange={(e) => handleDropdownChange(e.value, 'niveau', options.rowIndex, 'diplomas')}
                                        placeholder="Sélectionner un niveau"
                                    />
                                )}
                            />
                            <Column
                                header="Actions"
                                body={(rowData, options) => (
                                    <ImageButton
                                        tooltip="Supprimer"
                                        image={deleteIcon}
                                        imageFocus={deleteIconFocus}
                                        active={true}
                                        onClick={() => deleteRow('diplomas', options.rowIndex)}
                                        width={22}
                                        height={22}
                                    />
                                )}
                            />
                        </DataTable>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                            <div />
                            <div>
                                <PrimaryButton title="Next" active={true} onClick={handleNext} />
                            </div>
                        </div>
                    </StepperPanel>


                    <StepperPanel header={stepHeader(1, "Parcours professionnel pré-Vital")}>
                        <DataTable
                            value={entretienData.proPre}
                            tableStyle={{ minWidth: '60rem' }}
                            header={
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <ImageButton
                                        tooltip="Ajouter"
                                        image={addIcon}
                                        imageFocus={addIconFocus}
                                        active={true}
                                        onClick={() => addRow('proPre')}
                                        width={22}
                                        height={22}
                                    />
                                </div>
                            }
                        >
                            <Column field="dateDebut" header="Date de début" body={(rowData, options) => (
                                <Calendar
                                    value={new Date(rowData.dateDebut)}
                                    onChange={(e) => handleDropdownChange(e.value.toLocaleDateString(), 'dateDebut', options.rowIndex, 'proPre')}
                                />
                            )} />
                            <Column field="dateFin" header="Date de fin" body={(rowData, options) => (
                                <Calendar
                                    value={new Date(rowData.dateFin)}
                                    onChange={(e) => handleDropdownChange(e.value.toLocaleDateString(), 'dateFin', options.rowIndex, 'proPre')}
                                />
                            )} />
                            <Column
                                field="poste"
                                header="Poste"
                                body={(rowData, options) => (
                                    <Dropdown
                                        value={rowData.poste}
                                        options={posteOptions}
                                        onChange={(e) => handleDropdownChange(e.value, 'poste', options.rowIndex, 'proPre')}
                                        placeholder="Sélectionner un poste"
                                    />
                                )}
                            />
                            <Column
                                field="entreprise"
                                header="Entreprise"
                                body={(rowData, options) => (
                                    <InputText
                                        value={rowData.entreprise}
                                        onChange={(e) => handleInputChange(e.target.value, 'entreprise', options.rowIndex, 'proPre')}
                                    />
                                )}
                            />
                            <Column
                                header="Actions"
                                body={(rowData, options) => (
                                    <ImageButton
                                        tooltip="Supprimer"
                                        image={deleteIcon}
                                        imageFocus={deleteIconFocus}
                                        active={true}
                                        onClick={() => deleteRow('proPre', options.rowIndex)}
                                        width={22}
                                        height={22}
                                    />
                                )}
                            />
                        </DataTable>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                            <div>
                                <PrimaryButton title="Back" active={true} onClick={handlePrev} />
                            </div>
                            <div>
                                <PrimaryButton title="Next" active={true} onClick={handleNext} />
                            </div>
                        </div>
                    </StepperPanel>


                    <StepperPanel header={stepHeader(2, "Parcours professionnel chez Vital")}>
                        <DataTable
                            value={entretienData.proPost}
                            tableStyle={{ minWidth: '60rem' }}
                            header={
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <ImageButton
                                        tooltip="Ajouter"
                                        image={addIcon}
                                        imageFocus={addIconFocus}
                                        active={true}
                                        onClick={() => addRow('proPost')}
                                        width={22}
                                        height={22}
                                    />
                                </div>
                            }
                        >
                            <Column field="client" header="Client" body={(rowData, options) => (
                                <InputText
                                    value={rowData.client}
                                    onChange={(e) => handleDropdownChange(e.target.value, 'client', options.rowIndex, 'proPost')}
                                />
                            )} />
                            <Column field="dateDebut" header="Date de début" body={(rowData, options) => (
                                <Calendar
                                    value={new Date(rowData.dateDebut)}
                                    onChange={(e) => handleDropdownChange(e.value.toLocaleDateString(), 'dateDebut', options.rowIndex, 'proPost')}
                                />
                            )} />
                            <Column field="dateFin" header="Date de fin" body={(rowData, options) => (
                                <Calendar
                                    value={new Date(rowData.dateFin)}
                                    onChange={(e) => handleDropdownChange(e.value.toLocaleDateString(), 'dateFin', options.rowIndex, 'proPost')}
                                />
                            )} />
                            <Column field="poste" header="Poste" body={(rowData, options) => (
                                <Dropdown
                                    value={rowData.poste}
                                    options={posteOptions}
                                    onChange={(e) => handleDropdownChange(e.value, 'poste', options.rowIndex, 'proPost')}
                                    placeholder="Sélectionner un poste"
                                />
                            )} />
                            <Column
                                header="Actions"
                                body={(rowData, options) => (
                                    <ImageButton
                                        tooltip="Supprimer"
                                        image={deleteIcon}
                                        imageFocus={deleteIconFocus}
                                        active={true}
                                        onClick={() => deleteRow('proPost', options.rowIndex)}
                                        width={22}
                                        height={22}
                                    />
                                )}
                            />
                        </DataTable>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                            <div>
                                <PrimaryButton title="Back" active={true} onClick={handlePrev} />
                            </div>
                            <div>
                                <PrimaryButton title="Next" active={true} onClick={handleNext} />
                            </div>
                        </div>
                    </StepperPanel>


                    <StepperPanel header={stepHeader(3, "Formations Suivies (2 ans)")}>
                        <DataTable
                            value={entretienData.trainingLast2Years}
                            tableStyle={{ minWidth: '60rem' }}
                            header={<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <ImageButton
                                    tooltip="Ajouter"
                                    image={addIcon}
                                    imageFocus={addIconFocus}
                                    active={true}
                                    onClick={() => addRow('trainingLast2Years')}
                                    width={22}
                                    height={22}
                                />
                            </div>}
                        >
                            <Column field="date" header="Date" body={(rowData, options) => (
                                <Calendar value={new Date(rowData.date)} onChange={(e) => handleDropdownChange(e.value.toLocaleDateString(), 'date', options.rowIndex, 'trainingLast2Years')} />
                            )} />
                            <Column field="title" header="Titre" body={(rowData, options) => (
                                <InputText value={rowData.title} onChange={(e) => handleDropdownChange(e.target.value, 'title', options.rowIndex, 'trainingLast2Years')} />
                            )} />
                            <Column field="certification" header="Certification obtenue" body={(rowData, options) => (
                                <Dropdown value={rowData.certification} options={certificationOptions} onChange={(e) => handleDropdownChange(e.value, 'certification', options.rowIndex, 'trainingLast2Years')} />
                            )} />
                            <Column field="evaluation" header="Évaluation" body={(rowData, options) => (
                                <InputText value={rowData.evaluation} onChange={(e) => handleInputChange(e.target.value, 'evaluation', options.rowIndex, 'trainingLast2Years')} />
                            )} />
                            <Column field="origin" header="Origine" body={(rowData, options) => (
                                <Dropdown value={rowData.origin} options={originOptions} onChange={(e) => handleDropdownChange(e.target.value, 'origin', options.rowIndex, 'trainingLast2Years')} />
                            )} />
                            <Column field="financing" header="Financement" body={(rowData, options) => (
                                <Dropdown value={rowData.financing} options={financementOptions} onChange={(e) => handleDropdownChange(e.value, 'financing', options.rowIndex, 'trainingLast2Years')} />
                            )} />
                            <Column
                                header="Actions"
                                body={(rowData, options) => (
                                    <ImageButton
                                        tooltip="Supprimer"
                                        image={deleteIcon}
                                        imageFocus={deleteIconFocus}
                                        active={true}
                                        onClick={() => deleteRow('trainingLast2Years', options.rowIndex)}
                                        width={22}
                                        height={22}
                                    />
                                )}
                            />
                        </DataTable>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                            <div>
                                <PrimaryButton title="Back" active={true} onClick={handlePrev} />
                            </div>
                            <div>
                                <PrimaryButton title="Next" active={true} onClick={handleNext} />
                            </div>
                        </div>
                    </StepperPanel>

                    {/* Projet professionnel */}
                    <StepperPanel header={stepHeader(4, "Projet professionnel")}>
                        <div className="p-4">
                            <Row className="pb-3">
                                <Col lg={4}>
                                    <label htmlFor="periode">Période souhaitée:</label>
                                </Col>
                                <Col lg={8}>
                                    <Dropdown
                                        id="periode"
                                        value={entretienData.parcoursPro.periode}
                                        options={periodeOptions}
                                        onChange={(e) => handleInputChange(e.value, 'periode', 'parcoursPro')}
                                        placeholder="Sélectionner une période"
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>

                            <Row className="pb-3">
                                <Col lg={4}>
                                    <label htmlFor="description">Commentaires:</label>
                                </Col>
                                <Col lg={8}>
                                    <InputText
                                        id="description"
                                        value={entretienData.parcoursPro.description}
                                        onChange={(e) => handleInputChange(e.target.value, 'description', 'parcoursPro')}
                                        placeholder="Ajouter un commentaire"
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>

                            <Row className="pb-3">
                                <Col lg={4}>
                                    <label htmlFor="souhaitReorientation">Souhaitez-vous vous réorienter?</label>
                                </Col>
                                <Col lg={8}>
                                    <Dropdown
                                        id="souhaitReorientation"
                                        value={entretienData.parcoursPro.souhaitReorientation}
                                        options={souhaitOptions}
                                        onChange={(e) => handleInputChange(e.value, 'souhaitReorientation', 'parcoursPro')}
                                        placeholder="Sélectionner"
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>

                            <Row className="pb-3">
                                <Col lg={4}>
                                    <label htmlFor="souhaitResponsabilite">Souhaitez-vous avoir une prise de responsabilité?</label>
                                </Col>
                                <Col lg={8}>
                                    <Dropdown
                                        id="souhaitResponsabilite"
                                        value={entretienData.parcoursPro.souhaitResponsabilite}
                                        options={souhaitOptions}
                                        onChange={(e) => handleInputChange(e.value, 'souhaitResponsabilite', 'parcoursPro')}
                                        placeholder="Sélectionner"
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>

                            <Row className="pb-3">
                                <Col lg={4}>
                                    <label htmlFor="souhaitChangement">Souhaitez-vous changer de mission/fonction?</label>
                                </Col>
                                <Col lg={8}>
                                    <Dropdown
                                        id="souhaitChangement"
                                        value={entretienData.parcoursPro.souhaitChangement}
                                        options={souhaitOptions}
                                        onChange={(e) => handleInputChange(e.value, 'souhaitChangement', 'parcoursPro')}
                                        placeholder="Sélectionner"
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                            <div>
                                <PrimaryButton title="Back" active={true} onClick={handlePrev} />
                            </div>
                            <div>
                                <PrimaryButton title="Next" active={true} onClick={handleNext} />
                            </div>
                        </div>
                    </StepperPanel>


                    {/* Formations souhaitées */}
                    <StepperPanel header={stepHeader(5, "Formations souhaitées")}>
                        <DataTable
                            value={entretienData.trainingRequested}
                            tableStyle={{ minWidth: '60rem' }}
                            header={<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <ImageButton
                                    tooltip="Ajouter"
                                    image={addIcon}
                                    imageFocus={addIconFocus}
                                    active={true}
                                    onClick={() => addRow('trainingRequested')}
                                    width={22}
                                    height={22}
                                />
                            </div>}
                        >
                            <Column field="periode" header="Période" body={(rowData, options) => (
                                <Dropdown value={rowData.periode} options={periodeOptions} onChange={(e) => handleDropdownChange(e.value, 'periode', options.rowIndex, 'trainingRequested')} />
                            )} />
                            <Column field="formation" header="Formation" body={(rowData, options) => (
                                <InputText value={rowData.formation} onChange={(e) => handleDropdownChange(e.target.value, 'formation', options.rowIndex, 'trainingRequested')} />
                            )} />
                            <Column field="financement" header="Financement" body={(rowData, options) => (
                                <Dropdown value={rowData.financement} options={financementOptions} onChange={(e) => handleDropdownChange(e.value, 'financement', options.rowIndex, 'trainingRequested')} />
                            )} />
                            <Column field="commentaire" header="Commentaire" body={(rowData, options) => (
                                <InputText value={rowData.commentaire} onChange={(e) => handleDropdownChange(e.target.value, 'commentaire', options.rowIndex, 'trainingRequested')} />
                            )} />
                            <Column
                                header="Actions"
                                body={(rowData, options) => (
                                    <ImageButton
                                        tooltip="Supprimer"
                                        image={deleteIcon}
                                        imageFocus={deleteIconFocus}
                                        active={true}
                                        onClick={() => deleteRow('trainingRequested', options.rowIndex)}
                                        width={22}
                                        height={22}
                                    />
                                )}
                            />
                        </DataTable>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                            <div>
                                <PrimaryButton title="Back" active={true} onClick={handlePrev} />
                            </div>
                            <div>
                                <PrimaryButton title="Next" active={true} onClick={handleNext} />
                            </div>
                        </div>
                    </StepperPanel>


                    {/* Mobilité géographique */}
                    <StepperPanel header={stepHeader(6, "Mobilité géographique")}>
                        <div className="p-4">
                            <p>Pour rappel, le Groupe Vital dispose de deux centres de service à Paris et à Bordeaux</p>

                            <Row className="pb-3">
                                <Col lg={4}>
                                    <label htmlFor="reorientation">Souhaitez-vous vous réorienter ?</label>
                                </Col>
                                <Col lg={8}>
                                    <Dropdown
                                        id="reorientation"
                                        value={entretienData.mobility.value}
                                        options={souhaitOptions}
                                        onChange={(e) => handleInputChange(e.value, 'value', 'mobility')}
                                        placeholder="Sélectionner"
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>

                            <Row className="pb-3">
                                <Col lg={4}>
                                    <label htmlFor="lieu">Préciser le lieu :</label>
                                </Col>
                                <Col lg={8}>
                                    <InputText
                                        id="lieu"
                                        value={entretienData.mobility.lieu}
                                        onChange={(e) => handleInputChange(e.target.value, 'lieu', 'mobility')}
                                        placeholder="Indiquez un lieu"
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>

                            <Row className="pb-3">
                                <Col lg={4}>
                                    <label htmlFor="commentaire">Commentaire :</label>
                                </Col>
                                <Col lg={8}>
                                    <InputText
                                        id="commentaire"
                                        value={entretienData.mobility.commentaire}
                                        onChange={(e) => handleInputChange(e.target.value, 'commentaire', 'mobility')}
                                        placeholder="Ajouter un commentaire"
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                            <div>
                                <PrimaryButton title="Back" active={true} onClick={handlePrev} />
                            </div>
                            <div>
                                <PrimaryButton title="Next" active={true} onClick={handleNext} />
                            </div>
                        </div>
                    </StepperPanel>


                    {/* Formations 6 ans */}
                    <StepperPanel header={stepHeader(7, "Formations 6 ans")}>
                        <DataTable
                            value={entretienData.trainingLast6Years}
                            tableStyle={{ minWidth: '60rem' }}
                            header={<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <ImageButton
                                    tooltip="Ajouter"
                                    image={addIcon}
                                    imageFocus={addIconFocus}
                                    active={true}
                                    onClick={() => addRow('trainingLast6Years')}
                                    width={22}
                                    height={22}
                                />
                            </div>}
                        >
                            {/* Date formation */}
                            <Column field="date" header="Date formation" body={(rowData, options) => (
                                <Calendar value={new Date(rowData.date)} onChange={(e) => handleDropdownChange(e.value.toLocaleDateString(), 'date', options.rowIndex, 'trainingLast6Years')} />
                            )} />

                            {/* Formation/Certification */}
                            <Column field="title" header="Formation/Certification" body={(rowData, options) => (
                                <InputText value={rowData.title} onChange={(e) => handleDropdownChange(e.target.value, 'title', options.rowIndex, 'trainingLast6Years')} />
                            )} />

                            {/* Diplôme/certification obtenue */}
                            <Column field="certification" header="Diplôme/certification obtenue" body={(rowData, options) => (
                                <Dropdown value={rowData.certification} options={certificationOptions} onChange={(e) => handleDropdownChange(e.value, 'certification', options.rowIndex, 'trainingLast6Years')} />
                            )} />

                            {/* Évaluation */}
                            <Column field="evaluation" header="Évaluation" body={(rowData, options) => (
                                <InputText value={rowData.evaluation} onChange={(e) => handleDropdownChange(e.target.value, 'evaluation', options.rowIndex, 'trainingLast6Years')} />
                            )} />

                            {/* Origine */}
                            <Column field="origin" header="Origine" body={(rowData, options) => (
                                <Dropdown value={rowData.origin} options={[{ label: 'Employé', value: 'Employé' }, { label: 'Employeur', value: 'Employeur' }]} onChange={(e) => handleDropdownChange(e.value, 'origin', options.rowIndex, 'trainingLast6Years')} />
                            )} />

                            {/* Financement */}
                            <Column field="financing" header="Financement" body={(rowData, options) => (
                                <Dropdown value={rowData.financing} options={financementOptions} onChange={(e) => handleDropdownChange(e.value, 'financing', options.rowIndex, 'trainingLast6Years')} />
                            )} />

                            <Column
                                header="Actions"
                                body={(rowData, options) => (
                                    <ImageButton
                                        tooltip="Supprimer"
                                        image={deleteIcon}
                                        imageFocus={deleteIconFocus}
                                        active={true}
                                        onClick={() => deleteRow('trainingLast6Years', options.rowIndex)}
                                        width={22}
                                        height={22}
                                    />
                                )}
                            />
                        </DataTable>

                        <div className="pt-4">
                            <label>D'autres actions ont-elles été menées? (bilan de compétences, conseil en évolution professionnel) </label>
                            <InputText value={entretienData.bilanPlus} onChange={(e) => handleInputChange(e.target.value, 'bilanPlus', 'entretienData')} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                            <div>
                                <PrimaryButton title="Back" active={true} onClick={handlePrev} />
                            </div>
                            <div>
                                <PrimaryButton title="Next" active={true} onClick={handleNext} />
                            </div>
                        </div>
                    </StepperPanel>

                    {/* Bilan et commentaires */}
                    <StepperPanel header={stepHeader(8, "Bilan et commentaires")}>
                        <div className="p-4">
                            <Row className="pb-3">
                                <Col lg={4}>
                                    <label htmlFor="commentsCollab">Commentaires globaux (collaborateur):</label>
                                </Col>
                                <Col lg={8}>
                                    <InputText
                                        id="commentsCollab"
                                        value={entretienData.commentsCollab}
                                        onChange={(e) => handleInputChange(e.target.value, 'commentsCollab', 'entretienData')}
                                        placeholder="Ajouter un commentaire"
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>

                            <Row className="pb-3">
                                <Col lg={4}>
                                    <label htmlFor="commentsManager">Commentaires du manager:</label>
                                </Col>
                                <Col lg={8}>
                                    <InputText
                                        id="commentsManager"
                                        value={entretienData.commentsManager}
                                        onChange={(e) => handleInputChange(e.target.value, 'commentsManager', 'entretienData')}
                                        placeholder="Ajouter un commentaire"
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                            <div>
                                <PrimaryButton title="Back" active={true} onClick={handlePrev} />
                            </div>
                            <div>
                                <PrimaryButton title="Finish" active={true} onClick={() => {handleHide() }} />
                            </div>
                        </div>
                    </StepperPanel>

                </Stepper>
            </div>
        </Dialog>
    );
};

export default EntretienModal;
