import React, { useEffect, useRef, useState } from "react";
import styles from "./Vcomp.module.css";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Langs from "../../app/lang/langs";
import ProjectCard from "../../components/vcomp/projectCard";
import ImageButton from "../../components/buttons/ImageButton";
import addIcon from '../../assets/images/add.png'
import addIconFocus from '../../assets/images/addFocus.png'
import CreateProjectModal from "../../components/vcomp/createProjectModal";
import { setAdminConfigModal, setCreateProjectModalVisible, setSelectedView } from "../../components/vcomp/vcompSlice";
import { checkPermission, isAdmin, isOwner } from "../../app/utils/helpers";
import { config } from "../../app/utils/config";
import { getProjects } from "../../components/vcomp/vcompSlice";
import './Vcomp.css'
import { ViewSelectButton } from "../../components/buttons/ViewSelectButton";
import configIcon from "../../assets/images/configIconOrange.png";
import configIconFocus from "../../assets/images/configIconOrangeFill.png"
import AdminFormConfig from "../../components/vcomp/AdminFormConfig";
import CollabProjectForm from "../../components/vcomp/CollabProjectForm";
import { InputText } from "primereact/inputtext";
import CollabMcqView from "../../components/vcomp/CollabMcqView";

export default function VcompView() {

    const mounted = useRef(null)
    const dispatch = useDispatch()



    const isMobile = useSelector((state) => state.settings.isMobile);
    const projectsList = useSelector((state) => state.vcomp.projectsList);
    const selectedView = useSelector((state) => state.vcomp.selectedView);
    const userRoleId = useSelector((state) => state.login.userRoleId);


    const [manFilter, setManFilter] = useState('')
    const [colFilter, setColFilter] = useState('')

    const [iManageProjects, setIManageProjects] = useState([])
    const [iParticipateProjects, setIParticipateProjects] = useState([])

    const [viewsOptions, setViewOptions] = useState([
        { label: <Langs str='Participate' />, value: 0 },
        { label: <Langs str='Manage' />, value: 1 }
    ])


    useEffect(() => {
        if (!mounted.current) {
            dispatch(getProjects())
            mounted.current = true
        }
    }, [dispatch])

    useEffect(() => {
        setIManageProjects(projectsList.filter((project) => project.isManager == 1))
        setIParticipateProjects(projectsList.filter((project) => project.isCollab == 1))
    }, [projectsList])



    const handleViewChange = (view) => {
        if (view != null) {
            dispatch(setSelectedView(view))
        }
    }


    const body = () => {
        return (
            <div className={`d-flex justify-content-center pt-4 ${styles.collabsCard} ${isMobile === 2 ? styles.mobileBody : ''}`} >
                {selectedView == 0 &&
                    <Row className={`d-flex justify-content-start ${styles.projectsRow}`} style={{ width: "92%", height: "40vh" }}>
                        <Col className="ps-3 pb-1" xs={12}>
                            <InputText placeholder="Rechercher..." value={colFilter} onChange={(e) => setColFilter(e.target.value)} style={{ height: "40px", width:"300px" }} />
                        </Col>
                        {iParticipateProjects.filter(p => p.active === 1 && p.nom.toLowerCase().includes(colFilter)).map((p) => {
                            return (p.active === 1 &&
                                <ProjectCard project={p} />
                            )
                        })}
                    </Row>
                }
                {selectedView == 1 &&
                    <Row className={`d-flex justify-content-start ${styles.projectsRow}`} style={{ width: "92%", height: "40vh" }}>
                        <Col className="ps-3 pb-1" xs={12}>
                            <InputText placeholder="Rechercher..." value={manFilter} onChange={(e) => setManFilter(e.target.value)} style={{ height: "40px", width:"300px" }} />
                        </Col>
                        {iManageProjects.filter(p => (p.active === 1 || isOwner(userRoleId)) && p.nom.toLowerCase().includes(manFilter)).map((p) => {
                            return <ProjectCard project={p} manage={true} />
                        })}
                    </Row>
                }
            </div>
        )
    }

    return (
        <div>
            <div>
                <div className="topMenu" style={{ height: 'auto' }}>
                    <Row>
                        <Col lg={4} xs={12}>
                            <span className="headerTitle">
                                {<Langs str="V-Comp" />}
                            </span>
                        </Col>
                        <Col lg={4} xs={9}
                            className={(isMobile == 1 || isMobile == 3) ? "d-flex justify-content-center " : "d-flex justify-content-start mt-2"}>
                            {iManageProjects.length > 0 &&
                                <ViewSelectButton
                                    options={viewsOptions}
                                    selectedView={viewsOptions[selectedView].value}
                                    onViewChange={handleViewChange}
                                />
                            }
                        </Col>
                        {selectedView == 1 &&  <Col lg={4} xs={3} className={(isMobile == 1 || isMobile == 3) ? "d-flex justify-content-end pe-4 align-items-end p-0 m-0" : "d-flex justify-content-end px-2 align-items-center p-0 m-0 mt-2"}>
                            {isOwner(userRoleId) &&
                                <ImageButton
                                    image={configIcon}
                                    imageFocus={configIconFocus}
                                    width={32}
                                    active={true}
                                    onClick={() => { dispatch(setAdminConfigModal(true)) }}
                                />
                            }
                            {checkPermission(config.scopes.vcomp, config.permissionTypes.edit) &&
                                <ImageButton
                                    image={addIcon}
                                    imageFocus={addIconFocus}
                                    width={32}
                                    active={true}
                                    onClick={() => { dispatch(setCreateProjectModalVisible(true)) }}
                                />
                            }
                        </Col>}
                    </Row>
                </div>
                {body()}
                <CreateProjectModal />
                <AdminFormConfig />
                <CollabProjectForm />
                <CollabMcqView />
            </div>
        </div>
    )
}