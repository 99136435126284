import Select from 'react-select';
import { selectInputStyles, selectInputErrorStyles } from './inputStyles'
import Langs from '../../app/lang/langs';

export function SelectInput(props) {

    function getSelectedOption() {
        if (props.isMulti && props.values) {
            return props.options.filter(o => props.values.includes(o.value))
        } else {
            for (const key in props.options) {
                let option = props.options[key]
                if (option.value == props.value)
                    return option
            }
        }
    }
    // function translateOption(option){
    //     return {<Langs=option />}
    // }
    if (props.translate) {

    }
    return <Select
        isDisabled={props.disabled}
        value={getSelectedOption()}
        onChange={props.onChange}
        options={props.options}
        isMulti={props.isMulti}
        styles={props.error ? selectInputErrorStyles : selectInputStyles}
    />
}