import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tree } from 'primereact/tree';
import styles from './VcompCompnents.module.css'
import './VcompCompnentsStyle.css'
import { Col, Row } from "react-bootstrap";
import { ProgressBar } from 'primereact/progressbar';
import { FloatLabel } from "primereact/floatlabel";
import { InputNumber } from "primereact/inputnumber";
import Langs from "../../app/lang/langs";
import { getProjectProgress } from "./vcompSlice";
import { Dropdown } from "primereact/dropdown";
import AptitudeModal from "./AptitudeModal";
import moment from "moment";
import { Button } from "primereact/button";

export default function ProgressView() {

    const dispatch = useDispatch()

    const progressDetails = useSelector((state) => state.vcomp.projectProgressDetails)
    const projectProgress = useSelector((state) => state.vcomp.projectProgress)
    const onEditProject = useSelector((state) => state.vcomp.onCreateProject)
    const mode = useSelector((state) => state.vcomp.projectMode)
    const collabsNames = useSelector((state) => state.vcomp.collabsNames);
    const collabsIds = useSelector((state) => state.vcomp.projectCollabs);
    const isMobile = useSelector((state) => state.settings.isMobile);
    const sessionStartDate = useSelector((state) => state.vcomp.sessionStartDate);
    const sessionEndDate = useSelector((state) => state.vcomp.sessionEndDate);

    const [nodes, setNodes] = useState([])
    const [sessionNumber, setSesssionNumber] = useState(0)
    const [maxSession, setMaxSession] = useState(0)
    const [collabsList, setCollabsList] = useState([])
    const [associatedMembers, setAssociatedMembers] = useState()
    const [localProg, setLocalProg] = useState(0)

    const [showModal, setShowModal] = useState(false);
    const [selectedAptitude, setSelectedAptitude] = useState(null);
    const [editAptitudeDetails, setEditAptitudeDetails] = useState(false)

    const handleClose = () => {
        setShowModal(false);
        setSelectedAptitude(null);
        setEditAptitudeDetails(false);
    };

    const handleShow = () => setShowModal(true);
    const [expandedKeys, setExpandedKeys] = useState({ '0': true, '0-0': true });

    const expandAll = () => {
        let _expandedKeys = {};

        for (let node of nodes) {
            expandNode(node, _expandedKeys);
        }

        setExpandedKeys(_expandedKeys);
    };

    const collapseAll = () => {
        setExpandedKeys({});
    };

    const expandNode = (node, _expandedKeys) => {
        if (node.children && node.children.length) {
            _expandedKeys[node.key] = true;

            for (let child of node.children) {
                expandNode(child, _expandedKeys);
            }
        }
    };

    useEffect(() => {
        let IdCollabs = collabsIds.map(collab => collab.id_collab)
        setCollabsList(collabsNames.filter(collab => IdCollabs.includes(collab.id)).map(({ id, nom, prenom }) => ({
            id,
            name: `${nom} ${prenom}`
        })));

    }, [collabsIds])

    useEffect(() => {
        if (associatedMembers && associatedMembers.id) {
            const categories = filterByCollabIdAndRecalculateScores(nodes, associatedMembers);
            const totalCoef = categories.reduce((acc, cat) => acc + (cat.coef || 1), 0);
            const weightedSum = categories.reduce((acc, cat) => acc + ((cat.progress || 0) * (cat.coef || 1)), 0);
            const averageProgress = totalCoef > 0 ? weightedSum / totalCoef : 0;
            setLocalProg(parseFloat(averageProgress).toFixed(2));
        } else {
            setLocalProg(projectProgress);
        }
    }, [associatedMembers, nodes, progressDetails]);


    useEffect(() => {
        if (onEditProject && onEditProject.id) {
            if (mode === 'edit') {
                setSesssionNumber(onEditProject.last_session_number + 1)
                setMaxSession(onEditProject.last_session_number + 1)
                setLocalProg(projectProgress)
            } else {
                setSesssionNumber(0)
                setMaxSession(0)
                setLocalProg(0)
                setCollabsList([])
                setAssociatedMembers(null)
                setNodes([])
            }
        } else {
            setSesssionNumber(0)
            setMaxSession(0)
            setLocalProg(0)
            setCollabsList([])
            setAssociatedMembers([null])
            setNodes([])
        }
    }, [onEditProject])

    useEffect(() => {
        if (progressDetails.length > 0) {
            if (progressDetails?.length > 0) {
                const excludedKeys = [];
                const processNode = (node) => {
                    const keyPrefix = node.scope === 'category' ? 'cat' :
                        node.scope === 'subcategory' ? 'subcat' : 'apt';
                    const key = `${keyPrefix}-${node.id}`;
                    let processedChildren = node.children ? node.children.map(processNode).filter(child => child !== undefined) : [];
                    if ((keyPrefix === 'cat' || keyPrefix === 'subcat') && processedChildren.length === 0) {
                        return undefined;
                    }
                    if (!excludedKeys.includes(key)) {
                        return {
                            key: key,
                            id: node.id,
                            label: node.nom,
                            progress: node.progress,
                            coef: node.coef,
                            className: node.scope,
                            pos: node.pos,
                            visibility: node.visibility,
                            description: node.description,
                            type: node.type,
                            aspect: node.aspect ? node.aspect : null,
                            collabs_answers: node.collabs_answers ? node.collabs_answers : [],
                            collabs_no_answers: node.collabs_no_answers ? node.collabs_no_answers : [],
                            children: node.children ? node.children.map(child => processNode(child)).filter(child => child !== undefined) : [],
                            questions: node.questions ? node.questions : [],
                        };
                    }
                };

                const sdata = progressDetails.map(category =>
                    processNode(category)
                );

                setNodes(sdata.filter(s => s !== undefined));
            }
        } else {
            setNodes([])
        }
    }, [progressDetails])


    function findNodeByKey(tree, key) {
        if (!tree) return null;
        if (!key) return null;
        for (let node of tree) {
            if (node.key === key) {
                return node;
            }
            if (node.children && node.children.length) {
                const found = findNodeByKey(node.children, key);
                if (found) return found;
            }
        }
        return null;
    }

    function filterByCollabIdAndRecalculateScores(data, collabId) {
        const dataClone = JSON.parse(JSON.stringify(data));
        function recurse(elements) {
            return elements.map(element => {
                if (element.className === "aptitude") {
                    const collabAnswers = element.collabs_answers?.find(ca => ca.id_collab === collabId.id);
                    const collabNoAnswers = element.collabs_no_answers?.some(ca => ca.id === collabId.id);
                    if (collabAnswers) {
                        element.progress = collabAnswers.progress;
                    } else if (collabNoAnswers) {
                        element.progress = 0;
                    }
                }
                if (element.children && element.children.length > 0) {
                    element.children = recurse(element.children);
                    const totalCoef = element.children.reduce((acc, child) => acc + child.coef, 0);
                    const weightedSum = element.children.reduce((acc, child) => acc + (child.progress * child.coef), 0);
                    const averageProgress = totalCoef > 0 ? weightedSum / totalCoef : 0;
                    element.progress = averageProgress;
                }
                return element;
            });
        }
        if (associatedMembers && associatedMembers.id) {
            return recurse(dataClone);
        } else {
            return nodes
        }
    }

    const handleSessionNumberChange = (e) => {
        if (e.value == sessionNumber) {
            return
        }
        setSesssionNumber(e.value)
        if (onEditProject && mode === 'edit' && sessionNumber >= 1) {
            let sn = e.value - 1
            dispatch(getProjectProgress({ id_project: onEditProject.id, session: sn }))
        }
    }

    const getColor = (num) => {
        if (num < 0 || num > 100) {
            return 'gray';
        }
        if (num < 25) {
            return 'red';
        } else if (num < 50) {
            return 'orange';
        } else if (num < 75) {
            return '#FFBF00';
        } else {
            return 'green';
        }
    }

    const nodeTemplate = (node) => {

        const valueDisplay = (value) => {
            if (value < 10) {
                return
            }
            return (
                <div className="w-100" >
                    {parseFloat(value).toFixed(1)}%
                </div>
            )
        }
        return (
            <div className="node-container row" style={{ height: 'fit-content' }}>
                <Col lg={5}>
                    <span>{node.label}</span>&nbsp;{node.aspect === 'theoretical' && <b>[T]</b>}{node.aspect === 'practical' && <b>[P]</b>}&nbsp;
                </Col>
                <Col lg={1} >
                    {node.progress < 10 &&
                        <span>{node.progress}%</span>
                    }
                </Col>
                <Col lg={6} className="pe-3 ps-0">
                    <Col lg={12}><ProgressBar color={getColor(node.progress)} displayValueTemplate={valueDisplay} showValue={true} value={node.progress}></ProgressBar></Col>
                </Col>
            </div>
        );
    };

    const viewCollabsRespNodeDoubleClick = (value) => {
        if (associatedMembers) {
            return
        }
        setEditAptitudeDetails(true);
        if (onEditProject && sessionNumber) {
            if (sessionNumber - 1 != onEditProject.last_session_number) {
                setEditAptitudeDetails(false)
            }
        }
        if (value.node.className === "aptitude") {
            setSelectedAptitude(value.node.key);
            handleShow();
        }
    };

    function body() {

        return (
            <div style={{ height: isMobile === 2 ? "calc(80vh - 350px)" : 'calc(100vh - 350px)', overflowY: 'auto' }}>
                <Tree
                    value={filterByCollabIdAndRecalculateScores(nodes, associatedMembers)}
                    filter={true}
                    onNodeDoubleClick={(e) => { viewCollabsRespNodeDoubleClick(e) }}
                    nodeTemplate={nodeTemplate}
                    filterPlaceholder="Search..."
                    className="w-full md:w-30rem custom-tree border-0"
                    expandedKeys={expandedKeys}
                    onToggle={(e) => setExpandedKeys(e.value)}
                />
            </div>
        )
    }
    const optionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <div>
            <Row>
                <Row className="px-4 mt-2">
                    <Col lg={6}>
                        <Dropdown
                            value={associatedMembers}
                            onChange={(e) => setAssociatedMembers(e.value)}
                            options={collabsList}
                            optionLabel="name"
                            placeholder="Sélectionner un collaborateur"
                            showClear
                            filter
                            itemTemplate={optionTemplate}
                            style={{ width: '20rem' }}
                            className="w-full" />
                    </Col>
                    {
                        isMobile == 2 && <Col xs={12} className="my-3"> </Col>
                    }
                    <Col lg={6} className="row">
                        <Col lg={12} xs={12} className={isMobile === 2 ? "d-flex justify-content-start" : "d-flex justify-content-end"}>
                            <FloatLabel>
                                <InputNumber
                                    inputId="session-number"
                                    value={sessionNumber}
                                    onValueChange={(e) => handleSessionNumberChange(e)}
                                    showButtons
                                    buttonLayout="horizontal"
                                    step={1}
                                    min={1}
                                    max={maxSession}
                                    allowEmpty={false}
                                    inputStyle={{ width: "6rem", textAlign: "center" }}
                                    decrementButtonClassName={`p-button-warning `}
                                    incrementButtonClassName="p-button-warning"
                                    incrementButtonIcon="pi pi-plus"
                                    decrementButtonIcon="pi pi-minus" />
                                <label htmlFor="project-delay"><Langs str='Choose Session' /><Langs />
                                </label>
                            </FloatLabel>
                        </Col>
                        <Col
                            lg={12}
                            xs={12}
                            style={{ fontWeight: 'normal', color: '#7c7b7b', fontSize: '15px' }}
                            className={isMobile === 2 ? "d-flex justify-content-start py-2 row" : "d-flex justify-content-end py-2 row"}
                        >
                            {sessionStartDate &&
                                <div className="col-12 d-flex justify-content-end"><Langs str='START_DATE' />: {moment(sessionStartDate).format('DD-MM-YYYY')}</div>
                            }
                            {sessionEndDate &&
                                <div className="col-12 d-flex justify-content-end"><Langs str='END_DATE' />: {moment(sessionEndDate).format('DD-MM-YYYY')}</div>
                            }
                        </Col>
                    </Col>
                </Row>
                <Col lg={12} className="px-4 my-2 d-flex justify-content-start">
                    <Langs str='Overall Progress' />: {localProg < 10 ? `${localProg}%` : ''}
                </Col>
                <Col lg={12} className="px-4 my-2">
                    <ProgressBar color={getColor(localProg)} value={localProg} showValue={localProg >= 10}></ProgressBar>
                </Col>
                <Col lg={12}>
                    <Row>
                        <Col xs={4} className="d-flex p-2 ps-4 justify-content-start align-items-center">
                            <Langs str='Aptitudes' />
                        </Col>
                        <Col xs={8} className="d-flex p-2 pe-4 justify-content-end">
                            <Button className="btn btn-secondary me-2" type="button" label={<Langs str="Expand All" />} onClick={expandAll} />
                            <Button className="btn btn-secondary" type="button" label={<Langs str="Collapse All" />} onClick={collapseAll} />
                        </Col>
                    </Row>
                    {body()}
                </Col>
            </Row>
            {selectedAptitude && (
                <AptitudeModal
                    sessionNumber={sessionNumber}
                    maxSession={maxSession}
                    handleSessionNumberChange={(e) => { handleSessionNumberChange(e) }}
                    show={showModal}
                    handleClose={handleClose}
                    aptitude={findNodeByKey(nodes, selectedAptitude)}
                    edit={editAptitudeDetails || true}
                />
            )}
        </div>
    )
}