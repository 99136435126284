import React, { useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from "react-redux";
import { setCollabMcqResultsModalVisibility, setCollabMcqResults, setCollabIdMcqResults, getCollabMcqResults, setLastResponseNumber, getCollabResponseHistory, setResponseDatesHistory } from "./vcompSlice";
import './ResultsDiv.css';
import { Col, Row } from "react-bootstrap";
import { DynamicButton } from "../buttons/DynamicButton";
import Langs from "../../app/lang/langs";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";

export default function McqResultsModal() {

    const dispatch = useDispatch();

    const visible = useSelector((state) => state.vcomp.collabMcqResultsModalVisibility);
    const idCollab = useSelector((state) => state.vcomp.collabIdMcqResults)
    const project = useSelector((state) => state.vcomp.onCreateProject);
    const results = useSelector((state) => state.vcomp.collabMcqResults);
    const lastResponse = useSelector((state) => state.vcomp.lastResponseNumber);
    const responseHistory = useSelector((state) => state.vcomp.responsesDatesHistory);


    const [selectedResponse, setSelectedResponse] = useState(null)
    const [totalScore, setTotalScore] = useState(0);

    useEffect(() => {
        if (idCollab && project && project.id && lastResponse && lastResponse.response_number) {
            dispatch(getCollabMcqResults({ id_project: project.id, id_collab: idCollab, response_number: lastResponse.response_number }))
            dispatch(getCollabResponseHistory({ id_project: project.id, id_collab: idCollab }))
        }
    }, [idCollab, visible])

    useEffect(() => {
        if (responseHistory.length > 0) {
            setSelectedResponse(responseHistory[0])
        }
    }, [responseHistory])

    const handleResponseNumberChange = (e) => {
        setSelectedResponse(e.value)
        if (idCollab && project && project.id && e.value && e.value.response_number != null) {
            dispatch(getCollabMcqResults({ id_project: project.id, id_collab: idCollab, response_number: e.value.response_number }))
        }
    }
    const handleClose = () => {
        dispatch(setCollabMcqResultsModalVisibility(false))
        dispatch(setCollabIdMcqResults(null))
        dispatch(setCollabMcqResults([]))
        dispatch(setLastResponseNumber(null))
        dispatch(setResponseDatesHistory([]))
        setSelectedResponse(null)
    }

    useEffect(() => {
        if (results.length > 0) {
            let score = 0
            results.forEach(apt => {
                score = score + apt.score;
            })
            score = score / results.length
            setTotalScore(parseFloat(score).toFixed(2))
        } else {
            setTotalScore(0)
        }
    }, [results])

    const dateTemplate = (option) => {
        if (option && option.created_at) {
            return (
                moment(option.created_at).format('HH[h]mm | DD/MM/YYYY')
            )
        }
    }

    const header = () => {
        return (
            <Row className="d-flex justify-content-center">
                <Col lg={12} xs={12} className="py-2 d-flex justify-content-center">
                    Total Score: {totalScore}
                </Col>
                <Col lg={12} xs={12} className="py-2 d-flex justify-content-center">
                    <Dropdown
                        value={selectedResponse}
                        onChange={(e) => handleResponseNumberChange(e)}
                        options={responseHistory}
                        optionLabel="created_at"
                        placeholder="Select a City"
                        className="w-full md:w-14rem"
                        checkmark={true}
                        itemTemplate={dateTemplate}
                        valueTemplate={dateTemplate}
                    />
                </Col>
            </Row>
        )
    };

    const ResultsDiv = () => {
        return (
            <div className="results-container">
                {results.map((aptitude) => (
                    <div key={aptitude.id} className="aptitude-section">
                        <h2>{aptitude.nom}</h2>
                        <p>{aptitude.description}</p>
                        {aptitude.score !== null && <h3>Score: {parseFloat(aptitude.score).toFixed(2)}%</h3>}
                        <div className="questions-container">
                            {aptitude.questions.map((question) => (
                                <div key={question.id} className="question-block">
                                    <Row>
                                        <Col lg={10}>
                                            <h4>{question.question}</h4>
                                        </Col>
                                        <Col lg={2} className="d-flex justify-content-end">
                                            {question.response_date && moment(question.response_date).format('HH[h]mm | DD/MM/YYYY')}
                                        </Col>

                                    </Row>
                                    {question.score !== null && <div className="question-score">Question Score: {question.score}</div>}
                                    <ul>
                                        {question.options.map((option) => {
                                            const isCorrect = question.correct_answers.includes(option.id);
                                            const isCollabResponse = question.collab_response && question.collab_response.includes(option.id);
                                            return (
                                                <li key={option.id}
                                                    className={`option-item ${isCorrect && 'correct-answer'} ${isCollabResponse && isCorrect && 'collab-correct-response'} ${isCollabResponse && !isCorrect && 'collab-wrong'}`}>
                                                    {option.value}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        );
    };


    const body = () => {
        return (
            <Dialog
                header={header}
                headerClassName="d-flex justify-content-center"
                visible={visible}
                onHide={() => { if (!visible) return; handleClose(); }}
                style={{ width: '100vw', height: '100vh', maxHeight: "100%", backgroundColor: "grey" }}
                draggable={false}
                resizable={false}
                footer={footer}
                className="collabMcqModal">
                {ResultsDiv()}
            </Dialog>
        )
    }
    const footer = () => {
        return (
            <Row className="d-flex justify-content-center">
                <Col lg={2}>
                    <DynamicButton
                        color='red'
                        onClick={() => { handleClose() }}
                        title={<Langs str='CLOSE' />}
                        active={true}
                    />
                </Col>
            </Row>
        )
    }
    return (
        <div>
            {body()}
        </div>
    )
}