import React from 'react';
import styles from './Inputs.module.css';
import { CirclePicker } from 'react-color';

export function CustomColorPicker(props) {
    const defaultColors = [
        "#000000",
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#002880",
        "#2196f3",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#ffc107",
        "#ff5722",
        "#795548",
        "#607d8b",
      ]
    const colorPickerStyles = {
        default: {
            picker: {
                width: "100% !important"
            },
        },
    }

    const handleChangeComplete = (color, event) => {
        if(!props.disabled){
            if (props.onChange) props.onChange(color.hex)
        }
    }

    return <CirclePicker
        styles={colorPickerStyles}
        colors={defaultColors}
        color={props.color}
        onChangeComplete={handleChangeComplete} />
}