import React, { useState, useRef, useEffect } from 'react';
import arrow from "../../assets/images/down-arrow.png";
import rightArrow from "../../assets/images/right-arrow.png";
import leftArrow from "../../assets/images/left-arrow.png";
import styles from './Buttons.module.css';
import Langs from "../../app/lang/langs";
import { formatDate, getWeek, getWeekYear } from "../../app/utils/helpers"
import { Col, Row, OverlayTrigger, Popover } from "react-bootstrap";
import moment from 'moment';
import { useSelector } from 'react-redux';

export function TransparentDatePicker(props) {

    const popoverRef = useRef()
    const buttonRef = useRef()

    const [selectedView, setSelectedView] = useState("")
    const [showPopover, setShowPopover] = useState(false)
    const lang = useSelector((state) => state.settings.lang)

    const [currentDate, setCurrentDate] = useState(props.value)

    const handleShowPopover = () => {
        switch (props.viewType) {
            case "MONTHLY":
                setSelectedView("MONTHS")
                break
            default:
                setSelectedView("DAYS")
                break
        }
        setShowPopover(!showPopover)
        setCurrentDate(props.value)
    }

    function getFormattedDate() {
        if (props.viewType === "MONTHLY") {
            return formatDate(new Date(props.value), "MMM YYYY")
        } else if (props.viewType === "WEEKLY")
            return <Langs
                str={"WEEK_YEAR"}
                params={{
                    "WEEK": getWeek(props.value),
                    "YEAR": getWeekYear(props.value)
                }} />
        if (props.range) {
            return formatDate(new Date(props.range), "DD MMM YYYY")
        }
        return formatDate(new Date(props.value), "DD MMM YYYY")
    }

    function getFormattedMonth(date) {
        if (date)
            return formatDate(date, "MMM")
        else return formatDate(new Date(currentDate), "MMM YYYY")
    }

    function getFormattedYear() {
        return formatDate(new Date(currentDate), "YYYY")
    }

    function getYearMonths() {
        let months = []
        if (currentDate) {
            let selectedYear = moment(props.value, "x").year()
            let date = moment(currentDate, "x").year(selectedYear).startOf("month").startOf("year")
            while (selectedYear === date.year()) {
                months.push(date.format("MMM"))
                date.add(1, "month")
            }
        }
        return months
    }

    function getYears() {
        let years = []
        if (currentDate) {
            for (let i = 0; i < 12; i++) {
                let date = moment(currentDate, "x")
                date.add(i, "year")
                years.push(date.year())
            }
        }
        return years
    }

    function getDays() {
        let days = []
        var now = moment(currentDate, "x");
        var startDate = now.startOf("month")
        let startMonth = startDate.month()
        let startYear = startDate.year()
        let dayOfWeek = startDate.weekday()
        if (dayOfWeek !== 0) {
            while (dayOfWeek > 0) {
                let date = moment(startDate)
                date.date(date.date() - dayOfWeek)
                let time = date.format('x')
                days.push({ time: parseInt(time), active: false, selected: date.date() === now.date() })
                dayOfWeek--
            }
        }

        while (startDate.year() <= startYear && startDate.month() <= startMonth) {
            let time = startDate.format('x')
            let isActive = true
            if (props.minDate) isActive = time > (new Date(props.minDate)).getTime()
            if (props.maxDate && isActive) isActive = time < (new Date(props.maxDate)).getTime()
            days.push({
                time: parseInt(time),
                active: isActive,
                selected: time === now.format('x')
            })
            startDate.date(startDate.date() + 1)
        }
        if (days.length % 7 != 0) {
            for (let i = 0; i < days.length % 7; i++) {
                let time = startDate.format('x')
                days.push({ time: parseInt(time), active: false, selected: time === now.format('x') })
                startDate.date(startDate.date() + 1)
            }
        }
        return days
    }

    const handleIncrement = () => {
        let date = new Date(currentDate)
        if (selectedView === "YEARS") date.setFullYear(date.getFullYear() + 12)
        else if (selectedView === "DAYS") date.setMonth(date.getMonth() + 1)
        else date.setFullYear(date.getFullYear() + 1)
        setCurrentDate(date.getTime())
    }

    const handleDecrement = () => {
        let date = new Date(currentDate)
        if (selectedView === "YEARS") date.setFullYear(date.getFullYear() - 12)
        else if (selectedView === "DAYS") date.setMonth(date.getMonth() - 1)
        else date.setFullYear(date.getFullYear() - 1)
        setCurrentDate(date.getTime())
    }

    const toggleYearButton = () => {
        if (selectedView === "YEARS" || selectedView === "DAYS") setSelectedView("MONTHS")
        else setSelectedView("YEARS")
    }

    const handleMonthClick = (event) => {
        let month = event.target.value
        let date = new Date(currentDate)
        date.setDate(1)
        date.setMonth(month)
        switch (props.viewType) {
            case "MONTHLY":
                props.onChange(date.getTime())
                setShowPopover(false)
                break
            default:
                setCurrentDate(date.getTime())
                setSelectedView("DAYS")
                break
        }
    }

    const handleYearClick = (event) => {
        let year = event.target.value
        let date = new Date(currentDate)
        date.setFullYear(year)
        setCurrentDate(date.getTime())
        setSelectedView("MONTHS")
    }

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date;
    }

    const handleDayClick = (event) => {

        if (event.target.value) {
            if (props.viewType === "WEEKLY") {
                let date = moment(parseInt(event.target.value), 'x')
                date.date(date.date() - date.weekday())
                props.onChange((parseInt(date.format('x'))))
            } else if (props.viewType === "MONTHLY") {
                props.onChange((parseInt(event.target.value)))
            } else {
                let date = new Date(parseInt(event.target.value))
                let newDate = new Date(props.value)
                newDate.setFullYear(date.getFullYear())
                newDate.setMonth(date.getMonth())
                newDate.setDate(date.getDate())
                props.onChange(newDate.getTime())
            }
            setShowPopover(false)
        }
    }

    function checkIfCurrentDate(dateTime) {
        let currentDateTime = props.value
        if (props.viewType === "WEEKLY") {
            return getWeek(currentDateTime) == getWeek(dateTime)
        } else if (props.range) {
            let fromDate = formatDate(new Date(props.value), "YYYYMMDD")
            let toDate = formatDate(new Date(props.range), "YYYYMMDD")
            let date = formatDate(new Date(dateTime), "YYYYMMDD")
            return date >= fromDate && date <= toDate
        } else {
            let date = new Date(currentDateTime)
            return formatDate(date, "DD MMM YYYY") == formatDate(new Date(dateTime), "DD MMM YYYY")
        }
    }

    function checkIfCurrentMonth(month) {
        let currentDateTime = props.value
        let cDate = new Date(currentDate)
        let date = new Date(currentDateTime)
        return formatDate(date, "MMM") == month && cDate.getFullYear() === date.getFullYear()
    }

    function checkIfCurrentYear(year) {
        let currentDateTime = props.value
        return (new Date(currentDateTime)).getFullYear() == parseInt(year)
    }

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (popoverRef.current && !popoverRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setShowPopover(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [popoverRef])

    useEffect(() => {
        if (props.forceShow) {
            handleShowPopover()
        }
    }, [props.forceShow])

    function getWeekDaysInitials() {
        if (lang === "en") return ['S', 'M', 'T', 'W', 'T', 'F', 'S']
        return ['L', 'M', 'M', 'J', 'V', 'S', 'D']
    }

    const daysView = () => {
        return <Row className='m-0 p-0'>
            <Row className='m-0 p-0 pt-2 pb-1'>
                {getWeekDaysInitials().map((d, i) => {
                    return <Col xs={1} style={{ width: "14.25%" }} key={"day_" + d + '_' + i} className="p-0 m-0">
                        <label className={styles.dayLabel}>
                            {d}
                        </label>
                    </Col>
                })}
            </Row>
            {props.viewType == 'WEEKLY' ?
                getDays().map((_, index) => {
                    if (index % 7 === 0) {
                        return <Row className={styles.weekRow}>
                            {getDays().map((day, yndex) => {
                                if (yndex >= index && yndex < index + 7) {
                                    return <Col xs={1} style={{ width: "14.25%" }} key={day.time} className="p-0 m-0">
                                        <button
                                            className={
                                                (day.active ? styles.activeDayButton :
                                                    styles.deactiveDayButton)
                                            }
                                            onClick={handleDayClick}
                                            value={day.time}
                                            style={{ backgroundColor: checkIfCurrentDate(day.time) ? "rgba(255, 144, 21, 0.15)" : "transparent" }}>
                                            {(new Date(day.time)).getDate()}
                                        </button>
                                    </Col>
                                }
                            })}
                        </Row>
                    }
                })
                :
                getDays().map((day, index) => {
                    return <Col xs={1} style={{ width: "14.25%" }} key={day.time} className="p-0 m-0">
                        <button

                            className={
                                (day.active ? styles.activeDayButton :
                                    styles.deactiveDayButton)
                            }
                            onClick={day.active ? handleDayClick : () => { }}
                            value={day.time}
                            style={{ backgroundColor: checkIfCurrentDate(day.time) ? "rgba(255, 144, 21, 0.15)" : "transparent" }}>
                            {(new Date(day.time)).getDate()}
                        </button>
                    </Col>
                })
            }
        </Row>
    }
    const monthsView = () => {
        return <Row>
            {getYearMonths().map((month, index) => {
                return <Col xs={4} key={month}>
                    <button
                        className={styles.monthButton}
                        onClick={handleMonthClick}
                        value={index}
                        style={{ backgroundColor: checkIfCurrentMonth(month) ? "rgba(255, 144, 21, 0.15)" : "transparent" }}>
                        {month}
                    </button>
                </Col>
            })}
        </Row>
    }
    const yearsView = () => {
        return <Row>
            {getYears().map(year => {
                return <Col xs={4} key={year}>
                    <button
                        className={styles.monthButton}
                        onClick={handleYearClick}
                        value={year}
                        style={{ backgroundColor: checkIfCurrentYear(year) ? "rgba(255, 144, 21, 0.15)" : "transparent" }}>
                        {year}
                    </button>
                </Col>
            })}
        </Row>
    }
    return <OverlayTrigger
        show={!props.disabled && showPopover}
        key={'bottom'}
        placement={'bottom'}
        overlay={
            <Popover id='popover-positioned-bottom' style={{zIndex: 10000}}>
                <Popover.Body ref={popoverRef} className='text-center'>
                    <Row>
                        <Col xs={3}>
                            <button className={styles.arrowButton} onClick={handleDecrement}>
                                <img src={rightArrow} width={22} height={22} />
                            </button></Col>
                        <Col xs={6}>
                            <button className={styles.yearButton} onClick={toggleYearButton}>
                                {selectedView === "MONTHS" ? getFormattedYear() : getFormattedMonth()}
                            </button></Col>
                        <Col xs={3}>
                            <button className={styles.arrowButton} onClick={handleIncrement}>
                                <img src={leftArrow} width={22} height={22} />
                            </button></Col>
                    </Row>
                    {selectedView === "DAYS" && daysView()}
                    {selectedView === "MONTHS" && monthsView()}
                    {selectedView === "YEARS" && yearsView()}
                </Popover.Body>
            </Popover>
        }>
        {props.disabled ?
            <label className={styles.dateLabel}>
                {getFormattedDate()}
            </label>
            :
            <button
                className={styles.transparentDatePicker}
                ref={buttonRef}
                onClick={handleShowPopover}>
                {getFormattedDate()} <img src={arrow} width={15} height={15} />
            </button>}
    </OverlayTrigger>
}