import React from 'react';
import {Button} from 'react-bootstrap';
import styles from './Buttons.module.css';
import Langs from "../../app/lang/langs";

export function DangerButton(props) {

    return <Button className={styles.dangerButton + (props.active? "":" opacity-50 disabled not-allowed")}
                   onClick={props.onClick}>
        <Langs str={props.title}/>
    </Button>;
}