import React, {useState} from 'react';
import styles from './Buttons.module.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Langs from '../../app/lang/langs';

export default function ImageButton(props) {

    const [focused, setFocused] = useState(false)
    const tooltip = props.tooltip
    
    const renderTooltip = (props) => tooltip ? (
        <div className={styles.tooltip} id={props.name} {...props}>
            <Langs str={tooltip}/>
        </div>
    ) : (<div/>)

    return <OverlayTrigger
        placement="bottom"
        delay={{ show: 500, hide: 100 }}
        overlay={renderTooltip}>
        <button className={styles.imageButton + (props.active? "":" opacity-50 disabled not-allowed")}
                style={props.style}
                onMouseEnter={props.active ? () => setFocused(true) : () => {}}
                onMouseLeave={props.active ? () => setFocused(false) : () => {}}
                onClick={props.active ? props.onClick : () => {}}>
            <img src={focused && props.active? props.imageFocus : props.image}
                 className={props.imageClassName}
                 style={props.imageStyle}
                 alt={props.name}
                 width={props.width} height={props.height}/>
        </button>
    </OverlayTrigger>
}