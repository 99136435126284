import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Langs from "../../app/lang/langs";
import { ThemeButton } from "../../components/buttons/ThemeButton";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { prefillPlanning, getPlanning } from "./planningSlice";
import { checkPermission, isAdmin, showErrorToast } from "../../app/utils/helpers";
import { submitShifts } from "./planningSlice";
import { setPublishedStatus } from "./planningSlice";
import { setIsLoading } from "../common/commonSlice";
import { RadioButton } from "../../components/buttons/RadioButton";
import { config } from "../../app/utils/config";

export function PublishModal(props) {

  const dispatch = useDispatch()
  const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
  const selectedDate = useSelector((state) => state.planning.selectedDate)
  const myTeam = useSelector((state) => state.teams.myTeam)
  const userRoleId = useSelector((state) => state.login.userRoleId)
  const collabs = useSelector((state) => state.collabs.list)

  const [notifyCollabs, setNotifyCollabs] = useState(true)

  const handlePublish = (event) => {
    hide()
    dispatch(setIsLoading(true))

    dispatch(submitShifts({
      teamIds: selectedTeamsIds.filter(id => isManager(id)),
      date: selectedDate,
      notify: notifyCollabs
    })).then((response) => {
      dispatch(setIsLoading(false))
      if (response['payload']['responseData']) {
        loadPlanning()
      }
      if (response['payload']['responseError']) {
        showErrorToast()
      }
    })
  }

  function loadPlanning(){
    if (selectedTeamsIds && selectedTeamsIds.length && collabs.length) {
      let selectedCollabsIds = collabs.reduce((array, current) =>{
        array.push(current.id)
        return array
      }, [])
      dispatch(getPlanning({selectedTeamsIds, selectedCollabsIds}))
    }
  }

  function isManager(teamId) {
    return checkPermission(config.scopes.shifts, config.permissionTypes.edit, teamId)
    return isAdmin(userRoleId) && (!myTeam || myTeam.userRoleId == 2 || teamId != myTeam.id)
  }

  function hide() {
    props.onHide()
  }

  function renderBodyView() {
    return (
      <Modal.Body>
        <Row className="d-flex justify-content-center align-items-center p-2">
          <Col>
            <label><Langs str='PUBLISH_CONFIRMATION' /></label>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center align-items-center p-2">
          <Col>
            <label><Langs str='NOTIFY_COLLABS' /></label>
          </Col>
          <Col md={3}>
            <RadioButton
              title={"YES"}
              selected={notifyCollabs}
              setSelected={() => setNotifyCollabs(true)} />
          </Col>
          <Col md={5}>
            <RadioButton
              title={"NO"}
              selected={!notifyCollabs}
              setSelected={() => setNotifyCollabs(false)} />
          </Col>
        </Row>
      </Modal.Body>
    )
  }

  function renderHeaderView() {
    return (
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <label><Langs str='PUBLISH' /></label>
        </Modal.Title>
      </Modal.Header>
    );
  }

  function renderFooterView() {
    return (
      <Modal.Footer className="d-flex justify-content-between">
        <Col md={3}>
          <PrimaryButton title="CANCEL" active={true} onClick={() => hide()} />
        </Col>
        <Col md={3}>
          <ThemeButton
            title="YES"
            active={true}
            onClick={() => handlePublish()}
          />
        </Col>
      </Modal.Footer>
    )
  }
  return (
    <Modal
      {...props}
      onHide={() => hide()}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      {renderHeaderView()}
      {renderBodyView()}
      {renderFooterView()}
    </Modal>
  )
}
