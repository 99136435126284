import React from 'react';
import { Vortex } from "react-loader-spinner";
import styles from './Loader.module.css';
import { Row, Col } from "react-bootstrap";

export default class Loader extends React.Component {
    render() {
        return <Row className={styles.background}>
            <Col className={styles.fullHeight + " d-flex justify-content-center align-items-center"}>
                <Vortex
                    visible={true}
                    height="120"
                    width="120"
                    ariaLabel="vortex-loading"
                    wrapperStyle={{}}
                    wrapperClass="vortex-wrapper"
                    colors={['red', 'orange', 'yellow', 'green', 'blue', 'purple']}
                />
            </Col>
        </Row>;
    }
}