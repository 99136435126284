import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    defaultPage: 'planning',
    isLoading: false,
    headerAction: null,
    sideViewType: null,
    popup: {
        show: false,
        title: null,
        message: ""
    },
    confirmationTo: false,
    groupeModalType: false,
    selectedGroupeData: false,
    userViews: [
        { label: 'Vue Collab', value: 'collab' },
        { label: 'Vue Manager', value: 'manager' }
    ],
    selectedUserView: 'collab'
};



export const commonSlice = createSlice({
    name: 'common',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setMenuButtons: (state, action) => {
            state.menuButtons = action.payload
        },
        setHeaderAction: (state, action) => {
            state.headerAction = action.payload
        },
        showSideView: (state, action) => {
            return {
                ...state,
                sideViewType: action.payload
            }
        },
        hideSideView: (state, action) => {
            return {
                ...state,
                sideViewType: null
            }
        },
        showPopup: (state, action) => {
            return {
                ...state, popup: {
                    show: action.payload.show,
                    title: action.payload.title,
                    message: action.payload.message
                }
            }
        },
        hidePopup: (state, action) => {
            return {
                ...state, popup: {
                    show: false,
                    title: null,
                    message: null,
                }
            }
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        togglePublishButton: (state, action) => {
            state.menuButtons[0].active = action.payload
        },
        toggleDeleteButton: (state, action) => {
            state.menuButtons[2].active = action.payload
        },
        onPopupConfirm: (state, action) => {
            state.confirmationTo = action.payload
        },
        onPopupHandle: (state, action) => {
            return {
                ...state,
                confirmationTo: null,
                popup: {
                    show: false,
                    title: null,
                    message: null,
                }
            }
        },
        showGroupeModal: (state, action) => {
            state.selectedGroupeData = action.payload.data
            state.groupeModalType = action.payload.type
        },
        hideGroupeModal: (state, action) => {
            state.groupeModalType = false
            state.selectedGroupeData = false
        },
        setSelectedUserView: (state, action) => {
            state.selectedUserView = action.payload
        }
    }
});

export const {
    showPopup,
    hidePopup,
    showSideView,
    hideSideView,
    setIsLoading,
    setDefaultPage,
    togglePublishButton,
    setMenuButtons,
    onPopupConfirm,
    onPopupHandle,
    toggleDeleteButton,
    setHeaderAction,
    showGroupeModal,
    hideGroupeModal,
    setSelectedUserView
} = commonSlice.actions;

export default commonSlice.reducer
