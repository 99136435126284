import React, { useEffect, useRef, useState, } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Langs from '../../app/lang/langs';
import styles from './Ventr.module.css';
import './Ventr.css';
import { useSelector, useDispatch } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import ImageButton from '../../components/buttons/ImageButton';
import deleteIcon from "../../assets/images/delete.png";
import deleteIconFocus from "../../assets/images/delete-orange.png";
import editIcon from "../../assets/images/edit.png";
import editIconFocus from "../../assets/images/editFocus.png";
import { Checkbox } from 'primereact/checkbox';
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { getUserDetails, getClients, saveUserDetails, getInterviewsData } from './VentrSlice';
import { setSelectedView } from './VentrSlice';
import { VEntrModal } from './vEntrModal';
import { ViewSelectButton } from "../../components/buttons/ViewSelectButton";
import ManageEntretienView from './ManageEntretienView';

const VentrView = () => {
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.ventr.interviewsData?.user_data);

    const clients = useSelector((state) => state.ventr.clients?.clients || []);
    const isMobile = useSelector((state) => state.settings.isMobile);

    const stepperRef = useRef(null);
    const bottomRef = useRef(null);

    const [displayEducationModal, setDisplayEducationModal] = useState(false);
    const [displayExperienceModal, setDisplayExperienceModal] = useState(false);
    const [displayMissionModal, setDisplayMissionModal] = useState(false);
    const [displayTrainingModal, setDisplayTrainingModal] = useState(false);
    const [displayTrainingAskModal, setDisplayTrainingAskModal] = useState(false);

    const [educations, setEducations] = useState([]);
    const [newEducation, setNewEducation] = useState({ year: '', diploma: '', level: '' });
    const [educationErrors, setEducationErrors] = useState({});

    const [experiences, setExperiences] = useState([]);
    const [newExperience, setNewExperience] = useState({ startDate: '', endDate: '', position: '', company: '' });
    const [experienceErrors, setExperienceErrors] = useState({});

    const [missions, setMissions] = useState([]);
    const [newMission, setNewMission] = useState({ startDate: '', endDate: '', position: '', client: '' });
    const [missionErrors, setMissionErrors] = useState({});

    const [trainings, setTrainings] = useState([]);
    const [oldTraining, setOldTraining] = useState({ date: '', title: '', certification: '', evaluation: '', origin: '', financing: '' });
    const [trainingErrors, setTrainingErrors] = useState({});

    const [editEducationIndex, setEditEducationIndex] = useState(null);
    const [editExperienceIndex, setEditExperienceIndex] = useState(null);
    const [editMissionIndex, setEditMissionIndex] = useState(null);
    const [editTrainingIndex, setEditTrainingIndex] = useState(null);

    const [projects, setProjects] = useState({
        reorient: 'Non',
        responsibility: 'Non',
        changeFunction: 'Non',
        desiredPeriod: '',
        description: ''
    });

    const [trainingsAsk, setTrainingsAsk] = useState([]);
    const [newTrainingAsk, setNewTrainingAsk] = useState({ period: '', title: '', financing: '', comment: '' });
    const [trainingAskErrors, setTrainingAskErrors] = useState({});
    const [editTrainingAskIndex, setEditTrainingAskIndex] = useState(null);

    const [mobility, setMobility] = useState({
        willingToRelocate: 'Non',
        location: '',
        comment: ''
    });

    const [viewsOptions, setViewOptions] = useState([
        { label: <Langs str='Team Entretiens' />, value: 0 },
        { label: <Langs str='Mon Entretien' />, value: 1 }
    ])

    const selectedView = useSelector((state) => state.ventr.selectedView);

    useEffect(() => {
        if (mobility.willingToRelocate === 'Oui') {
            setIsNextEnabled(mobility.location && mobility.comment ? true : false);
        } else {
            if (mobility.location !== '' || mobility.comment !== '') {
                setMobility(prevMobility => ({
                    ...prevMobility,
                    location: '',
                    comment: ''
                }));
            }
            setIsNextEnabled(true);
        }
    }, [mobility]);

    const [bilan, setBilan] = useState({
        firstReviewDate: null,
        secondReviewDate: new Date(),
        vitalTraining: [],
        actionsTaken: false,
        actionDescription: ''
    });

    const [displayBilanTrainingModal, setDisplayBilanTrainingModal] = useState(false);
    const [editBilanTrainingIndex, setEditBilanTrainingIndex] = useState(null);
    const [oldBilanTraining, setOldBilanTraining] = useState({ date: '', title: '', certification: '', evaluation: '', origin: '', financing: '' });
    const [bilanTrainingErrors, setBilanTrainingErrors] = useState({});

    const [comments, setComments] = useState('');
    const [isNextEnabled, setIsNextEnabled] = useState(false);

    const diplomas = [
        "BREVET DES COLLEGES",
        "BAC GENERAL",
        "BAC PROFESSIONNEL",
        "DUT",
        "BTS",
        "LICENCE GENERALE",
        "LICENCE PROFESSIONNELLE",
        "MASTER",
        "AUTRE"
    ];

    const levels = [
        "BREVET",
        "BAC",
        "BAC +1",
        "BAC +2",
        "BAC +3",
        "BAC +4",
        "BAC +5",
        "BAC +6",
        "BAC +7",
        "BAC +8",
        "BAC +9",
        "BAC +10",
        "AUTRE"
    ];

    const positions = [
        "ADMINISTRATEUR SYSTEME",
        "ANALYSTE D'EXPLOITATION",
        "ASSISTANTE MANAGERIALE",
        "ASSISTANTE DE GESTION",
        "CHARGE CRM",
        "CHARGE DE COMMUNICATION",
        "CHARGE DE RECHERCHE",
        "CHARGE DE RECRUTEMENT",
        "CHEF DE PROJET AMOA - MOA",
        "CHEF DE PROJET INFRA",
        "CHEF DE PROJET MOE",
        "COORDINATEUR TECHNIQUE",
        "DBA PROD",
        "DEVELOPPEUR WEB - NET",
        "DIRECTEUR DE PROJET",
        "GESTIONNAIRE DE PARC",
        "HOTESSE D'ACCUEIL",
        "INCIDENT PROLEM MANAGER",
        "INGENIEUR BIG DATA",
        "INGENIEUR DE DEVELOPPEMENT",
        "INGENIEUR DE PRODUCTION",
        "INGENIEUR DEVOPS",
        "INGENIEUR POSTE DE TRAVAIL",
        "INGENIEUR RESEAUX SECURITE",
        "INGENIEUR STOCKAGE SAN - NAS",
        "INGENIEUR SYSTEME",
        "PILOTE D'EXPLOITATION",
        "RESPONSABLE DE RECRUTEMENT",
        "RESPONSABLE OPERATIONNEL PRODUCTION",
        "RESPONSABLE QUALITÉ",
        "SERVICE DELIVERY MANAGER",
        "SUPERVISEUR",
        "TECHNICIEN HD-PROXIMITE",
        "TECHNICIEN HELPDESK N1",
        "TECHNICIEN HOTLINE APPLICATIVE",
        "TECHNICIEN SUPPORT APPLICATIF N2",
        "TECHNICIEN SUPPORT N2",
        "ASSISTANTE RH",
        "DIRECTRICE ADM & FIN",
        "RESPONSABLE COMMERCIAL",
        "GESTIONNAIRE RH",
        "GEST COMPT PAIE ADMIN",
        "CHEF DE PROJET JUNIOR",
        "AUTRE"
    ];

    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const [userDetailsParsed, setUserDetailsParsed] = useState([]);

    useEffect(() => {
        if (!userDetails) {
            dispatch(getInterviewsData());
        }
        if (userDetails && typeof userDetails.pro_post === 'string') {
            setUserDetailsParsed(JSON.parse(userDetails.pro_post || '[]'));
        } else if (userDetails && Array.isArray(userDetails.pro_post)) {
            setUserDetailsParsed(userDetails.pro_post);
        }
        if (!clients || clients.length === 0) {
            dispatch(getClients());
        }
    }, [dispatch, userDetails, clients]);



    useEffect(() => {
        if (userDetails) {
            getDiplomas(userDetails.diplomas);
            getExperiences(userDetails.pro_pre);
            getMissions(userDetails.pro_post);
            getTrainingsAsk(userDetails.training_requested);
            getFormation2DernAn(userDetails.training_last_2_years);
            getProject(userDetails.desired_evolution);
            getMobility(userDetails.mobility);
            getBilan(userDetails.training_last_6_years);
            getComments(userDetails.comments_collab);
            setIsDataLoaded(true);
        }
    }, [userDetails]);

    const getDiplomas = (diplomas) => {
        if (diplomas) {
            if (typeof diplomas === 'string') {
                try {
                    diplomas = JSON.parse(diplomas);
                } catch (error) {
                    console.error("Error parsing diplomas JSON string:", error);
                }
            }
            if (!Array.isArray(diplomas)) {
                diplomas = [diplomas];
            }
            const initialEducations = diplomas.map(diplome => ({
                year: diplome.annee,
                diploma: diplome.diplome,
                level: diplome.niveau
            }));
            setEducations(initialEducations);
        } else {
            console.error("userDetails.profil.diplomes is not an array:", diplomas);
        }
    };

    const getExperiences = (experiences) => {
        if (experiences) {
            if (typeof experiences === 'string') {
                try {
                    experiences = JSON.parse(experiences);
                } catch (error) {
                    console.error("Error parsing experiences JSON string:", error);
                }
            }
            if (!Array.isArray(experiences)) {
                experiences = [experiences];
            }
            const initialExperiences = experiences.map(exp => ({
                startDate: exp.dateDebut,
                endDate: exp.dateFin,
                company: exp.entreprise,
                position: exp.poste
            }));
            setExperiences(initialExperiences);
        } else {
            console.error("userDetails.profil.proPre is not an array:", experiences);
        }
    };

    const getMissions = (missionsData) => {

        if (missionsData) {
            if (typeof missionsData === 'string') {
                try {
                    missionsData = JSON.parse(missionsData);
                } catch (error) {
                    console.error("Error parsing missionsData JSON string:", error);
                    return;
                }
            }
            if (!Array.isArray(missionsData)) {
                missionsData = [missionsData];
            }
            const initialMissions = missionsData.map(mission => {
                return {
                    startDate: mission.dateDebut ? formatDate(mission.dateDebut) : null,
                    endDate: mission.dateFin ? formatDate(mission.dateFin) : null,
                    position: mission.poste || mission.poste0 || '',
                    client: mission.client
                };
            });
            setMissions(initialMissions);
        } else {
            console.error("missionsData is not an array:", missionsData);
        }
    };

    const getTrainingsAsk = (trainingsData) => {
        if (trainingsData) {
            if (typeof trainingsData === 'string') {
                try {
                    trainingsData = JSON.parse(trainingsData);
                } catch (error) {
                    console.error("Error parsing trainings JSON string:", error);
                }
            }
            if (!Array.isArray(trainingsData)) {
                trainingsData = [trainingsData];
            }
            const initialTrainingsAsk = trainingsData.map(training => ({
                period: training.periode,
                title: training.formation,
                financing: training.financement,
                comment: training.commentaire
            }));
            setTrainingsAsk(initialTrainingsAsk);
        } else {
            console.error("userDetails.profil.FormSouhait is not an array:", trainingsData);
        }
    };

    const getFormation2DernAn = (formationsData) => {
        if (formationsData) {
            if (typeof formationsData === 'string') {
                try {
                    formationsData = JSON.parse(formationsData);
                } catch (error) {
                    console.error("Error parsing formations JSON string:", error);
                }
            }
            if (!Array.isArray(formationsData)) {
                formationsData = [formationsData];
            }
            const initialFormations = formationsData.map(formation => ({
                date: formation.date,
                title: formation.title,
                certification: formation.certification,
                evaluation: formation.evaluation,
                origin: formation.origin,
                financing: formation.financing
            }));
            setTrainings(initialFormations);
        } else {
            console.error("userDetails.formations.Formation2DernAn is not an array:", formationsData);
        }
    };

    const getProject = (projectData) => {
        if (projectData) {
            if (typeof projectData === 'string') {
                try {
                    projectData = JSON.parse(projectData);
                } catch (error) {
                    console.error("Error parsing project JSON string:", error);
                }
            }
            const initialProject = {
                reorient: projectData.souhaitReorientation ? true : false,
                responsibility: projectData.souhaitResponsabilite ? true : false,
                changeFunction: projectData.souhaitChangement ? true : false,
                desiredPeriod: projectData.periode,
                description: projectData.description
            };
            setProjects(initialProject);
        } else {
            console.error("userDetails.entretien.projet is not a valid JSON string:", projectData);
        }
    };

    const getMobility = (mobilityData) => {
        if (mobilityData) {  
            if (typeof mobilityData === 'string') {
                try {
                    mobilityData = JSON.parse(mobilityData);
                } catch (error) {
                    console.error("Error parsing mobility JSON string:", error);
                }
            }
            const initialMobility = {
                willingToRelocate: mobilityData.value == true ? 'Oui' : 'Non',
                location: mobilityData.lieu,
                comment: mobilityData.commentaire
            };
            setMobility(initialMobility);
        } else {
            console.error("userDetails.mobilite is not a valid JSON string:", mobilityData);
        }
    };

    const getBilan = (formation6DernAn, entretien) => {
        const parsedFormation6DernAn = parseJSON(formation6DernAn);
        const parsedEntretien = parseJSON(entretien);

        const bilanData = {
            firstReviewDate: parsedEntretien && parsedEntretien.entretien1 ? new Date(parsedEntretien.entretien1) : null,
            secondReviewDate: parsedEntretien && parsedEntretien.entretien2 ? new Date(parsedEntretien.entretien2) : null,
            vitalTraining: Array.isArray(parsedFormation6DernAn) ? parsedFormation6DernAn.map(training => ({
                date: training.date,
                title: training.title,
                certification: training.certification,
                evaluation: training.evaluation,
                origin: training.origin,
                financing: training.financing
            })) : [],
            actionsTaken: !!(parsedEntretien && parsedEntretien.BilanPlus),
            actionDescription: parsedEntretien ? parsedEntretien.BilanPlus : ''
        };
        setBilan(bilanData);
    };

    const getComments = (commentsData) => {
        if (commentsData) {
            setComments(commentsData);
        } else {
            console.error("userDetails.entretien.CommentaireCollab is not a valid JSON string:", commentsData);
        }
    };

    const parseJSON = (data) => {
        if (data) {
            if (typeof data === 'string') {
                try {
                    return JSON.parse(data);
                } catch (error) {
                    console.error("Error parsing JSON string:", error);
                }
            } else {
                return data;
            }
        }
        return null;
    };


    const handleAddEducation = () => {
        setDisplayEducationModal(true);
    };

    const handleEditEducation = (education, index) => {
        setNewEducation({
            year: new Date(education.year, 0),
            diploma: education.diploma,
            level: education.level,
        });
        setEditEducationIndex(index);
        setDisplayEducationModal(true);
    };

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [day, month, year].join('/');
    };

    const validateFields = (fields, data, setErrors) => {
        const newErrors = {};
        fields.forEach(field => {
            if (!data[field]) newErrors[field] = true;
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSaveEducation = () => {
        if (validateFields(['year', 'diploma', 'level'], newEducation, setEducationErrors)) {
            const formattedEducation = {
                ...newEducation,
                year: newEducation.year.getFullYear(),
            };

            if (editEducationIndex !== null) {
                const updatedEducations = [...educations];
                updatedEducations[editEducationIndex] = formattedEducation;
                setEducations(updatedEducations);
                setEditEducationIndex(null);
            } else {
                setEducations([...educations, formattedEducation]);
            }

            setNewEducation({ year: '', diploma: '', level: '' });
            setDisplayEducationModal(false);
        }
    };

    const handleEducationChange = (name, value) => {
        setNewEducation({ ...newEducation, [name]: value });
        setEducationErrors({ ...educationErrors, [name]: !value });
    };

    const parseDate = (dateString) => {
        if (!dateString) return null;
        const [day, month, year] = dateString.split('/');
        return new Date(year, month - 1, day);
    };


    const handleAddExperience = () => {
        setDisplayExperienceModal(true);
    };

    const handleEditExperience = (experience, index) => {
        setNewExperience({
            startDate: parseDate(experience.startDate),
            endDate: parseDate(experience.endDate),
            position: experience.position,
            company: experience.company,
        });
        setEditExperienceIndex(index);
        setDisplayExperienceModal(true);
    };

    const handleSaveExperience = () => {
        if (validateFields(['startDate', 'endDate', 'position', 'company'], newExperience, setExperienceErrors)) {
            const formattedExperience = {
                ...newExperience,
                startDate: formatDate(newExperience.startDate),
                endDate: formatDate(newExperience.endDate),
            };

            if (editExperienceIndex !== null) {
                const updatedExperience = [...experiences];
                updatedExperience[editExperienceIndex] = formattedExperience;
                setExperiences(updatedExperience);
                setEditExperienceIndex(null);
            } else {
                setExperiences([...experiences, formattedExperience]);
            }

            setNewExperience({ startDate: '', endDate: '', position: '', company: '' });
            setDisplayExperienceModal(false);
        }
    };

    const handleExperienceChange = (name, value) => {
        setNewExperience({ ...newExperience, [name]: value });
        setExperienceErrors({ ...experienceErrors, [name]: !value });
    };

    const handleAddMission = () => {
        setDisplayMissionModal(true);
    };

    const handleEditMission = (mission, index) => {
        setNewMission({
            startDate: new Date(mission.startDate),
            endDate: new Date(mission.endDate),
            position: mission.position,
            client: mission.client,
        });
        setEditMissionIndex(index);
        setDisplayMissionModal(true);
    };

    const handleSaveMission = () => {
        if (validateFields(['startDate', 'endDate', 'position', 'client'], newMission, setMissionErrors)) {
            const formattedMission = {
                ...newMission,
                startDate: formatDate(newMission.startDate),
                endDate: formatDate(newMission.endDate),
            };
            if (editMissionIndex !== null) {
                const updatedMissions = [...missions];
                updatedMissions[editMissionIndex] = formattedMission;
                setMissions(updatedMissions);
                setEditMissionIndex(null);
            } else {
                setMissions([...missions, formattedMission]);
            }
            setNewMission({ startDate: '', endDate: '', position: '', client: '' });
            setDisplayMissionModal(false);
        }
    };

    const handleMissionChange = (name, value) => {
        setNewMission({ ...newMission, [name]: value });
        setMissionErrors({ ...missionErrors, [name]: !value });
    };

    const handleAddTraining = () => {
        setDisplayTrainingModal(true);
    };

    const handleEditTraining = (training, index) => {
        setOldTraining({
            date: new Date(training.date),
            title: training.title,
            certification: training.certification,
            evaluation: training.evaluation,
            origin: training.origin,
            financing: training.financing,
        });
        setEditTrainingIndex(index);
        setDisplayTrainingModal(true);
    };

    const handleSaveTraining = () => {
        if (validateFields(['date', 'title', 'certification', 'evaluation', 'origin', 'financing'], oldTraining, setTrainingErrors)) {
            const formattedTraining = {
                ...oldTraining,
                date: formatDate(oldTraining.date),
            };
            if (editTrainingIndex !== null) {
                const updatedTrainings = [...trainings];
                updatedTrainings[editTrainingIndex] = formattedTraining;
                setTrainings(updatedTrainings);
                setEditTrainingIndex(null);
            } else {
                setTrainings([...trainings, formattedTraining]);
            }
            setOldTraining({ date: '', title: '', certification: '', evaluation: '', origin: '', financing: '' });
            setDisplayTrainingModal(false);
        }
    };

    const handleTrainingChange = (name, value) => {
        setOldTraining({ ...oldTraining, [name]: value });
        setTrainingErrors({ ...trainingErrors, [name]: !value });
    };

    const handleProjectChange = (name, value) => {
        setProjects({ ...projects, [name]: value });
    };

    const handleAddTrainingAsk = () => {
        setDisplayTrainingAskModal(true);
    };

    const handleTrainingChangeAsk = (name, value) => {
        setNewTrainingAsk({ ...newTrainingAsk, [name]: value });
        setTrainingAskErrors({ ...trainingAskErrors, [name]: !value });
    };

    const handleEditTrainingAsk = (trainingAsk, index) => {
        setNewTrainingAsk(trainingAsk);
        setEditTrainingAskIndex(index);
        setDisplayTrainingAskModal(true);
    };

    const handleSaveTrainingAsk = () => {
        if (validateFields(['period', 'title', 'financing', 'comment'], newTrainingAsk, setTrainingAskErrors)) {
            const formattedTrainingAsk = {
                ...newTrainingAsk,
            };
            if (editTrainingAskIndex !== null) {
                const updatedTrainingsAsk = [...trainingsAsk];
                updatedTrainingsAsk[editTrainingAskIndex] = formattedTrainingAsk;
                setTrainingsAsk(updatedTrainingsAsk);
                setEditTrainingAskIndex(null);
            } else {
                setTrainingsAsk([...trainingsAsk, formattedTrainingAsk]);
            }
            setNewTrainingAsk({ period: '', title: '', financing: '', comment: '' });
            setDisplayTrainingAskModal(false);
        }
    };

    const handleMobilityChange = (name, value) => {
        setMobility({ ...mobility, [name]: value });
    };

    const handleBilanChange = (name, value) => {
        setBilan({ ...bilan, [name]: value });
    };

    const handleBilanTrainingChange = (name, value) => {
        setOldBilanTraining({ ...oldBilanTraining, [name]: value });
        setBilanTrainingErrors({ ...bilanTrainingErrors, [name]: !value });
    };

    const handleEditBilanTraining = (training, index) => {
        setOldBilanTraining({
            date: new Date(training.date),
            title: training.title,
            certification: training.certification,
            evaluation: training.evaluation,
            origin: training.origin,
            financing: training.financing,
        });
        setEditBilanTrainingIndex(index);
        setDisplayBilanTrainingModal(true);
    };

    const handleSaveBilanTraining = () => {
        if (validateFields(['date', 'title', 'certification', 'evaluation', 'origin', 'financing'], oldBilanTraining, setBilanTrainingErrors)) {
            const formattedTraining = {
                ...oldBilanTraining,
                date: formatDate(oldBilanTraining.date)
            };
            if (editBilanTrainingIndex !== null) {
                const updatedTrainings = [...bilan.vitalTraining];
                updatedTrainings[editBilanTrainingIndex] = formattedTraining;
                setBilan(prevBilan => ({
                    ...prevBilan,
                    vitalTraining: updatedTrainings
                }));
                setEditBilanTrainingIndex(null);
            } else {
                setBilan(prevBilan => ({
                    ...prevBilan,
                    vitalTraining: [...prevBilan.vitalTraining, formattedTraining]
                }));
            }
            setOldBilanTraining({ date: '', title: '', certification: '', evaluation: '', origin: '', financing: '' });
            setDisplayBilanTrainingModal(false);
        }
    };

    const handleDeleteBilanTraining = (training) => {
        setBilan(prevBilan => ({
            ...prevBilan,
            vitalTraining: prevBilan.vitalTraining.filter(t => t !== training)
        }));
    };

    const saveEntretien = (isSave) => {
        const entretien = {
            diplomes: educations.map((education) => ({
                annee: education.year,
                diplome: education.diploma,
                niveau: education.level
            })),
            proPre: experiences.map((experience) => ({
                dateDebut: experience.startDate,
                dateFin: experience.endDate,
                entreprise: experience.company,
                poste: experience.position
            })),
            proPost: missions.map((mission) => ({
                client: mission.client,
                poste: mission.position,
                dateDebut: mission.startDate,
                dateFin: mission.endDate
            })),
            mobilite: {
                lieu: mobility.location,
                commentaire: mobility.comment,
                value: mobility.willingToRelocate === 'Oui'
            },
            formations2ans: trainings.map((training) => ({
                date: training.date,
                title: training.title,
                certification: training.certification,
                evaluation: training.evaluation,
                origin: training.origin,
                financing: training.financing
            })),
            formationsSouhait: trainingsAsk.map((trainingAsk) => ({
                periode: trainingAsk.period,
                formation: trainingAsk.title,
                financement: trainingAsk.financing,
                commentaire: trainingAsk.comment
            })),
            commentaire: comments,
            projet: {
                periode: projects.desiredPeriod,
                description: projects.description,
                souhaitResponsabilite: projects.responsibility,
                souhaitReorientation: projects.reorient,
                souhaitChangement: projects.changeFunction
            },
            formations6ans: bilan.vitalTraining.map((training) => ({
                date: training.date,
                title: training.title,
                certification: training.certification,
                evaluation: training.evaluation,
                origin: training.origin,
                financing: training.financing
            })),
            bilanPlus: bilan.actionDescription,
            entretien1: bilan.firstReviewDate ? formatDate(bilan.firstReviewDate) : '',
            entretien2: bilan.secondReviewDate ? formatDate(bilan.secondReviewDate) : '',
            save: isSave
        };

        dispatch(saveUserDetails(entretien));
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            });
        }, 1500);
    };

    const EducationFooter = (
        <div className={styles.buttonContainer}>
            <Button
                icon="pi pi-plus"
                className={styles.addButton}
                onClick={handleAddEducation}
            />
        </div>
    );

    const experienceFooter = (
        <div className={styles.buttonContainer}>
            <Button
                icon="pi pi-plus"
                className={styles.addButton}
                onClick={handleAddExperience}
            />
        </div>
    );

    const missionFooter = (
        <div className={styles.buttonContainer}>
            <Button
                icon="pi pi-plus"
                className={styles.addButton}
                onClick={handleAddMission}
            />
        </div>
    );

    const trainingFooter = (
        <div className={styles.buttonContainer}>
            <Button
                icon="pi pi-plus"
                className={styles.addButton}
                onClick={handleAddTraining}
            />
        </div>
    );

    const trainingFooterAsk = (
        <div className={styles.buttonContainer}>
            <Button
                icon="pi pi-plus"
                className={styles.addButton}
                onClick={handleAddTrainingAsk}
            />
        </div>
    );

    const bilanTrainingFooter = (
        <div className={styles.buttonContainer}>
            <Button
                icon="pi pi-plus"
                className={styles.addButton}
                onClick={() => setDisplayBilanTrainingModal(true)}
            />
        </div>
    );

    if (!userDetails && isDataLoaded) {
        return (
            <div className={styles.body}>
                <div className={styles.staticMessage}>
                    <Langs str="NO_INTERVIEW_FOR_EMPLOYEE" />
                </div>
            </div>
        );
    }

    const handleViewChange = (selectedView) => {
        if (selectedView != null) {
            dispatch(setSelectedView(selectedView))
        }
    }

    return (
        <div>
            <div className={styles.topMenu}>
                <div className={styles.headerContainer}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <div style={{ position: 'absolute', left: 0 }}>
                            <span className={styles.headerTitle}>
                                <Langs str="V-Entretien" />
                            </span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ViewSelectButton
                                options={viewsOptions}
                                selectedView={viewsOptions[selectedView]?.value}
                                onViewChange={handleViewChange}
                            />
                        </div>
                    </div>
                </div>
            </div>


            {isDataLoaded && userDetails.status !== "1-Envoyé au salarié" && selectedView == 1 ? (
                <div className={styles.staticMessage}>
                    <Langs str="INTERVIEW_SENDED" />
                </div>
            ) : (
                <div className={styles.body}>
                    {userDetails != "empty" && selectedView == 1 ? (
                        <Container className={styles.container}>
                            <Row>

                                <div>
                                    <Col>
                                        <div className={styles.section}>
                                            <div className={styles.sectionTitle}><Langs str="Entretien" /></div>
                                            <div className={styles.sectionContent}>
                                                <div>
                                                    <p><Langs str="OLDEST_VALID_INTERVIEW" /> : {userDetails.last_validated_interview_date ? formatDate(userDetails.last_validated_interview_date) : <Langs str="NOT_PROVIDED" />} </p>
                                                    <p><Langs str="INTERVIEW_TYPE" /> : {userDetails.type || <Langs str="NOT_PROVIDED" />}</p>
                                                    <p><Langs str="INTERVIEW_THEORETICAL_DATE" /> : {userDetails.theoretical_date ? formatDate(userDetails.entretien.DateEntretienTheorique) : <Langs str="NOT_PROVIDED" />}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.section}>
                                            <div className={styles.sectionTitle}><Langs str="PERSONAL_INFORMATION" /></div>
                                            <div className={styles.sectionContent}>
                                                <div>
                                                    <p>{userDetails.nom && userDetails.prenom || <Langs str="NOT_PROVIDED" />} </p>
                                                    <p>{userDetails.type_collab || <Langs str="NOT_PROVIDED" />} <Langs str="en" /> {userDetails.type_contrat || <Langs str="NOT_PROVIDED" />}</p>
                                                    <p><Langs str="Email" /> : {userDetails.email || <Langs str="NOT_PROVIDED" />}</p>
                                                    <p><Langs str="Matricule" /> : {userDetails.matricule || <Langs str="NOT_PROVIDED" />}</p>
                                                    <p><Langs str="ENTRY_DATE" /> : {userDetails.entree_date ? formatDate(userDetails.entree_date) : <Langs str="NOT_PROVIDED" />}</p>
                                                    {userDetails.sortie_date && (
                                                        <p><Langs str="EXIT_DATE" /> : {formatDate(userDetails.sortie_date)}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={styles.section}>
                                            <div className={styles.sectionTitle}><Langs str="CURRENT_MISSION" /></div>
                                            <div className={styles.sectionContent}>
                                                <div>
                                                    <p><Langs str="CLIENT" /> : {userDetailsParsed[0]?.client || <Langs str="NOT_PROVIDED" />}</p>
                                                    <p><Langs str="JOB_DESCRIPTION" /> : {userDetailsParsed[0]?.poste || <Langs str="NOT_PROVIDED" />}</p>
                                                    <p><Langs str="MISSION_START" /> : {userDetailsParsed[0]?.dateDebut ? formatDate(userDetailsParsed[0].dateDebut) : <Langs str="NOT_PROVIDED" />}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </div>

                            </Row>
                        </Container>
                    ) : (
                        <div className={styles.card}>
                            <ManageEntretienView />
                        </div>
                    )}
                    {userDetails && selectedView == 1 && (
                        <div className={styles.card}>
                            <Stepper ref={stepperRef} style={{ flexBasis: '50rem' }} orientation="vertical">
                                <StepperPanel header={<Langs str="YOUR_JOURNEY_BEFORE_VITAL" />}>
                                    <div className="flex flex-column">
                                        <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium" style={{ height: "auto" }}>
                                            <h4>a. Educations</h4>
                                            <div className={styles.form}>

                                                <DataTable value={educations} footer={EducationFooter} tableStyle={{ minWidth: '60rem' }}>
                                                    <Column field="year" header={<Langs str="YEAR" />} />
                                                    <Column field="diploma" header={<Langs str="DIPLOMA_TITLE" />} />
                                                    <Column field="level" header={<Langs str="EDUCATION_LEVEL" />} />
                                                    <Column
                                                        body={(rowData, options) => (
                                                            <div>
                                                                <ImageButton
                                                                    image={deleteIcon}
                                                                    imageFocus={deleteIconFocus}
                                                                    onClick={() => { setEducations(educations.filter(Education => Education !== rowData)); }}
                                                                    tooltip={<Langs str="Delete" />}
                                                                    active={true}
                                                                    width={30} />
                                                                <ImageButton
                                                                    image={editIcon}
                                                                    imageFocus={editIconFocus}
                                                                    onClick={() => handleEditEducation(rowData, options.rowIndex)}
                                                                    tooltip={<Langs str="Edit" />}
                                                                    active={true}
                                                                    width={30} />
                                                            </div>
                                                        )}
                                                        header="Actions"
                                                    ></Column>
                                                </DataTable>
                                            </div>
                                            <h4><Langs str="PROFESSIONAL_PATH" /></h4>
                                            <div className={styles.form}>
                                                <DataTable value={experiences} footer={experienceFooter} tableStyle={{ minWidth: '60rem' }}>
                                                    <Column field="startDate" header={<Langs str="START_DATE" />} />
                                                    <Column field="endDate" header={<Langs str="END_DATE" />} />
                                                    <Column field="position" header={<Langs str="POSTE" />} />
                                                    <Column field="company" header={<Langs str="COMPANY" />} />
                                                    <Column
                                                        body={(rowData, options) => (
                                                            <div>
                                                                <ImageButton
                                                                    image={deleteIcon}
                                                                    imageFocus={deleteIconFocus}
                                                                    onClick={() => { setExperiences(experiences.filter(exp => exp !== rowData)); }}
                                                                    tooltip={<Langs str="Delete" />}
                                                                    active={true}
                                                                    width={30} />
                                                                <ImageButton
                                                                    image={editIcon}
                                                                    imageFocus={editIconFocus}
                                                                    onClick={() => handleEditExperience(rowData, options.rowIndex)}
                                                                    tooltip={<Langs str="Edit" />}
                                                                    active={true}
                                                                    width={30} />
                                                            </div>
                                                        )}
                                                        header="Actions"
                                                    ></Column>
                                                </DataTable>

                                                <VEntrModal
                                                    show={displayEducationModal}
                                                    title="CONFIRM_EDUCATION"
                                                    onHide={() => {
                                                        setDisplayEducationModal(false);
                                                        setNewEducation({ year: '', diploma: '', level: '' });
                                                        setEducationErrors({});
                                                        setEditEducationIndex(null);
                                                    }}
                                                    message={
                                                        <div className={styles.modalContent}>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="year"><Langs str="YEAR" /></label>
                                                                <Calendar
                                                                    id="year"
                                                                    name="year"
                                                                    value={newEducation.year}
                                                                    onChange={(e) => handleEducationChange('year', e.value)}
                                                                    view="year"
                                                                    dateFormat="yy"
                                                                    className="custom-calendar"
                                                                    required
                                                                />
                                                                {educationErrors.year && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="diploma"><Langs str="DIPLOMA_TITLE" /></label>
                                                                <Dropdown
                                                                    id="diploma"
                                                                    name="diploma"
                                                                    value={newEducation.diploma}
                                                                    options={diplomas.map(diploma => ({ label: diploma, value: diploma }))}
                                                                    onChange={(e) => handleEducationChange('diploma', e.value)}
                                                                    placeholder={<Langs str="Select" />}
                                                                    className="custom-dropdown"
                                                                    panelClassName="custom-dropdown-panel"
                                                                    required
                                                                />
                                                                {educationErrors.diploma && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="level"><Langs str="EDUCATION_LEVEL" /></label>
                                                                <Dropdown
                                                                    id="level"
                                                                    name="level"
                                                                    value={newEducation.level}
                                                                    options={levels.map(level => ({ label: level, value: level }))}
                                                                    onChange={(e) => handleEducationChange('level', e.value)}
                                                                    placeholder={<Langs str="Select" />}
                                                                    className="custom-dropdown"
                                                                    panelClassName="custom-dropdown-panel"
                                                                    required
                                                                />
                                                                {educationErrors.level && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                        </div>
                                                    }
                                                    onConfirm={handleSaveEducation}
                                                />

                                                <VEntrModal
                                                    show={displayExperienceModal}
                                                    title="CONFIRM_EXPERIENCE"
                                                    onHide={() => {
                                                        setDisplayExperienceModal(false)
                                                        setNewExperience({ startDate: '', endDate: '', position: '', company: '' });
                                                        setExperienceErrors({});
                                                        setEditExperienceIndex(null);
                                                    }}
                                                    message={
                                                        <div className={styles.modalContent}>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="startDate"><Langs str="START_DATE" /></label>
                                                                <Calendar
                                                                    id="startDate"
                                                                    name="startDate"
                                                                    value={newExperience.startDate}
                                                                    onChange={(e) => handleExperienceChange('startDate', e.value)}
                                                                    dateFormat="yy-mm-dd"
                                                                    className="custom-calendar"
                                                                    required
                                                                />
                                                                {experienceErrors.startDate && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="endDate"><Langs str="END_DATE" /></label>
                                                                <Calendar
                                                                    id="endDate"
                                                                    name="endDate"
                                                                    value={newExperience.endDate}
                                                                    onChange={(e) => handleExperienceChange('endDate', e.value)}
                                                                    dateFormat="yy-mm-dd"
                                                                    className="custom-calendar"
                                                                    required
                                                                />
                                                                {experienceErrors.endDate && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="position"><Langs str="POSTE" /></label>
                                                                <Dropdown
                                                                    id="position"
                                                                    name="position"
                                                                    value={newExperience.position}
                                                                    options={positions.map(position => ({ label: position, value: position }))}
                                                                    onChange={(e) => handleExperienceChange('position', e.value)}
                                                                    placeholder={<Langs str="Select" />}
                                                                    className="custom-dropdown"
                                                                    panelClassName="custom-dropdown-panel"
                                                                    required
                                                                />
                                                                {experienceErrors.position && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="company"><Langs str="COMPANY" /></label>
                                                                <input
                                                                    id="company"
                                                                    name="company"
                                                                    value={newExperience.company}
                                                                    onChange={(e) => handleExperienceChange('company', e.target.value)}
                                                                    className="custom-input"
                                                                    maxLength="20"
                                                                    required
                                                                />
                                                                {experienceErrors.company && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                        </div>
                                                    }
                                                    onConfirm={handleSaveExperience}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex py-4" style={{ display: 'flex', justifyContent: 'flex-end', width: isMobile == 2 ? '75%' : '100%' }}>
                                            <div style={{ width: '10%' }}>
                                                <PrimaryButton
                                                    title="Next"
                                                    active={true}
                                                    onClick={() => {
                                                        stepperRef.current.nextCallback();
                                                        scrollToBottom();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </StepperPanel>
                                <StepperPanel header={<Langs str="YOUR_MISSIONS_AT_VITAL" />}>
                                    <div className="flex flex-column">
                                        <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium" style={{ height: "auto" }}>
                                            <h4><Langs str="YOUR_MISSIONS_AT_VITAL" /></h4>
                                            <div className={styles.form}>
                                                <DataTable value={missions} footer={missionFooter} tableStyle={{ minWidth: '60rem' }}>
                                                    <Column field="startDate" header={<Langs str="START_DATE" />} />
                                                    <Column field="endDate" header={<Langs str="END_DATE" />} />
                                                    <Column field="position" header={<Langs str="POSTE" />} />
                                                    <Column
                                                        field="client"
                                                        header={<Langs str="CLIENT" />}
                                                        body={(rowData) => {
                                                            const client = clients.find(client => client.id === rowData.client);
                                                            return client ? client.name : rowData.client;
                                                        }}
                                                    ></Column>
                                                    <Column
                                                        body={(rowData, options) => (
                                                            <div>
                                                                <ImageButton
                                                                    image={deleteIcon}
                                                                    imageFocus={deleteIconFocus}
                                                                    onClick={() => { setMissions(missions.filter(mission => mission !== rowData)); }}
                                                                    tooltip={<Langs str="Delete" />}
                                                                    active={true}
                                                                    width={30} />
                                                                <ImageButton
                                                                    image={editIcon}
                                                                    imageFocus={editIconFocus}
                                                                    onClick={() => handleEditMission(rowData, options.rowIndex)}
                                                                    tooltip={<Langs str="Edit" />}
                                                                    active={true}
                                                                    width={30} />
                                                            </div>
                                                        )}
                                                        header="Actions"
                                                    ></Column>
                                                </DataTable>
                                            </div>

                                            <h4><Langs str="TRAININGS_LAST_TWO_YEARS" /></h4>
                                            <div className={styles.form}>
                                                <DataTable value={trainings} footer={trainingFooter} tableStyle={{ minWidth: '60rem' }}>
                                                    <Column field="date" header={<Langs str="TRAINING_DATE" />} />
                                                    <Column field="title" header={<Langs str="TRAINING_TITLE" />} />
                                                    <Column field="certification" header={<Langs str="CERTIFICATION_OBTAINED" />} />
                                                    <Column field="evaluation" header={<Langs str="EVALUATION" />} />
                                                    <Column field="origin" header={<Langs str="ORIGIN" />} />
                                                    <Column field="financing" header={<Langs str="FINANCING" />} />
                                                    <Column
                                                        body={(rowData, options) => (
                                                            <div>
                                                                <ImageButton
                                                                    image={deleteIcon}
                                                                    imageFocus={deleteIconFocus}
                                                                    onClick={() => { setTrainings(trainings.filter(training => training !== rowData)); }}
                                                                    tooltip={<Langs str="Delete" />}
                                                                    active={true}
                                                                    width={30} />
                                                                <ImageButton
                                                                    image={editIcon}
                                                                    imageFocus={editIconFocus}
                                                                    onClick={() => handleEditTraining(rowData, options.rowIndex)}
                                                                    tooltip={<Langs str="Edit" />}
                                                                    active={true}
                                                                    width={30} />
                                                            </div>
                                                        )}
                                                        header="Actions"
                                                    ></Column>
                                                </DataTable>
                                                <VEntrModal
                                                    show={displayMissionModal}
                                                    title="CONFIRM_MISSION"
                                                    onHide={() => {
                                                        setDisplayMissionModal(false)
                                                        setNewMission({ startDate: '', endDate: '', position: '', client: '' });
                                                        setMissionErrors({});
                                                        setEditMissionIndex(null);
                                                    }}
                                                    message={
                                                        <div className={styles.modalContent}>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="startDate"><Langs str="START_DATE" /></label>
                                                                <Calendar
                                                                    id="startDate"
                                                                    name="startDate"
                                                                    value={newMission.startDate}
                                                                    onChange={(e) => handleMissionChange('startDate', e.value)}
                                                                    dateFormat="yy-mm-dd"
                                                                    className="custom-calendar"
                                                                    required
                                                                />
                                                                {missionErrors.startDate && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="endDate"><Langs str="END_DATE" /></label>
                                                                <Calendar
                                                                    id="endDate"
                                                                    name="endDate"
                                                                    value={newMission.endDate}
                                                                    onChange={(e) => handleMissionChange('endDate', e.value)}
                                                                    dateFormat="yy-mm-dd"
                                                                    className="custom-calendar"
                                                                    required
                                                                />
                                                                {missionErrors.endDate && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="position"><Langs str="POSTE" /></label>
                                                                <Dropdown
                                                                    id="position"
                                                                    name="position"
                                                                    value={newMission.position}
                                                                    options={positions.map(position => ({ label: position, value: position }))}
                                                                    onChange={(e) => handleMissionChange('position', e.value)}
                                                                    placeholder={<Langs str="Select" />}
                                                                    className="custom-dropdown"
                                                                    panelClassName="custom-dropdown-panel"
                                                                    required
                                                                />
                                                                {missionErrors.position && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="client"><Langs str="CLIENT" /></label>
                                                                <Dropdown
                                                                    id="client"
                                                                    name="client"
                                                                    value={newMission.client}
                                                                    options={clients?.map(client => ({ label: client.name, value: client.id })) || []}
                                                                    onChange={(e) => handleMissionChange('client', e.value)}
                                                                    placeholder={<Langs str="Select" />}
                                                                    className="custom-dropdown"
                                                                    panelClassName="custom-dropdown-panel"
                                                                    required
                                                                />
                                                                {missionErrors.client && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                        </div>
                                                    }
                                                    onConfirm={handleSaveMission}
                                                />

                                                <VEntrModal
                                                    show={displayTrainingModal}
                                                    title="CONFIRM_TRAINING"
                                                    onHide={() => {
                                                        setDisplayTrainingModal(false)
                                                        setOldTraining({ date: '', title: '', certification: '', evaluation: '', origin: '', financing: '' });
                                                        setTrainingErrors({});
                                                        setEditTrainingIndex(null);
                                                    }}
                                                    message={
                                                        <div className={styles.modalContent}>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="date"><Langs str="TRAINING_DATE" /></label>
                                                                <Calendar
                                                                    id="date"
                                                                    name="date"
                                                                    value={oldTraining.date}
                                                                    onChange={(e) => handleTrainingChange('date', e.value)}
                                                                    dateFormat="yy-mm-dd"
                                                                    className="custom-calendar"
                                                                    required
                                                                />
                                                                {trainingErrors.date && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="title"><Langs str="TRAINING_TITLE" /></label>
                                                                <input
                                                                    id="title"
                                                                    name="title"
                                                                    value={oldTraining.title}
                                                                    onChange={(e) => handleTrainingChange('title', e.target.value)}
                                                                    className="custom-input"
                                                                    maxLength="50"
                                                                    required
                                                                />
                                                                {trainingErrors.title && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="certification"><Langs str="CERTIFICATION_OBTAINED" /></label>
                                                                <Dropdown
                                                                    id="certification"
                                                                    name="certification"
                                                                    value={oldTraining.certification}
                                                                    options={[
                                                                        { label: <Langs str="YES" />, value: 'Oui' },
                                                                        { label: <Langs str="NO" />, value: 'Non' },
                                                                    ]}
                                                                    onChange={(e) => handleTrainingChange('certification', e.value)}
                                                                    placeholder={<Langs str="Select" />}
                                                                    className="custom-dropdown"
                                                                    panelClassName="custom-dropdown-panel"
                                                                    required
                                                                />
                                                                {trainingErrors.certification && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="evaluation"><Langs str="EVALUATION" /></label>
                                                                <input
                                                                    id="evaluation"
                                                                    name="evaluation"
                                                                    value={oldTraining.evaluation}
                                                                    onChange={(e) => handleTrainingChange('evaluation', e.target.value)}
                                                                    className="custom-input"
                                                                    maxLength="50"
                                                                    required
                                                                />
                                                                {trainingErrors.evaluation && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="origin"><Langs str="ORIGIN" /></label>
                                                                <Dropdown
                                                                    id="origin"
                                                                    name="origin"
                                                                    value={oldTraining.origin}
                                                                    options={[
                                                                        { label: <Langs str="EMPLOYEE_INITIATIVE" />, value: 'Employé' },
                                                                        { label: <Langs str="EMPLOYER_INITIATIVE" />, value: 'Salarié' },
                                                                    ]}
                                                                    onChange={(e) => handleTrainingChange('origin', e.value)}
                                                                    placeholder={<Langs str="Select" />}
                                                                    className="custom-dropdown"
                                                                    panelClassName="custom-dropdown-panel"
                                                                    required
                                                                />
                                                                {trainingErrors.origin && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="financing"><Langs str="FINANCING" /></label>
                                                                <Dropdown
                                                                    id="financing"
                                                                    name="financing"
                                                                    value={oldTraining.financing}
                                                                    options={[
                                                                        { label: <Langs str="CPF" />, value: 'CPF' },
                                                                        { label: <Langs str="EMPLOYER" />, value: 'Employeur' },
                                                                        { label: <Langs str="SELF_FINANCED" />, value: 'Autofinancé' },
                                                                    ]}
                                                                    onChange={(e) => handleTrainingChange('financing', e.value)}
                                                                    placeholder={<Langs str="Select" />}
                                                                    className="custom-dropdown"
                                                                    panelClassName="custom-dropdown-panel"
                                                                    required
                                                                />
                                                                {trainingErrors.financing && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                            </div>
                                                        </div>
                                                    }
                                                    onConfirm={handleSaveTraining}
                                                />

                                            </div>
                                        </div>
                                        <div className="flex py-4" style={{ display: 'flex', justifyContent: 'space-between', width: isMobile == 2 ? '75%' : '100%' }}>
                                            <div style={{ width: '10%' }}>
                                                <PrimaryButton
                                                    title="Back"
                                                    active={true}
                                                    onClick={() => {
                                                        stepperRef.current.prevCallback();
                                                        scrollToBottom();
                                                    }}
                                                    style={{ borderRadius: '10px', backgroundColor: '#FF9015', border: 'none', width: '100%' }}
                                                />
                                            </div>
                                            <div style={{ width: '10%' }}>
                                                <PrimaryButton
                                                    title="Next"
                                                    active={true}
                                                    onClick={() => {
                                                        stepperRef.current.nextCallback();
                                                        scrollToBottom();
                                                    }}
                                                    style={{ borderRadius: '10px', backgroundColor: '#FF9015', border: 'none', width: '100%' }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </StepperPanel>

                                <StepperPanel header={<Langs str="EVOLUTION_PROJECTS" />}>
                                    <div className="flex flex-column">
                                        <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium" style={{ height: "auto" }}>
                                            <h4>
                                                <Langs str="REORIENT_LABEL" />
                                            </h4>
                                            <div>
                                                <div>
                                                    <div className={styles.checkboxContainer}>
                                                        <label className={styles.formLabel}>
                                                            <Langs str="REORIENT_LABEL" />
                                                        </label>
                                                        <Checkbox
                                                            inputId="reorient"
                                                            name="reorient"
                                                            onChange={(e) => handleProjectChange('reorient', e.checked)}
                                                            checked={projects.reorient}
                                                        />
                                                        <label htmlFor="reorient" className={styles.checkboxLabel}>
                                                            <Langs str="YES" />
                                                        </label>
                                                    </div>
                                                    <div className={styles.checkboxContainer}>
                                                        <label className={styles.formLabel}>
                                                            <Langs str="RESPONSIBILITY_LABEL" />
                                                        </label>
                                                        <Checkbox
                                                            inputId="responsibility"
                                                            name="responsibility"
                                                            onChange={(e) => handleProjectChange('responsibility', e.checked)}
                                                            checked={projects.responsibility}
                                                        />
                                                        <label htmlFor="responsibility" className={styles.checkboxLabel}>
                                                            <Langs str="YES" />
                                                        </label>
                                                    </div>
                                                    <div className={styles.checkboxContainer}>
                                                        <label className={styles.formLabel}>
                                                            <Langs str="CHANGE_FUNCTION_LABEL" />
                                                        </label>
                                                        <Checkbox
                                                            inputId="changeFunction"
                                                            name="changeFunction"
                                                            onChange={(e) => handleProjectChange('changeFunction', e.checked)}
                                                            checked={projects.changeFunction}
                                                        />
                                                        <label htmlFor="changeFunction" className={styles.checkboxLabel}>
                                                            <Langs str="YES" />
                                                        </label>
                                                    </div>
                                                    <div className={styles.formGroup}>
                                                        <label htmlFor="desiredPeriod" className={styles.formLabel}>
                                                            <Langs str="DESIRED_PERIOD" />
                                                        </label>
                                                        <Dropdown
                                                            id="period"
                                                            name="period"
                                                            value={projects.desiredPeriod}
                                                            options={[
                                                                { label: <Langs str="SOON" />, value: 'Des que possible' },
                                                                { label: <Langs str="IN_6_MONTHS" />, value: 'Dans 6 mois' },
                                                                { label: <Langs str="THIS_YEAR" />, value: 'Cette année' },
                                                                { label: <Langs str="NEXT_YEAR" />, value: 'Année prochaine' },
                                                            ]}
                                                            onChange={(e) => handleProjectChange('desiredPeriod', e.value)}
                                                            placeholder={<Langs str="Select" />}
                                                        />
                                                    </div>
                                                    <div className={styles.formGroup}>
                                                        <label htmlFor="description" className={styles.formLabel}>
                                                            <Langs str="DESCRIPTION" />
                                                        </label>
                                                        <textarea
                                                            id="description"
                                                            name="description"
                                                            value={projects.description}
                                                            onChange={(e) => handleProjectChange('description', e.target.value)}
                                                            className={styles.formInput}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium" style={{ height: "auto" }}>
                                            <h4><Langs str="TRAINING_REQUEST_HEADER" /></h4>
                                            <div className={styles.form}>
                                                <DataTable value={trainingsAsk} footer={trainingFooterAsk} tableStyle={{ minWidth: '60rem' }}>
                                                    <Column field="period" header={<Langs str="DESIRED_PERIOD_HEADER" />} />
                                                    <Column field="title" header={<Langs str="TRAINING_TITLE_HEADER" />} />
                                                    <Column field="financing" header={<Langs str="FINANCING_HEADER" />} />
                                                    <Column field="comment" header={<Langs str="COMMENT_HEADER" />} />
                                                    <Column
                                                        body={(rowData, options) => (
                                                            <div>
                                                                <ImageButton
                                                                    image={deleteIcon}
                                                                    imageFocus={deleteIconFocus}
                                                                    onClick={() => { setTrainingsAsk(trainingsAsk.filter(trainingsAsk => trainingsAsk !== rowData)); }}
                                                                    tooltip={<Langs str="Delete" />}
                                                                    active={true}
                                                                    width={30} />
                                                                <ImageButton
                                                                    image={editIcon}
                                                                    imageFocus={editIconFocus}
                                                                    onClick={() => handleEditTrainingAsk(rowData, options.rowIndex)}
                                                                    tooltip={<Langs str="Edit" />}
                                                                    active={true}
                                                                    width={30} />
                                                            </div>
                                                        )}
                                                        header="Actions"
                                                    ></Column>
                                                </DataTable>
                                                <VEntrModal
                                                    show={displayTrainingAskModal}
                                                    title="CONFIRM_TRAINING_ASK"
                                                    onHide={() => {
                                                        setDisplayTrainingAskModal(false)
                                                        setNewTrainingAsk({ period: '', title: '', financing: '', comment: '' });
                                                        setTrainingAskErrors({});
                                                        setEditTrainingAskIndex(null);
                                                    }}
                                                    message={
                                                        <div className={styles.modalContent}>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="period"><Langs str="DESIRED_PERIOD_HEADER" /></label>
                                                                <Dropdown
                                                                    id="period"
                                                                    name="period"
                                                                    value={newTrainingAsk.period}
                                                                    options={[
                                                                        { label: <Langs str="SOON" />, value: 'Des que possible' },
                                                                        { label: <Langs str="IN_6_MONTHS" />, value: 'Dans 6 mois' },
                                                                        { label: <Langs str="THIS_YEAR" />, value: 'Cette année' },
                                                                        { label: <Langs str="NEXT_YEAR" />, value: 'Année prochaine' },
                                                                    ]}
                                                                    onChange={(e) => handleTrainingChangeAsk('period', e.target.value)}
                                                                    placeholder={<Langs str="Select" />}
                                                                    className="custom-dropdown"
                                                                    panelClassName="custom-dropdown-panel"
                                                                />
                                                                {trainingAskErrors.period && <span className={styles.errorText}><Langs str="REQUIRED_FIELD_ERROR" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="title"><Langs str="TRAINING_TITLE_HEADER" /></label>
                                                                <input
                                                                    id="title"
                                                                    name="title"
                                                                    value={newTrainingAsk.title}
                                                                    onChange={(e) => handleTrainingChangeAsk('title', e.target.value)}
                                                                    maxLength="40"
                                                                />
                                                                {trainingAskErrors.title && <span className={styles.errorText}><Langs str="REQUIRED_FIELD_ERROR" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="financing"><Langs str="FINANCING_HEADER" /></label>
                                                                <Dropdown
                                                                    id="financing"
                                                                    name="financing"
                                                                    value={newTrainingAsk.financing}
                                                                    options={[
                                                                        { label: <Langs str="CPF" />, value: 'CPF' },
                                                                        { label: <Langs str="EMPLOYER" />, value: 'Employeur' },
                                                                        { label: <Langs str="SELF_FINANCED" />, value: 'Autofinancé' },
                                                                    ]}
                                                                    onChange={(e) => handleTrainingChangeAsk('financing', e.value)}
                                                                    placeholder="Sélectionner une option"
                                                                    className="custom-dropdown"
                                                                    panelClassName="custom-dropdown-panel"
                                                                />
                                                                {trainingAskErrors.financing && <span className={styles.errorText}><Langs str="REQUIRED_FIELD_ERROR" /></span>}
                                                            </div>
                                                            <div className={styles.modalRow}>
                                                                <label htmlFor="comment"><Langs str="COMMENT_HEADER" /></label>
                                                                <input
                                                                    id="comment"
                                                                    name="comment"
                                                                    value={newTrainingAsk.comment}
                                                                    onChange={(e) => handleTrainingChangeAsk('comment', e.target.value)}
                                                                    maxLength="250"
                                                                />
                                                                {trainingAskErrors.comment && <span className={styles.errorText}><Langs str="REQUIRED_FIELD_ERROR" /></span>}
                                                            </div>
                                                        </div>
                                                    }
                                                    onConfirm={handleSaveTrainingAsk}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex py-4" style={{ display: 'flex', justifyContent: 'space-between', width: isMobile == 2 ? '75%' : '100%' }}>
                                            <div style={{ width: '10%' }}>
                                                <PrimaryButton
                                                    title="Back"
                                                    active={true}
                                                    onClick={() => {
                                                        stepperRef.current.prevCallback();
                                                        scrollToBottom();
                                                    }}
                                                    style={{ borderRadius: '10px', backgroundColor: '#FF9015', border: 'none', width: '100%' }}
                                                />
                                            </div>
                                            <div style={{ width: '10%' }}>
                                                <PrimaryButton
                                                    title="Next"
                                                    active={true}
                                                    onClick={() => {
                                                        stepperRef.current.nextCallback();
                                                        scrollToBottom();
                                                    }}
                                                    style={{ borderRadius: '10px', backgroundColor: '#FF9015', border: 'none', width: '100%' }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </StepperPanel>
                                <StepperPanel header={<Langs str="GEOGRAPHICAL_MOBILITY_HEADER" />}>
                                    <div>
                                        <p><Langs str="SERVICE_CENTERS_INFO" /></p>
                                        <div className={styles.checkboxContainer}>
                                            <label className={styles.formLabel}><Langs str="RELOCATE_LABEL" /></label>
                                            <Checkbox
                                                inputId="willingToRelocate"
                                                name="willingToRelocate"
                                                onChange={(e) => handleMobilityChange('willingToRelocate', e.checked ? <Langs str="YES" /> : <Langs str="NO" />)}
                                                checked={mobility.willingToRelocate == "Oui"}
                                            />
                                            <label htmlFor="willingToRelocate" className={styles.checkboxLabel}><Langs str="YES" /></label>
                                        </div>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="location" className={styles.formLabel}><Langs str="SPECIFY_LOCATION_LABEL" /></label>
                                            <input
                                                id="location"
                                                name="location"
                                                value={mobility.location}
                                                onChange={(e) => handleMobilityChange('location', e.target.value)}
                                                className={styles.formInput}
                                                style={{ width: '10%' }}
                                                maxLength="30"
                                            />
                                            {mobility.willingToRelocate === <Langs str="YES" /> && !mobility.location && (
                                                <span className={styles.errorText}><Langs str="REQUIRED_FIELD_ERROR" /></span>
                                            )}
                                        </div>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="comment" className={styles.formLabel}><Langs str="COMMENT_LABEL" /></label>
                                            <textarea
                                                id="comment"
                                                name="comment"
                                                value={mobility.comment}
                                                onChange={(e) => handleMobilityChange('comment', e.target.value)}
                                                className={styles.formInput}
                                                maxLength="250"
                                            />
                                            {mobility.willingToRelocate === <Langs str="YES" /> && !mobility.comment && (
                                                <span className={styles.errorText}><Langs str="REQUIRED_FIELD_ERROR" /></span>
                                            )}
                                        </div>
                                        <div className="flex py-4" style={{ display: 'flex', justifyContent: 'space-between', width: isMobile == 2 ? '75%' : '100%' }}>
                                            <div style={{ width: '10%' }}>
                                                <PrimaryButton
                                                    title={<Langs str="Back" />}
                                                    active={true}
                                                    onClick={() => {
                                                        stepperRef.current.prevCallback();
                                                        scrollToBottom();
                                                    }}
                                                    style={{ borderRadius: '10px', backgroundColor: '#FF9015', border: 'none', width: '100%' }}
                                                />
                                            </div>
                                            <div style={{ width: '10%' }}>
                                                <PrimaryButton
                                                    title={<Langs str="Next" />}
                                                    active={isNextEnabled}
                                                    onClick={() => {
                                                        stepperRef.current.nextCallback();
                                                        scrollToBottom();
                                                    }}
                                                    style={{ borderRadius: '10px', backgroundColor: '#FF9015', border: 'none', width: '100%' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </StepperPanel>
                                {userDetails.entretien && userDetails.entretien.TypeEntretienARealiser === "6 ans" && (
                                    <StepperPanel header={<Langs str="SIX_YEAR_REVIEW_HEADER" />}>
                                        <div>
                                            <h5><Langs str="LAST_TWO_REVIEWS_TITLE" /></h5>
                                            <div className={styles.formGroup}>
                                                <label htmlFor="firstReviewDate" className={styles.formLabel}><Langs str="FIRST_REVIEW_LABEL" /> :</label>
                                                <Calendar
                                                    id="firstReviewDate"
                                                    name="firstReviewDate"
                                                    value={bilan.firstReviewDate}
                                                    onChange={(e) => handleBilanChange('firstReviewDate', e.value)}
                                                    dateFormat="dd/mm/yy"
                                                    placeholder={<Langs str="DATE_PLACEHOLDER" />}
                                                />
                                            </div>
                                            <div className={styles.formGroup}>
                                                <label htmlFor="secondReviewDate" className={styles.formLabel}><Langs str="SECOND_REVIEW_LABEL" /> :</label>
                                                <Calendar
                                                    id="secondReviewDate"
                                                    name="secondReviewDate"
                                                    value={bilan.secondReviewDate}
                                                    onChange={(e) => handleBilanChange('secondReviewDate', e.value)}
                                                    dateFormat="dd/mm/yy"
                                                    placeholder={<Langs str="DATE_PLACEHOLDER" />}
                                                />
                                            </div>

                                            <h5><Langs str="TRAININGS_CERTIFICATIONS_TITLE" /></h5>
                                            <div className={styles.form}>
                                                <DataTable value={bilan.vitalTraining} footer={bilanTrainingFooter} tableStyle={{ minWidth: '60rem' }}>
                                                    <Column field="date" header={<Langs str="TRAINING_DATE" />} />
                                                    <Column field="title" header={<Langs str="TITLE_HEADER" />} />
                                                    <Column field="certification" header={<Langs str="CERTIFICATION_HEADER" />} />
                                                    <Column field="evaluation" header={<Langs str="EVALUATION_HEADER" />} />
                                                    <Column field="origin" header={<Langs str="ORIGIN_HEADER" />} />
                                                    <Column field="financing" header={<Langs str="FINANCING_HEADER" />} />
                                                    <Column
                                                        body={(rowData, options) => (
                                                            <div>
                                                                <ImageButton
                                                                    image={deleteIcon}
                                                                    imageFocus={deleteIconFocus}
                                                                    onClick={() => handleDeleteBilanTraining(rowData)}
                                                                    tooltip={<Langs str="Delete" />}
                                                                    active={true}
                                                                    width={30} />
                                                                <ImageButton
                                                                    image={editIcon}
                                                                    imageFocus={editIconFocus}
                                                                    onClick={() => handleEditBilanTraining(rowData, options.rowIndex)}
                                                                    tooltip={<Langs str="Edit" />}
                                                                    active={true}
                                                                    width={30} />
                                                            </div>
                                                        )}
                                                        header="Actions"
                                                    ></Column>
                                                </DataTable>

                                            </div>
                                            <VEntrModal
                                                show={displayBilanTrainingModal}
                                                title="CONFIRM_TRAINING_BILAN"
                                                onHide={() => {
                                                    setDisplayBilanTrainingModal(false)
                                                    setOldBilanTraining({ date: '', title: '', certification: '', evaluation: '', origin: '', financing: '' });
                                                    setBilanTrainingErrors({});
                                                    setEditBilanTrainingIndex(null);
                                                }}
                                                message={
                                                    <div className={styles.modalContent}>
                                                        <div className={styles.modalRow}>
                                                            <label htmlFor="date"><Langs str="Date" /></label>
                                                            <Calendar
                                                                id="date"
                                                                name="date"
                                                                value={oldBilanTraining.date}
                                                                onChange={(e) => handleBilanTrainingChange('date', e.value)}
                                                                dateFormat="yy-mm-dd"
                                                                className="custom-calendar"
                                                                required
                                                            />
                                                            {bilanTrainingErrors.date && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                        </div>
                                                        <div className={styles.modalRow}>
                                                            <label htmlFor="title"><Langs str="TRAINING_TITLE" /></label>
                                                            <input
                                                                id="title"
                                                                name="title"
                                                                value={oldBilanTraining.title}
                                                                onChange={(e) => handleBilanTrainingChange('title', e.target.value)}
                                                                className="custom-input"
                                                                maxLength="50"
                                                                required
                                                            />
                                                            {bilanTrainingErrors.title && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                        </div>
                                                        <div className={styles.modalRow}>
                                                            <label htmlFor="certification"><Langs str="CERTIFICATION_OBTAINED" /></label>
                                                            <Dropdown
                                                                id="certification"
                                                                name="certification"
                                                                value={oldBilanTraining.certification}
                                                                options={[
                                                                    { label: <Langs str="YES" />, value: 'Oui' },
                                                                    { label: <Langs str="NO" />, value: 'Non' },
                                                                ]}
                                                                onChange={(e) => handleBilanTrainingChange('certification', e.value)}
                                                                placeholder={<Langs str="Select" />}
                                                                className="custom-dropdown"
                                                                panelClassName="custom-dropdown-panel"
                                                                required
                                                            />
                                                            {bilanTrainingErrors.certification && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                        </div>
                                                        <div className={styles.modalRow}>
                                                            <label htmlFor="evaluation"><Langs str="EVALUATION" /></label>
                                                            <input
                                                                id="evaluation"
                                                                name="evaluation"
                                                                value={oldBilanTraining.evaluation}
                                                                onChange={(e) => handleBilanTrainingChange('evaluation', e.target.value)}
                                                                className="custom-input"
                                                                maxLength="50"
                                                                required
                                                            />
                                                            {bilanTrainingErrors.evaluation && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                        </div>
                                                        <div className={styles.modalRow}>
                                                            <label htmlFor="origin"><Langs str="ORIGIN" /></label>
                                                            <Dropdown
                                                                id="origin"
                                                                name="origin"
                                                                value={oldBilanTraining.origin}
                                                                options={[
                                                                    { label: <Langs str="EMPLOYEE_INITIATIVE" />, value: 'Employé' },
                                                                    { label: <Langs str="EMPLOYER_INITIATIVE" />, value: 'Salarié' },
                                                                ]}
                                                                onChange={(e) => handleBilanTrainingChange('origin', e.value)}
                                                                placeholder={<Langs str="Select" />}
                                                                className="custom-dropdown"
                                                                panelClassName="custom-dropdown-panel"
                                                                required
                                                            />
                                                            {bilanTrainingErrors.origin && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                        </div>
                                                        <div className={styles.modalRow}>
                                                            <label htmlFor="financing"><Langs str="FINANCING" /></label>
                                                            <Dropdown
                                                                id="financing"
                                                                name="financing"
                                                                value={oldBilanTraining.financing}
                                                                options={[
                                                                    { label: <Langs str="CPF" />, value: 'CPF' },
                                                                    { label: <Langs str="EMPLOYER" />, value: 'Employeur' },
                                                                    { label: <Langs str="SELF_FINANCED" />, value: 'Autofinancé' },
                                                                ]}
                                                                onChange={(e) => handleBilanTrainingChange('financing', e.value)}
                                                                placeholder={<Langs str="Select" />}
                                                                className="custom-dropdown"
                                                                panelClassName="custom-dropdown-panel"
                                                                required
                                                            />
                                                            {bilanTrainingErrors.financing && <span className={styles.errorText}><Langs str="REQUIRED_FIELD" /></span>}
                                                        </div>
                                                    </div>
                                                }
                                                onConfirm={() => { handleSaveBilanTraining() }}
                                            />


                                            <h5><Langs str="OTHER_ACTIONS_TITLE" /></h5>
                                            <div className={styles.checkboxContainer}>
                                                <label className={styles.formLabel}>
                                                    <Langs str="ACTIONS_TAKEN_LABEL" />
                                                </label>
                                                <Checkbox
                                                    inputId="actionsTaken"
                                                    name="actionsTaken"
                                                    onChange={(e) => handleBilanChange('actionsTaken', e.checked)}
                                                    checked={bilan.actionsTaken}
                                                />
                                                <label htmlFor="actionsTaken" className={styles.checkboxLabel}>
                                                    <Langs str="YES" />
                                                </label>
                                            </div>
                                            {bilan.actionsTaken && (
                                                <div className={styles.formGroup}>
                                                    <label htmlFor="actionDescription" className={styles.formLabel}>
                                                        <Langs str="SPECIFY_ACTIONS_LABEL" />
                                                    </label>
                                                    <textarea
                                                        id="actionDescription"
                                                        name="actionDescription"
                                                        value={bilan.actionDescription}
                                                        onChange={(e) => handleBilanChange('actionDescription', e.target.value)}
                                                        className={styles.formInput}

                                                        maxLength="250"
                                                    />
                                                </div>
                                            )}
                                            <div className="flex py-4" style={{ display: 'flex', justifyContent: 'space-between', width: isMobile == 2 ? '75%' : '100%' }}>
                                                <div style={{ width: '10%' }}>
                                                    <PrimaryButton
                                                        title={<Langs str="Back" />}
                                                        active={true}
                                                        onClick={() => {
                                                            stepperRef.current.prevCallback();
                                                            scrollToBottom();
                                                        }}
                                                        style={{ borderRadius: '10px', backgroundColor: '#FF9015', border: 'none', width: '100%' }}
                                                    />
                                                </div>
                                                <div style={{ width: '10%' }}>
                                                    <PrimaryButton
                                                        title={<Langs str="Next" />}
                                                        active={isNextEnabled}
                                                        onClick={() => {
                                                            stepperRef.current.nextCallback();
                                                            scrollToBottom();
                                                        }}
                                                        style={{ borderRadius: '10px', backgroundColor: '#FF9015', border: 'none', width: '100%' }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </StepperPanel>
                                )}
                                <StepperPanel header={<Langs str="COMMENTS_HEADER" />}>
                                    <div>
                                        <div className={styles.formGroup}>
                                            <label htmlFor="comment" className={styles.formLabel}>
                                                <Langs str="COMMENTS_LABEL" />
                                            </label>
                                            <textarea
                                                id="comment"
                                                name="comment"
                                                value={comments}
                                                onChange={(e) => setComments(e.target.value)}
                                                className={styles.formInput}
                                                maxLength="250"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex py-4" style={{ display: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ width: '10%' }}>
                                            <PrimaryButton
                                                title={<Langs str="BACK_BUTTON" />}
                                                active={true}
                                                onClick={() => {
                                                    stepperRef.current.prevCallback();
                                                    scrollToBottom();
                                                }}
                                                style={{ borderRadius: '10px', backgroundColor: '#FF9015', border: 'none', width: '100%' }}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="flex py-4"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: isMobile === 2 ? 'row' : 'row',
                                            alignItems: 'center',
                                            gap: isMobile === 2 ? '10px' : '0'
                                        }}
                                    >
                                        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                                            <Col xs="auto" style={{ display: 'flex', justifyContent: 'flex-start' }}></Col>
                                            <Col xs="auto" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <PrimaryButton
                                                    title={<Langs str="SAVE_BUTTON" />}
                                                    active={true}
                                                    onClick={() => {
                                                        saveEntretien(false);
                                                    }}
                                                    style={{
                                                        borderRadius: '10px',
                                                        backgroundColor: '#FF9015',
                                                        border: 'none'
                                                    }}
                                                />
                                                <PrimaryButton
                                                    title={<Langs str="SEND_BUTTON" />}
                                                    active={true}
                                                    onClick={() => {
                                                        saveEntretien(true);
                                                        stepperRef.current.nextCallback();
                                                        scrollToBottom();
                                                    }}
                                                    style={{
                                                        borderRadius: '10px',
                                                        backgroundColor: '#FF9015',
                                                        border: 'none'
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </StepperPanel>
                            </Stepper>
                        </div>
                    )}
                    <div ref={bottomRef} style={{ height: '5px' }}></div>
                </div>
            )}
        </div>
    );
}

export default VentrView;
