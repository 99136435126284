import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkManager from "../../app/managers/networkManager";
import { config } from "../../app/utils/config";
import { store } from "../../app/store/store";
import { setIsLoading } from "../common/commonSlice";
import { showErrorToast, showSuccessToast } from "../../app/utils/helpers";


const initialState = {
    userDetails: null,
    clients: null,
    postes: null,
    entretienSaved: false,
    entretienSended: false,
    selectedView: 0,
    interviewsData: null,
    selectedEntretien: null,
}

export const VentrSlice = createSlice({
    name: "ventr",
    initialState,
    reducers: {
        setUserDetails: (state, action) => {
            state.userDetails = action.payload;
        },
        setClients: (state, action) => {
            state.clients = action.payload;
        },
        setPostes: (state, action) => {
            state.postes = action.payload;
        },
        setentretienSaved: (state, action) => {
            state.entretienSaved = action.payload;
        },
        setentretienSended: (state, action) => {
            state.entretienSended = action.payload;
        },
        setSelectedView: (state, action) => {
            state.selectedView = action.payload;
        },
        setSelectedEntretien: (state, action) => {  
            state.selectedEntretien = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserDetails.fulfilled, (state, action) => {
            if (action.payload.responseData) {
                if (action.payload.responseData.error === "Unauthorized") {
                    state.userDetails = "empty";
                } else {
                    state.userDetails = action.payload.responseData;
                }
            }
        });        
        builder.addCase(saveUserDetails.fulfilled, (state, action) => {
            state.entretienSaved = action.payload.responseData;
            if (action.payload.responseData) {
                showSuccessToast("Entretien enregistré avec succès");
            }
        });
        builder.addCase(getClients.fulfilled, (state, action) => {
            if (action.payload.responseData) {
                state.clients = action.payload.responseData;
            }
        });
        builder.addCase(getInterviewsData.fulfilled, (state, action) => {
            if (action.payload.responseData) {
                state.interviewsData = action.payload.responseData;
            }
        });
        builder.addCase(saveUserDetailsByManager.fulfilled, (state, action) => {
            state.entretienSaved = action.payload.responseData;
            if (action.payload.responseData) {
                showSuccessToast("Entretien enregistré avec succès");
            }
        });
        // builder.addCase(getPostes.fulfilled, (state, action) => {
        //     state.postes = action.payload.responseData;
        // });
    },
});

export const getUserDetails = createAsyncThunk(
    "ventr/getUserDetails",
    async () => {
        try {
            store.dispatch(setIsLoading(true));
            let response = await NetworkManager.getInstance().getRequest(
                config.apiPaths.ventr.getUserDetails
            );
            store.dispatch(setIsLoading(false));
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const saveUserDetails = createAsyncThunk(
    "ventr/saveUserDetails",
    async (data) => {
        try {
            let response = await NetworkManager.getInstance().postRequest(
                config.apiPaths.ventr.saveUserDetails,
                data
            );
            return response;
        } catch (error) {
            showErrorToast("Erreur lors de l'enregistrement de l'entretien");
            return error;
        }
    }
);

export const getClients = createAsyncThunk(
    "ventr/getClients",
    async () => {
        try {
            let response = await NetworkManager.getInstance().getRequest(
                config.apiPaths.planning.getClients
            );
            return response;
        } catch (error) {
            return error;
        }
    }
);

export var getInterviewsData = createAsyncThunk(
    "ventr/getInterviewsData",
    async () => {
        try {
            let response = await NetworkManager.getInstance().postRequest(
                config.apiPaths.ventr.getInterviewsData
            );
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const saveUserDetailsByManager = createAsyncThunk(
    "ventr/saveUserDetailsByManager",
    async (data) => {
        try {
            let response = await NetworkManager.getInstance().postRequest(
                config.apiPaths.ventr.saveUserDetailsByManager,
                data
            );
            return response;
        } catch (error) {
            showErrorToast("Erreur lors de l'enregistrement de l'entretien");
            return error;
        }
    }
);

export const { setSelectedView, setSelectedEntretien } = VentrSlice.actions;



export default VentrSlice.reducer;