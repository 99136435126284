import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import NetworkManager from "../../app/managers/networkManager";
import { config } from "../../app/utils/config";

const initialState = {
  WEEKLY_VIEW_DAYS: 14,
  selectedDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
  draggingItem: "",
  selectedView: "MONTHLY",
  selectedGroupe: 0,
  showFormPopup: false,
  selectedForm: null,
  selectedFormData: {},
  selectedSamplesView: false,
  planningList: [],
  selectedShifts: [],
  publications: {}
}

export const planningSlice = createSlice({
  name: "planning",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateShiftProperties: (state, action) => {
      if (action.payload.id) {
        let shifts = state.planningList.filter((shift) => shift.id === parseInt(action.payload.id))
        if (shifts.length) {
          let shift = shifts[0]
          for (const key in action.payload) {
            if (key === "id") continue
            shift[key] = action.payload[key]
          }
          shift["status"] = "UPDATED"
          shift["updatedAt"] = new Date().getTime()
          shift["updatedBy"] = null
        }
      }
    },
    deleteItemFromPlanningList: (state, action) => {
      let item = state.planningList.filter(
        (shift) => shift.id == action.payload
      )
      let index = state.planningList.indexOf(item[0])
      if (index > -1) {
        // only splice array when item is found
        state.planningList.splice(index, 1) // 2nd parameter means remove one item only
      }

      // check if shift cell selected remove from selected list
      let selectedIndex = state.selectedShifts.indexOf(action.payload)
      if (selectedIndex > -1) {
        state.selectedShifts.splice(selectedIndex, 1)
      }
    },
    fillPlanningList: (state, action) => {
      state.planningList = state.planningList.concat(action.payload)
    },
    setPublishedStatus: (state, action) => {
      for (const key in state.planningList) {
        if (state.planningList[key].status != 'PUBLISHED') {
          state.planningList[key].status = "PUBLISHED"
        }
      }
    },
    setShowFormPopup: (state, action) => {
      state.showFormPopup = true
      state.selectedForm = action.payload.type
      if (action.payload.data) state.selectedFormData = action.payload.data
    },
    hideFormPopup: (state, action) => {
      state.showFormPopup = false;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload
    },
    setDraggingItem: (state, action) => {
      state.draggingItem = action.payload
    },
    setSelectedView: (state, action) => {
      let dateSelected = new Date(state.selectedDate)
      if (action.payload === "WEEKLY") {
        if (dateSelected.getDay() > 1 && state.selectedView !== "WEEKLY") {
          dateSelected.setDate(dateSelected.getDate() - dateSelected.getDay())
        }
      } else {
        if (state.selectedView !== "MONTHLY") {
          // check if weekly selected month is not the same as latest monthly selected date
          if (state.selectedView === "WEEKLY") {
            let toDate = new Date(dateSelected.getTime())
            toDate.setDate(toDate.getDate() + 7)
            if (toDate.getMonth() != dateSelected.getMonth()) {
              dateSelected.setMonth(dateSelected.getMonth() + 1)
            }
          }
          dateSelected.setDate(1)
        }
      }
      state.selectedDate = dateSelected.getTime()
      state.selectedView = action.payload
    },
    setSelectedFormData: (state, action) => {
      state.selectedFormData = action.payload;
    },
    setSelectedGroupe: (state, action) => {
      state.selectedGroupe = action.payload;
    },
    setSelectedSamplesView: (state, action) => {
      state.selectedSamplesView = action.payload;
    },
    toggleSelectedShift: (state, action) => {
      let shift = action.payload
      let index = state.selectedShifts.indexOf(shift.id)
      if (index > -1) {
        state.selectedShifts.splice(index, 1)
      } else {
        state.selectedShifts.push(shift.id)
      }
    },
    clearSelectedShifts: (state, action) => {
      state.selectedShifts = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlanning.fulfilled, (state, action) => {
      if (
        action.payload.responseData &&
        action.payload.responseData["planning"]
      ) {
        let planningList = []
        let publicationsMap = {}
        action.payload.responseData["publications"].map(publication => {
          publicationsMap[publication.id_team] = publication
        })
        state.publications = publicationsMap
        for (const key in action.payload.responseData["planning"]) {
          let planning = action.payload.responseData["planning"][key]
          planningList.push({
            value: key,
            label: planning["task"],
            id: planning["id"],
            id_sample_shift: planning["id_sample_shift"],
            id_sample_leave: planning["id_sample_leave"],
            id_collab: planning["id_collab"],
            id_groupe: planning['id_groupe'],
            id_client: planning["id_client"],
            id_team: planning["id_team"],
            id_team_delegate: planning["id_team_delegate"],
            type: planning["type"],
            validatedBy: planning["validatedBy"],
            task: planning["task"],
            description: planning["description"],
            from: planning["from"],
            to: planning["to"],
            status: planning["status"],
            absent: planning["absent"],
            createdAt: new Date(planning["created_at"]).getTime(),
            updatedAt: new Date(planning['updated_at']).getTime(),
            createdBy: planning["createdBy"],
            updatedBy: planning["updatedBy"],
          })
        }
        state.planningList = planningList;
      }
    })
  }
})

export const getPlanning = createAsyncThunk(
  "planning/getPlanning",
  async (payload, { getState }) => {
    const state = getState()
    try {
      const firstDay = new Date(state.planning.selectedDate)
      let firstDayTimeStamp = firstDay.getTime()
      let lastDay = new Date(firstDayTimeStamp)
      if (state.selectedView === "WEEKLY") {
        lastDay.setDate(firstDay.getDate() + state.WEEKLY_VIEW_DAYS)
      } else {
        lastDay.setMonth(firstDay.getMonth() + 1)
      }
      let body = {
        teamsIds: payload.selectedTeamsIds,
        collabsIds: payload.selectedCollabsIds,
        from: firstDayTimeStamp,
        to: lastDay.getTime()
      }
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.getPlanning,
        body
      )
      return response
    } catch (error) {
      return error
    }
  }
)

export const prefillPlanning = createAsyncThunk(
  "planning/prefill",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.prefill,
        body
      )
      return response;
    } catch (error) {
      return error
    }
  }
)

export const deletePlanning = createAsyncThunk(
  "planning/delete",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.delete,
        body
      )
      return response;
    } catch (error) {
      return error
    }
  }
)

export const submitShifts = createAsyncThunk(
  "planning/submitShifts",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.submitShifts,
        body
      )
      return response;
    } catch (error) {
      return error
    }
  }
)

export const insertPrimeCollab = createAsyncThunk(
  "planning/insertPrimeCollab",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.insertPrimeCollab,
        body
      )
      return response;
    } catch (error) {
      return error
    }
  }
)

export const {
  hideFormPopup,
  setShowFormPopup,
  setSelectedDate,
  setDraggingItem,
  setSelectedView,
  setSelectedFormData,
  setSelectedGroupe,
  setSelectedSamplesView,
  setPublishedStatus,
  fillPlanningList,
  deleteItemFromPlanningList,
  updateShiftProperties,
  toggleSelectedShift,
  clearSelectedShifts,
} = planningSlice.actions;

export default planningSlice.reducer;
