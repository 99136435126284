import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Langs from "../../app/lang/langs";
import { ThemeButton } from "../../components/buttons/ThemeButton";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { prefillPlanning, getPlanning } from "./planningSlice";
import { showErrorToast } from "../../app/utils/helpers";

export function PrefillModal(props) {

  const dispatch = useDispatch()

  const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
  const selectedDate = useSelector((state) => state.planning.selectedDate)
  const collabs = useSelector((state) => state.collabs.list);

  const handleFloatingButtonClick = (event) => {
    dispatch(prefillPlanning({
      id_team: selectedTeamsIds[0],
      month: new Date(selectedDate).getMonth(),
      year: new Date(selectedDate).getFullYear()
    })).then((response) => {
      if (response['payload']['responseData']['status'] == 1) {
        loadPlanning()
      }
      if (response['payload']['responseData']['status'] == 4) {
        showErrorToast(response['payload']['responseData']['message'])
      }
      if (response['payload']['responseError']) {
        showErrorToast()
    }
    })
    hide()
  }

  function loadPlanning(){
    if (selectedTeamsIds && selectedTeamsIds.length && collabs.length) {
      let selectedCollabsIds = collabs.reduce((array, current) =>{
        array.push(current.id)
        return array
      }, [])
      dispatch(getPlanning({selectedTeamsIds, selectedCollabsIds}))
    }
  }

  function hide() {
    props.onHide()
  }

  function renderBodyView() {
    return (
      <Modal.Body>
        <Row className="d-flex justify-content-center align-items-center p-2">
          <label><Langs str='ARE_YOU_SURE_PREFILL' /></label>
        </Row>
      </Modal.Body>
    );
  }

  function renderHeaderView() {
    return (
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <label><Langs str='PREFILL' /></label>
        </Modal.Title>
      </Modal.Header>
    );
  }

  function renderFooterView() {
    return (
      <Modal.Footer className="d-flex justify-content-between">
        <Col md={3}>
          <PrimaryButton title="CANCEL" active={true} onClick={() => hide()} />
        </Col>
        <Col md={3}>
          <ThemeButton
            title="YES"
            active={true}
            onClick={() => handleFloatingButtonClick()}
          />
        </Col>
      </Modal.Footer>
    );
  }
  return (
    <Modal
      {...props}
      onHide={() => hide()}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {renderHeaderView()}
      {renderBodyView()}
      {renderFooterView()}
    </Modal>
  );
}
