import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import styles from './Forms.module.css';
import { PrimaryButton } from "../../buttons/PrimaryButton";
import Modal from 'react-bootstrap/Modal';
import Langs from "../../../app/lang/langs";
import { ThemeButton } from '../../buttons/ThemeButton'
import { RadioButton } from '../../buttons/RadioButton';
import { TransparentDatePicker } from '../../buttons/TransparentDatePicker';
import { Message } from 'primereact/message';
import { getPlanning, prefillPlanning } from '../../../containers/planning/planningSlice';
import moment from 'moment';
import { showErrorToast } from '../../../app/utils/helpers';

export function DuplicateFormModal(props) {

    const dispatch = useDispatch()
    const [durationType, setDurationType] = useState("WEEKLY")
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [selectedTargetDate, setSelectedTargetDate] = useState(new Date())
    const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
    const collabs = useSelector((state) => state.collabs.list)

    function hide() {
        props.onHide()
    }

    function loadPlanning(){
        if (selectedTeamsIds && selectedTeamsIds.length && collabs.length) {
          let selectedCollabsIds = collabs.reduce((array, current) =>{
            array.push(current.id)
            return array
          }, [])
          dispatch(getPlanning({selectedTeamsIds, selectedCollabsIds}))
        }
    }

    function handleDuplicatePlanning() {
        let range = "month"
        if (durationType == "WEEKLY") {
            range = "week"
        }
        let sourceFrom = moment(selectedDate).startOf(range)
        let sourceTo = moment(selectedDate).endOf(range)

        let targetFrom = moment(selectedTargetDate).startOf(range)
        let targetTo = moment(selectedTargetDate).endOf(range)

        if (sourceFrom && sourceTo && targetFrom && targetTo) {
            let params = {
                teamIds: selectedTeamsIds,
                sourceFrom: sourceFrom.format("x"),
                sourceTo: sourceTo.format("x"),
                targetFrom: targetFrom.format("x"),
                targetTo: targetTo.format("x")
            }
            if (durationType == "WEEKLY") {
                params.targetWeek = targetTo.week()
            } else {
                params.targetMonth = targetTo.month()
            }
            dispatch(prefillPlanning(params)).then((response) => {
                if (response['payload']['responseData']['status'] == 1) {
                    loadPlanning()
                }
                if (response['payload']['responseData']['status'] == 4) {
                    showErrorToast(response['payload']['responseData']['message'])
                }
                if (response['payload']['responseError']) {
                    showErrorToast()
                }
            })
        }
        hide()
    }

    const onSelectedDateChange = (date) => {
        setSelectedDate(date)
    }

    const onSelectedTargetDateChange = (date) => {
        setSelectedTargetDate(date)
    }

    function renderBodyView() {
        return <Modal.Body>
            <Row className='d-flex justify-content-center align-items-center p-1 pb-2'>
                <Col md={3}><label><Langs str='Duration' /></label></Col>
                <Col md={4}>
                    <RadioButton
                        title={"Week"}
                        selected={durationType == "WEEKLY"}
                        setSelected={() => setDurationType("WEEKLY")} />
                </Col>
                <Col md={5}>
                    <RadioButton
                        title={"Month"}
                        selected={durationType == "MONTHLY"}
                        setSelected={() => setDurationType("MONTHLY")} />
                </Col>
            </Row>
            <Row className='d-flex justify-content-center align-items-center p-1 pb-2'>
                <Col md={3}><label><Langs str='Source' /></label></Col>
                <Col md={9}>
                    <TransparentDatePicker
                        viewType={durationType}
                        onChange={onSelectedDateChange}
                        value={selectedDate} />
                </Col>
            </Row>
            <Row className='d-flex justify-content-center align-items-center p-1 pb-2'>
                <Col md={3}><label><Langs str='Target' /></label></Col>
                <Col md={9}>
                    <TransparentDatePicker
                        viewType={durationType}
                        onChange={onSelectedTargetDateChange}
                        value={selectedTargetDate} />
                </Col>
            </Row>
            <Row className='d-flex justify-content-center align-items-center p-1 pb-2'>
                <Message severity="warn" text={<Langs str="DUPLICATE_NOTE" />} />
            </Row>
        </Modal.Body>
    }

    function renderHeaderView() {
        return <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                <Langs str='Duplicate planning' />
            </Modal.Title>
        </Modal.Header>
    }

    function renderFooterView() {
        return <Modal.Footer className="d-flex justify-content-between">
            <Col md={3}>
                <PrimaryButton
                    title="CANCEL"
                    active={true}
                    onClick={() => hide()} />
            </Col>
            <Col md={3}>
                <ThemeButton
                    title="Duplicate"
                    active={true}
                    onClick={() => handleDuplicatePlanning()} />
            </Col>
        </Modal.Footer>
    }
    return <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >

        {renderHeaderView()}
        {renderBodyView()}
        {renderFooterView()}

    </Modal>
}