import React, { useState } from 'react';
import styles from './Cells.module.css';
import { useSelector } from "react-redux";
import { Row } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {
    getFormattedDate, isWorkingShift,
} from "../../../app/utils/helpers";
import Langs from '../../../app/lang/langs';

export function DateCell({ data, isDayOff, amShifts, pmShifts, nightShifts }) {

    const renderTooltip = (props) => (
        <div className={styles.tooltip} id={props.name} {...props}>
            <span className={styles.matinCount}><Langs str="Matin"/>: {amShifts && amShifts.length || 0}</span><br />
            <span className={styles.soirCount}><Langs str="Soir"/>: {pmShifts && pmShifts.length || 0}</span> <br />
            <span className={styles.nuitCount}><Langs str="Nuit"/>: {nightShifts && nightShifts.length || 0}</span>
        </div>
    )

    return <div className={styles.dateCell + " " + (isDayOff && styles.dateCellOff)}>
        <Row className='d-flex justify-content-center align-items-center h-50'>
            <label>{data.text}</label>
        </Row>
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 500, hide: 100 }}
            overlay={renderTooltip}>
            <Row className='d-flex justify-content-center align-items-center h-50'>
                <span className={styles.matinCount}>{amShifts && amShifts.length || 0}</span>
                <span>|</span>
                <span className={styles.soirCount}>{pmShifts && pmShifts.length || 0}</span>
                <span>|</span>
                <span className={styles.nuitCount}>{nightShifts && nightShifts.length || 0}</span>
            </Row>
        </OverlayTrigger>
    </div>
}