import React from 'react';
import styles from './Inputs.module.css';

export function TextInput(props) {

    return <input
        maxLength={props.maxLength}
        value={props.value}
        type="text"
        className={styles.inputText}
        placeholder=""
        onChange={props.onChange} />
}