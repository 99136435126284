import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setAdminConfigModal,
    getBankAptitudesOnCreation,
    setAddElementModalVisibility,
    reorderPublicForm,
    setRespPublicFrom,
    hidePublicCatSub,
    hidePublicAptitudes,
    setAutoQuestionsModalVisibility,
    setQuestionsParentAptitude
} from "./vcompSlice";
import Langs from "../../app/lang/langs";
import { Tree } from "primereact/tree";
import addIcon from '../../assets/images/addIconGray.png'
import addIconFocus from '../../assets/images/addIconOrange.png'
import deleteIcon from '../../assets/images/delete.png'
import deleteIconFocus from '../../assets/images/delete-orange.png'
import editIcon from '../../assets/images/penGray.png'
import editIconFocus from '../../assets/images/penOrange.png'
import AddElementModal from "./AddElementModal";
import ImageButton from "../buttons/ImageButton";
import { Col, Row } from "react-bootstrap";
import { ThemeButton } from "../buttons/ThemeButton";
import { isAdmin, showErrorToast, showSuccessToast } from "../../app/utils/helpers";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';




export default function AdminFormConfig() {



    const dispatch = useDispatch()

    const visible = useSelector((state) => state.vcomp.adminConfigModal);
    const bankAptitudesOnCreation = useSelector((state) => state.vcomp.bankAptitudesOnCreation);
    const respPublicFrom = useSelector((state) => state.vcomp.respPublicFrom)


    const [nodes, setNodes] = useState([])

    useEffect(() => {
        if (visible) {
            dispatch(getBankAptitudesOnCreation())
        }
    }, [visible])

    useEffect(() => {
        let data = [];
        let popedKeys = []
        if (bankAptitudesOnCreation && bankAptitudesOnCreation.categories) {
            const processNode = (node) => {
                const keyPrefix = node.scope === 'category' ? 'cat' :
                    node.scope === 'subcategory' ? 'subcat' : 'apt';
                const key = `${keyPrefix}-${node.id}`;
                if (keyPrefix === 'apt') {
                    popedKeys.push(key);
                }
                return {
                    key: key,
                    id: node.id,
                    label: node.nom,
                    coef: node.coef,
                    className: node.scope,
                    pos: node.pos,
                    visibility: node.visibility,
                    description: node.description,
                    type: node.type,
                    aspect: node.aspect ? node.aspect : null,
                    children: node.children ? node.children.map(child => processNode(child)) : []
                };
            };
            data = bankAptitudesOnCreation.categories.map(category =>
                processNode(category)
            );
        }
        setNodes(data);
    }, [bankAptitudesOnCreation])

    useEffect(() => {
        if (respPublicFrom != null) {
            if (respPublicFrom.trim().toLowerCase().includes("successfully")) {
                dispatch(getBankAptitudesOnCreation())
                showSuccessToast(respPublicFrom);
            } else {
                showErrorToast(respPublicFrom);
            }
            dispatch(setRespPublicFrom(null));
        }
    }, [respPublicFrom]);

    const handleClose = () => {

        dispatch(setAdminConfigModal(false))

    }

    const handleNodeDoubleClick = (e) => {
        let subcat = isChild(nodes, e.node.key);
        dispatch(setAddElementModalVisibility({ visibility: true, element: e.node, subcategory: subcat, isEdit: 1, isAdmin: 1 }))
    }
    const nodeTemplate = (node) => {
        let subcat = isChild(nodes, node.key);
        let type = node ? node.key.split('-')[0] : null
        return (
            <div className="node-container">
                <span>{node.label}</span>&nbsp;{node.aspect === 'theoretical' && <b>[T]</b>}{node.aspect === 'practical' && <b>[P]</b>}&nbsp;
                <div className="node-button">
                    {node.visibility &&
                        <ImageButton
                            image={editIcon}
                            imageFocus={editIconFocus}
                            onClick={() => {
                                dispatch(setAddElementModalVisibility({ visibility: true, element: node, subcategory: subcat, isEdit: 1 }))
                            }}
                            active={true}
                            width={32}
                        />
                    }
                    <ImageButton
                        image={deleteIcon}
                        imageFocus={deleteIconFocus}
                        onClick={() => {
                            if (type === "apt") {
                                confirmDeleteApt(node.id);
                            } else {
                                confirmDeleteCatSub(node.id);
                            }
                        }}
                        active={true}
                        width={32}
                    />
                    {node.className != 'aptitude' &&
                        <ImageButton
                            image={addIcon}
                            imageFocus={addIconFocus}
                            onClick={() => {
                                dispatch(setAddElementModalVisibility({ visibility: true, element: node, subcategory: subcat, isEdit: 0, isAdmin: 1, isEdit: 0 }))
                            }}
                            active={true}
                            width={32}
                        />
                    }
                    {node.className == 'aptitude' &&
                        <ImageButton
                            image={addIcon}
                            imageFocus={addIconFocus}
                            onClick={() => {
                                dispatch(setAutoQuestionsModalVisibility(true));
                                dispatch(setQuestionsParentAptitude(node))
                            }}
                            active={true}
                            width={32}
                        />
                    }
                </div>
            </div>
        );
    };
    const editTreeHeader = () => {
        let position;
        if (nodes) {
            position = nodes.length
        } else {
            position = 0
        }
        return (
            <div className="col-2">
                <ImageButton
                    image={addIcon}
                    imageFocus={addIconFocus}
                    onClick={() => {
                        dispatch(setAddElementModalVisibility({ visibility: true, element: null, subcategory: false, pos: position, isAdmin: 1, isEdit: 0 }))
                    }}
                    active={true}
                    width={32}
                />
            </div>
        )
    }
    function isChild(objects, searchKey) {
        function searchChildren(children) {
            return children.some(child => {
                if (child.key === searchKey) {
                    return true;
                }
                if (child.children && child.children.length > 0) {
                    return searchChildren(child.children);
                }
                return false;
            });
        }
        return objects.some(obj => {
            if (obj.children && obj.children.length > 0) {
                return searchChildren(obj.children);
            }
            return false;
        });
    }
    const dragDropAptitudes = (event) => {
        const { value, dragNode, dropNode } = event;
        if (dropNode) {
            if (dropNode.key.trim().toLowerCase().includes("apt")) {
                return
            }
        }
        const hasCategoryChild = dragNode.children && dragNode.children.some(child => child.key.trim().toLowerCase().includes("cat"));
        if (hasCategoryChild && dropNode) {
            return
        }
        if (dropNode && isChild(value, dropNode.key) &&
            dropNode.key.trim().toLowerCase().includes("cat") &&
            !dragNode.key.trim().toLowerCase().includes("apt")) {
            return
        }
        setNodes(value);
    }

    const handleAddtProjectFormEdit = () => {
        const categories = [];
        const aptitudes = []
        nodes.forEach((node, index) => {
            if (node.key.trim().toLowerCase().includes("cat")) {
                let idParent = node.id
                categories.push(
                    {
                        id: node.id,
                        pos: index
                    }
                )
                if (node.children && node.children.length > 0) {
                    node.children.forEach((childCat, index) => {
                        if (childCat.key.trim().toLowerCase().includes("cat")) {
                            categories.push(
                                {
                                    id: childCat.id,
                                    id_parent: idParent,
                                    pos: index
                                }
                            )
                            if (childCat.children && childCat.children.length > 0) {
                                childCat.children.forEach((childApt, index) => {
                                    if (childApt.key.trim().toLowerCase().includes("apt")) {
                                        aptitudes.push({
                                            id: childApt.id,
                                            id_category: childCat.id,
                                            pos: index
                                        })
                                    }
                                })
                            }
                        }
                        if (childCat.key.trim().toLowerCase().includes("apt")) {
                            aptitudes.push(
                                {
                                    id: childCat.id,
                                    id_category: idParent,
                                    pos: index
                                }
                            )
                        }
                    })
                }
            }
            if (node.key.trim().toLowerCase().includes("apt")) {
                aptitudes.push(
                    {
                        id: node.id,
                        id_category: null,
                        pos: index

                    }
                )
            }

        })

        dispatch(reorderPublicForm({
            categories: categories,
            aptitudes: aptitudes
        }))

    }

    const confirmDeleteCatSub = (id) => {
        confirmDialog({
            message: <Langs str="DELETE_ASSOCIATED" />,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            draggable: false,
            acceptLabel: <Langs str="KEEP" />,
            rejectLabel: <Langs str="DELETE" />,
            accept: () => { deleteCatSubFromProject(true, id) },
            reject: () => { deleteCatSubFromProject(false, id) },
        });
    };
    const deleteCatSubFromProject = (keeper, id) => {
        dispatch(hidePublicCatSub(
            {
                keep: keeper,
                id_category: id
            }
        ))
    }
    const confirmDeleteApt = (id) => {
        confirmDialog({
            message: <Langs str="DELETE_APTITUDE_CONFIRM_PUBLIC" />,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            draggable: false,
            acceptLabel: <Langs str="DELETE" />,
            rejectLabel: <Langs str="CANCEL" />,
            accept: () => { deleteAptFromProject(id) }
        });
    };
    const deleteAptFromProject = (id) => {
        dispatch(hidePublicAptitudes(
            {
                id_aptitude: id
            }
        ))
    }

    function body() {
        return (
            <div style={{ overflowY: "auto", height: "calc(100vh - 180px" }}>
                <Tree
                    value={nodes}
                    dragdropScope="demo"
                    onNodeDoubleClick={(e) => { handleNodeDoubleClick(e) }}
                    nodeTemplate={nodeTemplate}
                    filter={true}
                    filterPlaceholder="Search..."
                    onDragDrop={(e) => { dragDropAptitudes(e) }}
                    showHeader={true}
                    className="w-full md:w-30rem custom-tree border-0"
                />
                <ConfirmDialog />
            </div>
        )
    }

    const header = () => {
        return (
            <Row className="d-flex justify-content-start">
                <Col lg={2} xs={6} className="d-flex justify-content-center my-auto"><Langs str="General Form Config" /></Col>
                {editTreeHeader()}
            </Row>
        )
    }
    const footer = () => {
        return (
            <Row className="d-flex justify-content-around">
                <Col lg={1} xs={3}>
                    <ThemeButton title="CLOSE" active={true} onClick={()=>{handleClose()}} style={{ width: '48%' }} />
                </Col>
                <Col lg={1} xs={3}>
                    <ThemeButton title="SAVE" active={true} onClick={()=>{handleAddtProjectFormEdit()}} style={{ width: '48%' }} />
                </Col>
            </Row>
        )
    };




    return (
        <div className="card flex justify-content-center">
            <Dialog
                visible={visible}
                style={{ width: '100vw', height: '100vh', maxHeight: "100%", backgroundColor: "grey" }}
                onHide={() => { handleClose() }}
                draggable={false}
                resizable={false}
                header={header}
                footer={footer}
                className="customDialog"
            >
                {body()}
            </Dialog>
        </div>
    )
}