import React, { useState } from 'react';
import styles from './Inputs.module.css';

export function InputWithSubmit(props) {

    const [currentValue, setCurrentValue] = useState(props.value)

    const onChangeHandler = (event) => {
        setCurrentValue(event.target.value)
    }

    const onBlurHandler = (event) => {
        if (event.currentTarget === event.target && props.value != currentValue) {
            props.onSubmit(event)
        }
    }

    return <div className={styles.inputWithSubmit}>
        <input {...props}
            value={currentValue}
            onBlur={onBlurHandler}
            onChange={onChangeHandler} />
    </div>
}