import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit';
import NetworkManager from '../../app/managers/networkManager';
import { config } from '../../app/utils/config';

const initialState = {
  list: []
}

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addClient: (state, action) => {
      state.push({
        value: nanoid(),
        label: action.payload.name
      })
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getClients.fulfilled, (state, action) => {
      if (
        action.payload.responseData &&
        action.payload.responseData["clients"]
      ) {
        let clientsList = [];
        for (const key in action.payload.responseData["clients"]) {
          let client = action.payload.responseData["clients"][key];
          clientsList.push({
            value: client['id'],
            label: client['name'],
            id: client['id'],
            nom: client["name"],
            createdAt: new Date(client['created_at']).getTime(),
            updatedAt: new Date(client['updated_at']).getTime()
          })
        }
        state.list = clientsList;
      }
    });
  },
});

export const getClients = createAsyncThunk(
  "planning/getClients",
  async () => {
    try {
      let response = await NetworkManager.getInstance().getRequest(
        config.apiPaths.planning.getClients
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const { addClient
} = clientsSlice.actions

export default clientsSlice.reducer