import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkManager from "../../../app/managers/networkManager";
import { config } from "../../../app/utils/config";
import { generateUniqueId } from "../../../app/utils/helpers";

const initialState = {
  list: [],
  shiftsList: [],
  localShiftsList: [],
};

export const shiftsSlice = createSlice({
  name: "shifts",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addShiftLocally: (state, action) => {
      let data = {
        id: generateUniqueId(),
        type: action.payload.type,
        id_collab: action.payload.id_collab,
        id_groupe: action.payload.id_groupe,
        id_sample_shift: action.payload.id_sample_shift,
        description: action.payload.description,
        id_client: action.payload.id_client,
        task: action.payload.task,
        absent: action.payload.absent,
        id_team: action.payload.id_team,
        from: parseInt(action.payload.from),
        to: parseInt(action.payload.to),
      }
      if (action.payload.id_team_delegate)
        data.id_team_delegate = action.payload.id_team_delegate
      if (action.payload.id_sample_leave){
        data.id_sample_leave = action.payload.id_sample_leave
      }
      state.shiftsList.push(data)
    },
    updateShiftLocally: (state, action) => {
      if (action.payload.id) {
        let shifts = state.shiftsList.filter((shift) => shift.id === parseInt(action.payload.id));
        if (shifts.length) {
          let shift = shifts[0];
          for (const key in action.payload) {
            if (key === "id") continue;
            shift[key] = action.payload[key]
          }
        }
      }
    },
    deleteShiftLocally: (state, action) => {
      if (action.payload) {
        state.shiftsList.map((shift, index) => {
          if (shift.id === action.payload) {
            return state.shiftsList.splice(index, 1)
          }
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addShifts.fulfilled, (state, action) => {
      state.localShiftsList = state.shiftsList
      state.shiftsList = []
    });
    builder.addCase(addShifts.rejected, (state, action) => {
      // state.list = [];
      // state.shiftsList = [];
      // state.localShiftsList = [];
    });
    builder.addCase(updateShifts.fulfilled, (state, action) => {
    });
    builder.addCase(updateTimesheet.fulfilled, (state, action) => {
    });
  },
});

export const addShifts = createAsyncThunk(
  "planning/addShifts",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.addShifts,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateShifts = createAsyncThunk(
  "planning/updateShifts",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.updateShifts,
        body
      )
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const deleteShifts = createAsyncThunk(
  "planning/deleteShifts",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.deleteShifts,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateTimesheet = createAsyncThunk(
  "planning/updateTimesheet",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.planning.updateTimesheet,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const { 
  addShiftLocally,
  updateShiftLocally,
  deleteShiftLocally
} = shiftsSlice.actions;

export default shiftsSlice.reducer;
