import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NetworkManager from "../../app/managers/networkManager";
import { config } from "../../app/utils/config";
import { dynamicSort } from '../../app/utils/helpers';

const initialState = {
    list: [],
    allTeams: [],
    myTeam: null,
    selectedTeamsIds: []
}

export const teamsSlice = createSlice({
    name: 'teams',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setSelectedTeams: (state, action) => {
            state.selectedTeamsIds = action.payload
        },
        updateAllTeamsSettings: (state, action) => {
            state.list.map(team => {
                if(action.payload.id == team.id){
                    team.settings = action.payload.settings
                }
            })
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTeams.fulfilled, (state, action) => {
                if (action.payload.responseData) {
                    if (action.payload.responseData["teams"]) {
                        let teamsList = []
                        for (const key in action.payload.responseData["teams"]) {
                            let team = action.payload.responseData["teams"][key]
                            let teamData = {
                                value: team["id"],
                                label: team["name"],
                                id: team["id"],
                                localization: team["localization"],
                                category: "Vue Manager",
                                lunchBreakDuration: team["lunch_break_duration"],
                                settings: team.settings
                            }
                            teamsList.push(teamData)
                        }
                        teamsList.sort(dynamicSort("label"))
                        state.list = teamsList
                    }
                    if (action.payload.responseData["allTeams"]) {
                        let teamsList = []
                        for (const key in action.payload.responseData["allTeams"]) {
                            let team = action.payload.responseData["allTeams"][key]
                            teamsList.push({
                                value: team["id"],
                                label: team["name"],
                                id: team["id"],
                                localization: team["localization"]
                            })
                        }
                        teamsList.sort(dynamicSort("label"))
                        state.allTeams = teamsList
                    }
                    if (action.payload.responseData["myTeam"]) {
                        let team = action.payload.responseData["myTeam"]
                        let teamData = {
                            value: team["id"],
                            label: team["name"],
                            id: team["id"],
                            localization: team["localization"],
                            userRoleId: team["userRoleId"],
                            category: team["userRoleId"] == 2 ? "Vue Manager" : "Vue Collab"
                        }
                        state.myTeam = teamData
                    }
                }
            })
    },
})

export const getTeams = createAsyncThunk(
    'planning/getTeams',
    async () => {
        try {
            let response = await NetworkManager.getInstance().getRequest(config.apiPaths.planning.getTeams)
            return response
        } catch (error) {
            return error
        }
    }
)

export const updateCollabsOrder = createAsyncThunk(
    'planning/updateCollabsOrder',
    async (body) => {
        try {
            let response = await NetworkManager.getInstance().postRequest(config.apiPaths.planning.updateCollabsOrder, body)
            return response
        } catch (error) {
            return error
        }
    }
)

export const {
    addTeam,
    setSelectedTeams,
    updateAllTeamsSettings
} = teamsSlice.actions

export default teamsSlice.reducer