import React from 'react';
import styles from './Cells.module.css';
import { useDispatch, useSelector } from "react-redux";
import { setSelectedGroupe } from '../../../containers/planning/planningSlice';
import { setCollabsGroupeId } from '../../collabs/collabsSlice';

export function GroupeCell(props) {

    const dispatch = useDispatch()
    const selectedGroupe = useSelector((state) => state.planning.selectedGroupe)

    const handleGroupeSelect = () => {
        dispatch(setSelectedGroupe(props.data.value))
        dispatch(setCollabsGroupeId(props.data.id))
    }

    let style = {}
    if (props.data.color) style.color = props.data.color
    return <div className={styles.groupeCell} >
        <button
            className={selectedGroupe === props.data.value ? styles.selected : ""}
            onClick={handleGroupeSelect} 
            style={style}>
            {props.data.label}
        </button>
    </div>
}