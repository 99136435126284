import {useSelector} from "react-redux";

import en from "./en";
import fr from "./fr";

import { addLocale, locale } from 'primereact/api';
import moment from "moment";

// for dataTables
addLocale('fr', {
    firstDayOfWeek: 1,
    dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'May', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: "Aujourd'hui",
    apply: 'Appliquer',
    clear: 'Vider',
    addRule: "Ajouter une condition",
    equals: "Est égal",
    contains: "Contient",
    notContains: "Ne contient pas",
    endsWith: "Se termine par",
    matchAll: "Correspondre à tout",
    matchAny: "Correspond à n'importe"
})

locale(localStorage.getItem("lang") || "fr")
moment.locale(localStorage.getItem("lang") || "fr")

const DEFAULT_LANG = "fr"
let langs = {
    "en": en,
    "fr": fr
}
export default function Langs(props) {
    let lang = useSelector((state) => state.settings.lang)
    if(!lang) lang = DEFAULT_LANG
    let Lang = langs[lang]
    let finalStr = props.str
    if (Lang && Lang[props.str])
        finalStr = Lang[props.str]
    if (props.params && Object.keys(props.params).length){
        for (const key in props.params) {
            //console.log(key, props.params[key])
            let regex = new RegExp("%" + key + "%", "g");
            finalStr = finalStr.replace(regex, props.params[key])
        }
    }
    return finalStr
}

export function Localize(str, lang) {
    if(!lang) lang = DEFAULT_LANG
    let Lang = langs[lang]
    let finalStr = str
    if (Lang && Lang[str])
        finalStr = Lang[str]
    return finalStr
}