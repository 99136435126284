import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import addIcon from "../../assets/images/add.png";
import addIconFocus from "../../assets/images/addFocus.png";
import exportIcon from "../../assets/images/export.png";
import exportIconFocus from "../../assets/images/exportFocus.png";
import { Col, Row, Toast } from "react-bootstrap";
import Langs, { Localize } from "../../app/lang/langs";
import styles from "./Reports.module.css";
import { TeamSelector } from "../../components/teams/TeamSelector";
import ImageButton from "../../components/buttons/ImageButton";
import { ReportFormModal } from "./ReportFormModal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from 'primereact/api';
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { getReports, setSelectedView, setSelectedDate } from "./reportsSlice";
import { Tag } from "primereact/tag";
import { getStatusTagClass } from "../../app/utils/helpers";
import NetworkManager from "../../app/managers/networkManager";
import { showErrorToast } from "../../app/utils/helpers";
import { ViewSelectButton } from "../../components/buttons/ViewSelectButton";
import { PublicationsView } from "./PublicationsView";
import { TransparentDatePicker } from "../../components/buttons/TransparentDatePicker";
import { FloatLabel } from "primereact/floatlabel";

export function ReportsView() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const reports = useSelector((state) => state.reports.list)
    const settings = useSelector((state) => state.settings)
    const selectedTeamsIds = useSelector((state) => state.teams.selectedTeamsIds)
    const selectedView = useSelector((state) => state.reports.selectedView)
    const selectedDate = useSelector((state) => state.reports.selectedDate)
    const isMobile = useSelector((state) => state.settings.isMobile)

    const [reportModalType, setReportModalType] = useState(null)
    const [globalFilterValue, setGlobalFilterValue] = useState('')
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const viewsOptions = [
        { label: Localize('Reports', settings.lang), value: 0 },
        { label: Localize('Exports SILAE', settings.lang), value: 1 },
        { label: Localize('Clients', settings.lang), value: 2 },
    ]

    const toast = useRef(null)
    const dt = useRef(null)
    const mounted = useRef(null)

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
            if (!selectedDate)
                dispatch(setSelectedDate(new Date().getTime()))
        }
    })

    useEffect(() => {
        if (selectedDate) loadReports()
    }, [selectedDate])

    useEffect(() => {
        if (urlParams.subView && viewsOptions[urlParams.subView]) {
            dispatch(setSelectedView(urlParams.subView))
        }
    }, [urlParams])

    function loadReports() {
        let formattedDate = moment(selectedDate).format("MM-YYYY")
        if (formattedDate) {
            dispatch(getReports({
                monthDate: formattedDate
            }))
        }
    }

    const handleViewChange = (view) => {
        let selectedTeamId = selectedTeamsIds[0]
        navigate(`/reports/${selectedTeamId}/${view}`)
    }

    const onSelectedDateChange = (date) => {
        dispatch(setSelectedDate(date))
    }

    const openNewReport = () => {
        setReportModalType("NEW_REPORT")
    }

    const openNewExport = () => {
        setReportModalType("NEW_EXPORT")
    }

    const onSubmit = (date) => {
        if (selectedDate != date) {
            dispatch(setSelectedDate(date))
        } else {
            loadReports()
        }
        setReportModalType(null)
    }

    const handleExport = async (data) => {
        let result = await NetworkManager.getInstance().downloadFile(data.filename)
        if (!result) showErrorToast()
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value
        let _filters = { ...filters }

        _filters['global'].value = value

        setFilters(_filters)
        setGlobalFilterValue(value)
    }

    const actionBodyTemplate = (rowData) => {
        return <ImageButton
            image={exportIcon}
            tooltip={"Export"}
            onClick={
                () => handleExport(rowData)
            }
            imageFocus={exportIconFocus}
            active={rowData.status !== 'VALIDATED'}
            width={28} />
    }

    const statusBodyTemplate = (rowData) => {
        return <Tag
            value={<Langs str={rowData.status} />}
            severity={getStatusTagClass(rowData.status)}>
        </Tag>
    }

    const header = isMobile == 3 ? null : (
        <Row>
          <Col md={3}>
            <span className="p-input-icon-left">
            <FloatLabel >
              <InputText
                id="search-report"
                type="search"
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
              />
              <label htmlFor="search-report"><span className="pi pi-search">&ensp;Search...</span></label>
            </FloatLabel>
            </span>
          </Col>
          <Col md={9}></Col>
        </Row>
      )

    return (
        <div className={styles.reportsView}>
            <div className="topMenu">
                {isMobile == 2 ? (
                    <div>
                        <Row>
                            <Col md={12}>
                                <span className="headerTitle">
                                    {<Langs str={viewsOptions[selectedView].label} />}
                                </span>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col md={12} className="d-flex justify-content-center pe-4 align-items-center p-0 m-0">
                                <ViewSelectButton
                                    options={viewsOptions}
                                    selectedView={viewsOptions[selectedView].value}
                                    onViewChange={handleViewChange}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col md={12} className="d-flex justify-content-center align-items-center">
                                <div className="pe-2">
                                    <TransparentDatePicker
                                        viewType={"MONTHLY"}
                                        onChange={onSelectedDateChange}
                                        value={selectedDate}
                                    />
                                </div>
                                {selectedView == 0 && (
                                    <ImageButton
                                        image={addIcon}
                                        imageFocus={addIconFocus}
                                        onClick={openNewReport}
                                        active={true}
                                        tooltip="NEW_REPORT"
                                        width={32}
                                    />
                                )}
                                {selectedView == 1 && (
                                    <ImageButton
                                        image={addIcon}
                                        imageFocus={addIconFocus}
                                        onClick={openNewExport}
                                        active={true}
                                        tooltip="NEW_EXPORT"
                                        width={32}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                ) : isMobile == 3 ? (
                    <Row className="d-flex align-items-center">
                        <Col md={3} className="d-flex align-items-center">
                            <span className="headerTitle">
                                <Langs str={viewsOptions[selectedView].label} />
                            </span>
                        </Col>
                        <Col md={6} className="d-flex justify-content-center align-items-center">
                            <ViewSelectButton
                                options={viewsOptions}
                                selectedView={viewsOptions[selectedView].value}
                                onViewChange={handleViewChange}
                            />
                        </Col>
                        <Col md={3} className="d-flex justify-content-end align-items-center">
                            <TransparentDatePicker
                                viewType={"MONTHLY"}
                                onChange={onSelectedDateChange}
                                value={selectedDate}
                            />
                            {selectedView === 0 && (
                                <ImageButton
                                    image={addIcon}
                                    imageFocus={addIconFocus}
                                    onClick={openNewReport}
                                    active={true}
                                    tooltip="NEW_REPORT"
                                    width={32}
                                />
                            )}
                            {selectedView === 1 && (
                                <ImageButton
                                    image={addIcon}
                                    imageFocus={addIconFocus}
                                    onClick={openNewExport}
                                    active={true}
                                    tooltip="NEW_EXPORT"
                                    width={32}
                                />
                            )}
                        </Col>
                    </Row>

                ) : (
                    <Row>
                        <Col md={4}>
                            <span className="headerTitle">
                                {<Langs str={viewsOptions[selectedView].label} />}
                            </span>
                        </Col>
                        <Col md={4}
                            className="d-flex justify-content-center pe-4 align-items-center p-0 m-0">
                            <ViewSelectButton options={viewsOptions}
                                selectedView={viewsOptions[selectedView].value}
                                onViewChange={handleViewChange} />
                        </Col>
                        <Col md={4} className="d-flex justify-content-end align-items-center">
                            <div className="pe-2">
                                <TransparentDatePicker
                                    viewType={"MONTHLY"}
                                    onChange={onSelectedDateChange}
                                    value={selectedDate} />
                            </div>
                            {selectedView == 0 && <ImageButton
                                image={addIcon}
                                imageFocus={addIconFocus}
                                onClick={openNewReport}
                                active={true}
                                tooltip="NEW_REPORT"
                                width={32} />}
                            {selectedView == 1 && <ImageButton
                                image={addIcon}
                                imageFocus={addIconFocus}
                                onClick={openNewExport}
                                active={true}
                                tooltip="NEW_EXPORT"
                                width={32} />}
                        </Col>
                    </Row>
                )}
            </div>
            {selectedView == 2 ?
                <PublicationsView />
                :
                <div>
                    <Toast ref={toast} />
                    <div className={styles.card}>
                        <DataTable ref={dt} value={selectedView == 0 ? reports.filter(r => !r.isSilae) : reports.filter(r => r.isSilae)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50, 100]} scrollable scrollHeight={isMobile == 3 ? "calc(100vh - 200px)" : "calc(100vh - 315px)"}
                            paginatorTemplate={isMobile == 3 ? "PrevPageLink NextPageLink" :"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"}
                            currentPageReportTemplate={"Showing {first} to {last} of {totalRecords}"}
                            header={header}
                            filters={isMobile === 3 ? null : filters}>
                            {/* <Column selectionMode="multiple" exportable={false}></Column> */}
                            <Column field="company" header={<Langs str="Company" />} sortable filter={isMobile == 3} filterPlaceholder={isMobile == 3 ? "Search by name" : undefined} style={{ minWidth: '11rem' }}></Column>
                            <Column field="team" header={<Langs str="Client" />} sortable filter={isMobile == 3} filterPlaceholder={isMobile == 3 ? "Search by name" : undefined} style={{ minWidth: '11rem' }}></Column>
                            <Column field="createdAt" header={<Langs str="CREATED_AT" />} sortable style={{ minWidth: '12rem' }}></Column>
                            <Column field="createdBy" header={<Langs str="CREATED_BY" />} sortable style={{ minWidth: '12rem' }}></Column>
                            <Column field="status" header={<Langs str="status" />} body={statusBodyTemplate} sortable style={{ minWidth: '8rem' }}></Column>
                            <Column field="description" header={<Langs str="Description" />} sortable style={{ minWidth: '10rem' }}></Column>
                            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '6rem', textAlign: "right" }} frozen
                                alignFrozen="right"></Column>
                        </DataTable>
                    </div>
                    <ReportFormModal
                        type={reportModalType}
                        isSilae={selectedView == 1}
                        onHide={() => setReportModalType(null)}
                        onSubmit={onSubmit} />
                </div>
            }
        </div>
    )
}
