import React from 'react';
import { Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from "react-redux";
import Langs from "../../app/lang/langs";
import { hidePopup, onPopupConfirm } from "../../containers/common/commonSlice";
import { PrimaryButton } from '../../components/buttons/PrimaryButton';
import { ThemeButton } from '../../components/buttons/ThemeButton';

export function VEntrModal({ show, title, onHide, message, onConfirm }) {
    const dispatch = useDispatch();

    function hide() {
        dispatch(hidePopup());
        onHide();
    }

    function handleConfirm() {
        console.log("handleConfirm");
        dispatch(onPopupConfirm(title));
        onConfirm();
    }

    function renderFooter() {
        if (title.includes("CONFIRM_")) {
            return (
                <Modal.Footer className="d-flex justify-content-between">
                    <Col md={3}>
                        <PrimaryButton
                            title="NO"
                            active={true}
                            onClick={() => hide()} />
                    </Col>
                    <Col md={3}>
                        <ThemeButton
                            title="YES"
                            active={true}
                            onClick={() => handleConfirm()} />
                    </Col>
                </Modal.Footer>
            );
        } else {
            return (
                <Modal.Footer className="d-flex justify-content-center">
                    <Col md={3}>
                        <PrimaryButton
                            title="OK"
                            active={true}
                            onClick={() => hide()} />
                    </Col>
                </Modal.Footer>
            );
        }
    }

    return (
        <Modal
            show={show}
            onHide={hide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <Langs str={title} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            {renderFooter()}
        </Modal>
    );
}
