import React, { useState, useRef, useEffect } from 'react';
import styles from './Inputs.module.css';
import Langs from "../../app/lang/langs";
import { useDispatch, useSelector } from "react-redux";
import addIcon from '../../assets/images/add.png'
import addIconFocus from '../../assets/images/addFocus.png'
import { setSelectedSamplesView } from '../../containers/planning/planningSlice'

export function AddSelect(props) {

    const dispatch = useDispatch();
    const selectedSamplesView = useSelector((state) => state.planning.selectedSamplesView)

    const buttonRef = useRef()
    const [focused, setFocused] = useState(false)
    
    const handleAddShiftsClick = (event) => {
        if (selectedSamplesView) {
            dispatch(setSelectedSamplesView(false))
        } else {
            dispatch(setSelectedSamplesView("SHIFTS"))
        }
    }

    return <button
        ref={buttonRef}
        className={styles.imageButton + (selectedSamplesView ? ' rotate45' : '')}
        style={props.style}
        onMouseEnter={() => setFocused(true)}
        onMouseLeave={() => setFocused(false)}
        onClick={handleAddShiftsClick}>
        <img src={focused ? addIconFocus : addIcon}
            alt={"addSelect"}
            width={32} />
    </button>
}