import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import EntretienModal from './EntretienModal';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import styles from './Ventr.module.css';
import ImageButton from '../../components/buttons/ImageButton';
import deleteIcon from "../../assets/images/delete.png";
import deleteIconFocus from "../../assets/images/delete-orange.png";
import editIcon from "../../assets/images/edit.png";
import editIconFocus from "../../assets/images/editFocus.png";
import { useSelector, useDispatch } from 'react-redux';
import { getFormattedDate } from '../../app/utils/helpers';
import { setSelectedEntretien } from './VentrSlice';

const ManageEntretienView = () => {
    const dispatch = useDispatch();
    const [entretiens, setEntretiens] = useState([]);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(0);
    const [selectedCollab, setSelectedCollab] = useState(null);
    const [filteredEntretiens, setFilteredEntretiens] = useState([]);

    const teamsData = useSelector((state) => state.ventr.interviewsData?.teams_data);

    const teamOptions = teamsData ? teamsData.map(team => ({ label: team.team_name, value: team.id_team })) : [];
    teamOptions.unshift({ label: 'Toutes les équipes', value: 0 });

    useEffect(() => {
        if (teamsData) {
            const entretienList = teamsData.flatMap((team) =>
                team.collabs.map((collab) => ({
                    id: collab.id,
                    teamId: collab.id_team,
                    collab: `${collab.nom} ${collab.prenom}`,
                    status: collab.status || 'Non renseigné',
                    date: getFormattedDate(collab.theoretical_date) || 'N/A',
                    matricule: collab.matricule || 'N/A',
                    entreeDate: getFormattedDate(collab.entree_date) || 'N/A',
                    sortieDate: collab.sortie_date ? getFormattedDate(collab.sortie_date) : 'Présent',
                    typeContrat: collab.type_contrat || 'N/A',
                    typeCollab: collab.type_collab || 'N/A',
                    email: collab.email || 'N/A',
                    diplomas: collab.diplomas ? JSON.parse(collab.diplomas) : [],
                    proPre: collab.pro_pre ? JSON.parse(collab.pro_pre) : [],
                    proPost: collab.pro_post ? JSON.parse(collab.pro_post) : [],
                    mobility: collab.mobility ? JSON.parse(collab.mobility) : {},
                    trainingRequested: collab.training_requested ? JSON.parse(collab.training_requested) : [],
                    trainingLast2Years: collab.training_last_2_years ? JSON.parse(collab.training_last_2_years) : [],
                    trainingLast6Years: collab.training_last_6_years ? JSON.parse(collab.training_last_6_years) : [],
                    desiredEvolution: collab.desired_evolution || 'N/A',
                    commentsCollab: collab.comments_collab || 'N/A',
                    commentsManager: collab.comments_manager || 'N/A',
                    interviewType: collab.type || 'N/A',
                    parcoursPro: collab.parcours_pro ? JSON.parse(collab.parcours_pro) : {},
                    bilanPlus: collab.bilan_plus || 'N/A',
                    lastValidatedInterviewDate: collab.last_validated_interview_date
                        ? getFormattedDate(collab.last_validated_interview_date)
                        : 'N/A',
                    clientName: collab.client_name || 'N/A',
                    teamStartDate: getFormattedDate(collab.team_start_date) || 'N/A'
                }))
            );
            setEntretiens(entretienList);
        }
    }, [teamsData]);

    useEffect(() => {
        const newFilteredEntretiens = entretiens
            .filter(entretien => {
                const isTeamMatch = selectedTeam !== 0 ? entretien.teamId === selectedTeam : true;
                return isTeamMatch;
            })
            .filter(entretien => {
                const isCollabMatch = selectedCollab ? entretien.id === selectedCollab.id : true;
                return isCollabMatch;
            });
    
        setFilteredEntretiens(newFilteredEntretiens);
    }, [entretiens, selectedTeam, selectedCollab]); 

    const optionTemplate = (option) => {
        return (
            <div className="p-clearfix">
                <span>{option.collab}</span>
            </div>
        );
    };

    const handleEdit = (rowData) => {
        dispatch(setSelectedEntretien(rowData));
        setEditDialogVisible(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'start' }}>
                <ImageButton
                    tooltip="Edit"
                    image={editIcon}
                    imageFocus={editIconFocus}
                    active={true}
                    onClick={() => handleEdit(rowData)}
                    width={24}
                    height={24}
                />
                <ImageButton
                    tooltip="Delete"
                    image={deleteIcon}
                    imageFocus={deleteIconFocus}
                    active={true}
                    onClick={() => handleDelete(rowData)}
                    width={24}
                    height={24}
                    style={{ marginLeft: '10px' }}
                />
            </div>
        );
    };

    const handleDelete = (rowData) => {
        console.log('Delete clicked for:', rowData);
    };

    return (
        <div className="datatable-crud-demo">
            <div className="card">
                <DataTable
                    value={filteredEntretiens}
                    scrollable
                    scrollHeight="75vh"
                    header={
                        <div className={styles.headerRow}>
                            <h5 className="m-0">Gérer les entretiens</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Dropdown
                                    value={selectedTeam}
                                    options={teamOptions}
                                    onChange={(e) => setSelectedTeam(e.value)}
                                    placeholder="Sélectionner une équipe"
                                    className="p-dropdown"
                                    style={{ marginRight: '10px' }}
                                />

                                <Dropdown
                                    value={selectedCollab}
                                    onChange={(e) => setSelectedCollab(e.value)}
                                    options={entretiens}  // Utilise l'option entière
                                    optionLabel="collab"
                                    placeholder="Sélectionner un collaborateur"
                                    showClear
                                    filter
                                    itemTemplate={optionTemplate}
                                    style={{ width: '20rem' }}
                                    className="w-full"
                                />
                            </div>
                        </div>
                    }
                    dataKey="id"
                >
                    <Column field="collab" header="Collaborateur" sortable></Column>
                    <Column field="status" header="Statut" sortable></Column>
                    <Column field="date" header="Date" sortable></Column>
                    <Column body={actionBodyTemplate} header="Actions" />
                </DataTable>

                <EntretienModal
                    visible={editDialogVisible}
                    onHide={() => setEditDialogVisible(false)}
                />
            </div>
        </div>
    );
};

export default ManageEntretienView;
