import { store } from "../store/store";
import axios from "axios";
import { fileDownload } from 'js-file-download'
import { logoutUser } from '../../containers/login/loginSlice'
import { config } from "../utils/config";

let API_URL = "/api"
switch (config.env) {
    case "STAG":
        API_URL = "http://localhost:8089/api"
        break
    case "PROD":
        console.log = console.warn = console.error = () => { }
        API_URL = "https://v365.groupe-vital.com/api"
        break
    case "PREPROD":
        console.log = console.warn = console.error = () => { }
        API_URL = "https://ppdv365.groupe-vital.com/api"
        break
}

const extName = {
    'text/html': 'html',
    'text/plain': 'txt',
    'image/gif': 'gif',
    'image/jpeg': 'jpg',
    'image/jpg': 'jpg',
    'image/png': 'png',
    'image/svg+xml': 'svg',
    'audio/mp3': 'mp3',
    'audio/aac': 'aac',
    'audio/x-wav': 'wav',
    'video/mp4': 'mp4',
    'text/csv': 'csv',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        'pptx',
    'application/pdf': 'pdf',
}

export default class NetworkManager {

    static getInstance() {
        if (!this.instance) {
            this.instance = new NetworkManager()
        }
        return this.instance;
    }

    getBaseUrl() {
        return API_URL
    }

    getMediaStorageUrl() {
        return API_URL + "/v1/planning"
    }

    getUploadUrl() {
        return this.getMediaStorageUrl() + "/upload"
    }

    getDownloadUrl() {
        return this.getMediaStorageUrl() + "/downloadReport/"
    }

    /**
     * 
     * @param {*} params 
     * @returns 
     */
    parseGetParams(params) {
        if (params) {
            let arrayOfParams = []
            for (let key in params) {
                arrayOfParams.push(key + "=" + params[key])
            }
            if (arrayOfParams.length)
                return "?" + arrayOfParams.join("&")
        }
        return ""
    }

    /**
     * 
     * @param {*} path 
     * @param {*} params 
     * @returns 
     */
    async postRequest(path, params) {

        const jsonParams = JSON.stringify(params)
        const options = {
            headers: {
                'lang': localStorage.getItem("lang"),
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem("AUTHORIZATION")}`
            },
            withCredentials: true
        }
        try {
            //console.log('POST REQUEST '+this.getBaseUrl() + path + " PARAMS: " + jsonParams)
            const response = await axios.post(this.getBaseUrl() + path, jsonParams, options)
            if (response.api_status === 401) {
                store.dispatch(logoutUser({ doReload: path !== config.apiPaths.user.login }))
            }
            return { responseData: response.data, body: params };
        } catch (error) {
            if (error.response.status === 401) {
                store.dispatch(logoutUser({ doReload: path !== config.apiPaths.user.login }))
            }
            return { responseError: error.response };
        }
    }

    /**
     * 
     * @param {*} path 
     * @param {*} params 
     * @param {*} token 
     * @returns 
     */
    async getRequest(path, params, token) {
        const options = {
            headers: {
                'lang': localStorage.getItem("lang"),
                'Content-Type': 'application/json',
                'Authorization': `${token ? token : localStorage.getItem("AUTHORIZATION")}`
            },
            withCredentials: true
        }
        try {
            let url = this.getBaseUrl() + path + this.parseGetParams(params)
            const response = await axios.get(url, options)
            if (response.api_status === 401) {
                store.dispatch(logoutUser({ doReload: path !== config.apiPaths.user.msAuth }))
            }
            return { responseData: response.data };
        } catch (error) {
            if (error.response.status === 401) {
                store.dispatch(logoutUser({ doReload: path !== config.apiPaths.user.msAuth }))
            }
            return { responseError: error.response };
        }
    }

    /**
     * 
     * @param {*} file 
     * @param {*} fileName 
     * @param {*} apiKey 
     * @returns 
     */
    async uploadFile(file, fileName, apiKey) {
        if (!file) return Promise.resolve(null)

        const base64Response = await fetch(file);
        const blob = await base64Response.blob();
        let fileType = blob.type
        if (fileType) fileName += "." + extName[fileType]
        var formData = new FormData();
        formData.append('file', blob, fileName)

        const options = {
            headers: {
                'lang': localStorage.getItem("lang"),
                'Content-Type': 'multipart/form-data',
                "api-key": apiKey,
            }
        }
        try {
            const response = await axios.post(this.getUploadUrl(), formData, options);
            if (response && response.data && response.data.filename) {
                return Promise.resolve(this.getDownloadUrl() + response.data.filename)
            }
            return Promise.resolve(null)
        } catch (error) {
            return Promise.resolve(null)
        }
    }

    /**
     * 
     * @param {*} filename 
     * @returns 
     */
    async downloadFile(filename) {
        if (!filename) return Promise.resolve(null)

        const options = {
            headers: {
                'lang': localStorage.getItem("lang"),
                'Authorization': `${localStorage.getItem("AUTHORIZATION")}`
            },
            withCredentials: true,
            responseType: 'blob'
        }
        try {
            const response = await axios.get(`${this.getDownloadUrl()}${filename}`, options)
            if (response && response.data) {
                var data = new Blob([response.data])
                var excelURL = window.URL.createObjectURL(data)
                let tempLink = document.createElement('a')
                tempLink.href = excelURL
                tempLink.setAttribute('download', filename)
                tempLink.click()
                return Promise.resolve(true)
            }
            return Promise.resolve(null)
        } catch (error) {
            return Promise.resolve(null)
        }
    }

    /**
     * Function to fetch and download a file from a post request
     * @param {*} path 
     * @param {*} params 
     * @param {*} filename 
     * @returns 
     */
    async fetchFile(path, params, filename) {

        const jsonParams = JSON.stringify(params)
        const options = {
            headers: {
                'lang': localStorage.getItem("lang"),
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem("AUTHORIZATION")}`
            },
            withCredentials: true,
            responseType: 'blob'
        }

        try {
            const response = await axios.post(this.getBaseUrl() + path, jsonParams, options)
            if (response && response.data) {
                var data = new Blob([response.data])
                var fileURL = window.URL.createObjectURL(data)
                let tempLink = document.createElement('a')
                tempLink.href = fileURL
                tempLink.setAttribute('download', filename)
                tempLink.click()
                return Promise.resolve(true)
            }
            return Promise.reject(response)
        } catch (error) {
            return Promise.reject(error)
        }
    }

}